import React, { useState, useEffect } from "react";
import axios from "axios";
import customePermissions from "./../customPerms";
import CustomNavbar from "../CustomNavbar";
import DocladderFilterMarketingValueForUsers from "./DocladderMarketingDateViewForUsers";


const DocladderMarketingFilesPageForUser = () => {
  const [files, setFiles] = useState([]);
  const [vacancies, setVacancies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sources, setSources] = useState([]);
  const [contactPersonTypes, setContactPersonTypes] = useState([]);
  const [contactPersonTypes2, setContactPersonTypes2] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [fileDetails, setFileDetails] = useState({
    name: "",
    city: "",
    state: "",
    cat: "",
    source: "",
    contact_person_type: "",
    poc_name: "",
    poc_number: "",
    date: "",
    percentage_closed: "",
    payment_in_days: "",
    scanned_file: null,
    active_status: true,
    contact_person2_type: "",
    contact_person_name2: "",
    contact_person_number2: "",
    comment: "",
    callback: "",
    callback_date: "",
  });
  const [editFileId, setEditFileId] = useState(null);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);

  useEffect(() => {
    fetchAllFiles();
  }, []);

  const fetchAllFiles = async () => {
    try {
      const response = await axios.get(
        "https://scoreboard.codingindia.co.in/DocladderFiles/docladder-marketing-files/",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setFiles(response.data.data);
      setFilteredFiles(response.data.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const handleDeleteFile = async (id) => {
    try {
      await axios.delete(
        `https://scoreboard.codingindia.co.in/DocladderFiles/docladder-marketing-files/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      await fetchAllFiles(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleFormChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "scanned_file") {
      setFileDetails((prev) => ({ ...prev, scanned_file: files[0] }));
    } else if (name === "active_status") {
      setFileDetails((prev) => ({ ...prev, [name]: value === "true" }));
    } else {
      setFileDetails((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(fileDetails).forEach(([key, value]) => {
      if (value !== null) formData.append(key, value);
    });

    const url = editFileId
      ? `https://scoreboard.codingindia.co.in/DocladderFiles/docladder-marketing-files/${editFileId}/`
      : "https://scoreboard.codingindia.co.in/DocladderFiles/docladder-marketing-files/";
    const method = editFileId ? "put" : "post";

    try {
      await axios({
        method,
        url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      if (editFileId) {
        const updatedFiles = files.map((file) =>
          file.id === editFileId ? { ...file, ...fileDetails } : file
        );
        setFiles(updatedFiles);
        setFilteredFiles(updatedFiles);
      } else {
        fetchAllFiles();
      }

      setModalOpen(false);
      resetForm();
    } catch (error) {
      console.error("Error saving file:", error);
      alert("Failed to save the file!");
    }
  };

  const resetForm = () => {
    setFileDetails({
      name: "",
      city: "",
      state: "",
      cat: "",
      source: "",
      contact_person_type: "",
      poc_name: "",
      poc_number: "",
      date: "",
      percentage_closed: "",
      payment_in_days: "",
      scanned_file: null,
      active_status: true,
      contact_person2_type: "",
      contact_person_name2: "",
      contact_person_number2: "",
      comment: "",
      callback: "",
      callback_date: "",
    });
    setEditFileId(null);
  };

  const handleEditFile = (file) => {
    setFileDetails({
      ...file,
      cat:
        categories.find((category) => category.name === file.cat__name)?.id ||
        "",
      source:
        sources.find((source) => source.name === file.source__name)?.id || "",
      contact_person_type:
        contactPersonTypes.find(
          (type) => type.name === file.contact_person_type__name
        )?.id || "",
      contact_person2_type:
        contactPersonTypes.find(
          (type) => type.name === file.contact_person2_type__name
        )?.id || "",
      active_status: file.active_status,
    });
    setEditFileId(file.id);
    setModalOpen(true);
  };

  const handleFilteredResults = (data) => {
    setFilteredFiles(Array.isArray(data) ? data : []);
  };

  const toggleRowExpansion = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const onClose = () => {
    setModalOpen(false);
    setFileDetails({
      name: "",
      city: "",
      state: "",
      cat: "",
      source: "",
      contact_person_type: "",
      poc_name: "",
      poc_number: "",
      date: "",
      percentage_closed: "",
      payment_in_days: "",
      scanned_file: null,
      contact_person2_type: "",
      contact_person_name2: "",
      contact_person_number2: "",
      comment: "",
      callback: "",
      callback_date: "",
    });
    setEditFileId(null);
  };

  useEffect(() => {
    axios
      .get("https://scoreboard.codingindia.co.in/Marketing/vacancies/")
      .then((response) => setVacancies(response.data))
      .catch((error) =>
        console.error("There was an error fetching the vacancies!", error)
      );

    axios
      .get("https://scoreboard.codingindia.co.in/Marketing/categories/")
      .then((response) => setCategories(response.data))
      .catch((error) =>
        console.error("There was an error fetching the categories!", error)
      );

    axios
      .get("https://scoreboard.codingindia.co.in/Marketing/marketing-sources/")
      .then((response) => setSources(response.data))
      .catch((error) =>
        console.error("There was an error fetching the sources!", error)
      );

    axios
      .get("https://scoreboard.codingindia.co.in/Marketing/contact-person-types/")
      .then((response) => setContactPersonTypes(response.data))
      .catch((error) =>
        console.error(
          "There was an error fetching the contact person types!",
          error
        )
      );
  }, []);

  const totalClients = files.length;
  const activeClientsCount = files.filter((file) => file.active_status).length;
  const inactiveClientsCount = totalClients - activeClientsCount;

  return (
    <div className="font-sans">
      <customePermissions />
      <CustomNavbar />
      <div
        className="container mt-2"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h1 className="text-center text-3xl text-blue-800 mb-2">
          Docladder Marketing Files
        </h1>
        <button
          className="bg-blue-800 text-white px-4 py-2 mb-2 rounded hover:bg-blue-900 transition-colors"
          onClick={() => {
            setModalOpen(true);
            setEditFileId(null);
          }}
        >
          Add New File
        </button>
      </div>

      {/* Display total, active, and inactive clients */}
      <div
        className="container mt-4 text-center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h2 className="text-xl font-bold text-gray-700">
          Total Clients: {totalClients}
        </h2>
        <h2 className="text-xl font-bold text-green-700">
          Active Clients: {activeClientsCount}
        </h2>
        <h2 className="text-xl font-bold text-red-700">
          Inactive Clients: {inactiveClientsCount}
        </h2>
      </div>

      <div className="px-5">
        <div className="mt-4 mb-5">
          <DocladderFilterMarketingValueForUsers onResults={handleFilteredResults} />
          <h2 className="text-center text-2xl text-blue-800 mb-2">
            Existing Files
          </h2>
          <table
            className="min-w-full bg-white border border-gray-300"
            style={{ width: "95%", margin: "auto" }}
          >
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Sr. No</th>
                <th className="py-2 px-4 border-b">Client Name</th>
                <th className="py-2 px-4 border-b">City</th>
                <th className="py-2 px-4 border-b">State</th>
                <th className="py-2 px-4 border-b">Category</th>
                <th className="py-2 px-4 border-b">Active Status</th>
                <th className="py-2 px-4 border-b">Source</th>
                <th className="py-2 px-4 border-b">%</th>
                <th className="py-2 px-4 border-b">Payment Terms (Days)</th>
                <th className="py-2 px-4 border-b">Callback Date</th>

                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredFiles.length > 0 ? (
                filteredFiles.map((file, index) => (
                  <React.Fragment key={file.id}>
                    <tr
                      className="cursor-pointer bg-white hover:bg-gray-100 transition-colors"
                      onClick={() => toggleRowExpansion(index)}
                    >
                      <td className="py-2 px-4 border-b text-center">
                        {index + 1}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {file.name}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {file.city}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {file.state}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {file.cat__name}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {file.active_status ? "Active" : "Inactive"}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {file.source__name}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {file.percentage_closed}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {file.payment_in_days}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {file.callback_date}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        <button className="text-sm font-medium text-green-600 hover:text-green-800 ">
                          <a
                            href={`https://scoreboard.codingindia.co.in/media/${file.scanned_file}/`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-sm font-medium text-blue-600 hover:text-blue-800"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-eye"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                            </svg>
                          </a>
                        </button>
                        <button
                          onClick={() => handleEditFile(file)}
                          className="text-sm font-medium text-green-600 hover:text-green-800 ml-2 px-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pen-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001" />
                          </svg>
                        </button>
                        {/* <button
                          onClick={() => {
                            const confirmDelete = window.confirm(
                              "Are you sure you want to delete this file? This action cannot be undone."
                            );
                            if (confirmDelete) {
                              handleDeleteFile(file.id);
                            }
                          }}
                          className="text-sm font-medium text-red-600 hover:text-red-800 ml-2 px-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                          </svg>
                        </button> */}
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr className="bg-gray-50">
                        <td colSpan="11" className="px-4 py-4">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p>
                              <strong>Contact Person Type:</strong>{" "}
                              {file.contact_person_type__name}
                            </p>
                            <p>
                              <strong>Name:</strong> {file.poc_name}
                            </p>
                            <p>
                              <strong>Number:</strong> {file.poc_number}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p>
                              <strong>Contact Person 2 Type:</strong>{" "}
                              {file.contact_person2_type__name}
                            </p>
                            <p>
                              <strong>Name:</strong> {file.contact_person_name2}
                            </p>
                            <p>
                              <strong>Number:</strong>{" "}
                              {file.contact_person_number2}
                            </p>
                          </div>
                          <p>
                            <strong>Callback:</strong> {file.callback}
                          </p>

                          <p>
                            <strong>Comment:</strong> {file.comment}
                          </p>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    No records found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {modalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg mx-4 my-8 w-full max-w-4xl">
            <h2 className="text-xl font-bold mb-4">
              {editFileId ? "Edit File" : "Add File"}
            </h2>

            <div className="overflow-auto max-h-[70vh]">
              <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={fileDetails.name}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                />
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={fileDetails.city}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                />

                <input
                  type="text"
                  name="state"
                  placeholder="State"
                  value={fileDetails.state}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                />
                <input
                  type="date"
                  name="date"
                  value={fileDetails.date}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                />

                <select
                  name="cat"
                  value={fileDetails.cat}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>

                <select
                  name="active_status"
                  value={fileDetails.active_status}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                >
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>

                <select
                  name="source"
                  value={fileDetails.source}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                >
                  <option value="">Select Source</option>
                  {sources.map((source) => (
                    <option key={source.id} value={source.id}>
                      {source.name}
                    </option>
                  ))}
                </select>

                <select
                  name="contact_person_type"
                  value={fileDetails.contact_person_type}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                >
                  <option value="">Select Contact Person Type</option>
                  {contactPersonTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>

                <input
                  type="text"
                  name="poc_name"
                  placeholder="POC Name"
                  value={fileDetails.poc_name}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                />
                <input
                  type="text"
                  name="poc_number"
                  placeholder="POC Number"
                  value={fileDetails.poc_number}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                />

                <select
                  name="contact_person2_type"
                  value={fileDetails.contact_person2_type}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                >
                  <option value="">Select Contact Person Type</option>
                  {contactPersonTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>

                <input
                  type="text"
                  name="contact_person_name2"
                  placeholder="Contact Person Name"
                  value={fileDetails.contact_person_name2}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                />
                <input
                  type="text"
                  name="contact_person_number2"
                  placeholder="Contact Person Number"
                  value={fileDetails.contact_person_number2}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                />

                <input
                  type="text"
                  name="percentage_closed"
                  placeholder="Percentage Closed"
                  value={fileDetails.percentage_closed}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                />
                <input
                  type="text"
                  name="payment_in_days"
                  placeholder="Payment in Days"
                  value={fileDetails.payment_in_days}
                  onChange={handleFormChange}
                  required
                  className="col-span-1 p-2 border rounded"
                />
                <input
                  type="text"
                  name="callback"
                  placeholder="Callback"
                  value={fileDetails.callback}
                  onChange={handleFormChange}
                  className="col-span-1 p-2 border rounded"
                />
                <input
                  type="date"
                  name="callback_date"
                  placeholder="Call Back Date"
                  value={fileDetails.callback_date}
                  onChange={handleFormChange}
                  className="col-span-1 p-2 border rounded"
                />
                <textarea
                  name="comment"
                  placeholder="Comment"
                  value={fileDetails.comment}
                  onChange={handleFormChange}
                  className="col-span-1 p-2 border rounded"
                />
                <input
                  type="file"
                  name="scanned_file"
                  onChange={handleFormChange}
                  className="col-span-1 p-2 border rounded"
                />

                <div className="flex justify-between items-center col-span-2">
                  <button
                    type="submit"
                    className="bg-blue-800 text-white px-4 py-2 rounded hover:bg-blue-900 transition-colors"
                  >
                    {editFileId ? "Update" : "Create"}
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                    className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition-colors"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocladderMarketingFilesPageForUser;
