import React from "react";

const DealsFilter = ({ filters, setFilters }) => {
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  return (
    <div className="row mb-4">
      <div className="col-md-3">
        <input
          type="text"
          name="deal_name"
          className="form-control"
          placeholder="Filter by Deal Name"
          value={filters.deal_name}
          onChange={handleFilterChange}
        />
      </div>
      <div className="col-md-3">
        <input
          type="text"
          name="deal_status"
          className="form-control"
          placeholder="Filter by Status"
          value={filters.deal_status}
          onChange={handleFilterChange}
        />
      </div>
      <div className="col-md-3">
        <input
          type="text"
          name="hospital_name"
          className="form-control"
          placeholder="Filter by Hospital Name"
          value={filters.hospital_name}
          onChange={handleFilterChange}
        />
      </div>
      {/* <div className="col-md-3">
        <input
          type="text"
          name="percentage"
          className="form-control"
          placeholder="Filter by Percentage"
          value={filters.percentage}
          onChange={handleFilterChange}
        />
      </div>
      <div className="col-md-3">
        <input
          type="text"
          name="payment_terms"
          className="form-control"
          placeholder="Filter by Payment Terms"
          value={filters.payment_terms}
          onChange={handleFilterChange}
        />
      </div> */}
    </div>
  );
};

export default DealsFilter;
