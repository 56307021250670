import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Announcementslider from "./Announcementslider";

function UserOldRecordUpdateView() {
  const [monthlyRecords, setMonthlyRecords] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newRecord, setNewRecord] = useState({
    entrydate: "",
    selected: "",
    revenue: "",
    resume_forwarded: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [id, setId] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      fetch("https://scoreboard.codingindia.co.in/GetUserDatafromUsername/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setId(data.records.id);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  }, []);

  const userId = id;

  const handleSignOut = () => {
    localStorage.removeItem("access_token");
    navigate("/");
  };

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(
          `https://scoreboard.codingindia.co.in/user/${userId}/total-stats/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        setMonthlyRecords(response.data.monthly_records || []);
        setLoading(false);
      } catch (err) {
        setError("Failed to load user stats. Please try again.");
        setLoading(false);
      }
    };

    if (localStorage.getItem("access_token") !== null && userId) {
      fetchStats();
    }
  }, [userId]);

  // Calculate totals dynamically
  const totalSelected = monthlyRecords.reduce(
    (sum, record) => sum + (record.total_selected || 0),
    0
  );
  const totalRevenue = monthlyRecords.reduce(
    (sum, record) => sum + (record.total_revenue || 0),
    0
  );

  const handleCreateModalSubmit = async () => {
    const postData = {
      user: userId,
      entrydate: newRecord.entrydate,
      resume_forwarded: newRecord.resume_forwarded || 0,
      selected: newRecord.selected || 0,
      revenue: newRecord.revenue || 0,
      pipline: 0,
      turned_up: 0,
    };

    try {
      const response = await axios.post(
        "https://scoreboard.codingindia.co.in/create_or_update_record/",
        postData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      alert(response.data.message);
      setShowCreateModal(false);
      setNewRecord({
        entrydate: "",
        selected: "",
        revenue: "",
        resume_forwarded: "",
      });
      const refreshedData = await axios.get(
        `https://scoreboard.codingindia.co.in/user/${userId}/total-stats/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setMonthlyRecords(refreshedData.data.monthly_records || []);
    } catch (error) {
      console.error("Error saving the record:", error);
    }
  };

  return (
    <>
      <section style={{ maxWidth: '100vh', width: "100%", margin: "auto" }}>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <button
            className="btn btn-secondary"
            onClick={() => navigate("/UserViews")}
          >
            Back to Current Record
          </button>
          <button className="btn btn-danger ml-2" onClick={handleSignOut}>
            Sign Out
          </button>
        </nav>
        <div className="container-fluid mt-4">
          <di style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>User Monthly Stats</h2>

            <div className="mt-4">
              <button
                className="btn btn-primary"
                onClick={() => setShowCreateModal(true)}
              >
                Add New Record
              </button>
            </div>

            {showCreateModal && (
              <div className="modal" style={{ display: "block" }}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Create New Record</h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowCreateModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="mb-3">
                          <label htmlFor="entrydate" className="form-label">
                            Entry Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="entrydate"
                            value={newRecord.entrydate}
                            onChange={(e) =>
                              setNewRecord({
                                ...newRecord,
                                entrydate: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="resume_forwarded"
                            className="form-label"
                          >
                            Resume Forwarded
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="resume_forwarded"
                            value={newRecord.resume_forwarded}
                            onChange={(e) =>
                              setNewRecord({
                                ...newRecord,
                                resume_forwarded: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="selected" className="form-label">
                            Selected
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="selected"
                            value={newRecord.selected}
                            onChange={(e) =>
                              setNewRecord({
                                ...newRecord,
                                selected: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="revenue" className="form-label">
                            Revenue
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="revenue"
                            value={newRecord.revenue}
                            onChange={(e) =>
                              setNewRecord({
                                ...newRecord,
                                revenue: e.target.value,
                              })
                            }
                          />
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleCreateModalSubmit}
                        >
                          Save Record
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => setShowCreateModal(false)}
                        >
                          Close
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </di>
          <p className="text-muted">User ID: {userId}</p>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-danger">{error}</p>
          ) : (
            <>
              <div className="mb-4">
                <h3>User Stats</h3>
                <p>Total Revenue: {totalRevenue.toFixed(2)} lac</p>
                <p>Total Selected: {totalSelected}</p>
              </div>
              <table className="table table-bordered"  style={{ width: "80%", margin: "auto" }}>
                <thead>
                  <tr>
                    <th>Month Start</th>
                    <th>Month End</th>
                    <th>Total Resume Forwarded</th>
                    <th>Total Selected</th>
                    <th>Total Revenue (Lac)</th>
                  </tr>
                </thead>
                <tbody>
                  {monthlyRecords.map((record, index) => (
                    <tr key={index}>
                      <td>{record.month_start}</td>
                      <td>{record.month_end}</td>
                      <td>{record.total_resume_forwarded}</td>
                      <td>{record.total_selected}</td>
                      <td>{record.total_revenue.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>

        <Announcementslider />
      </section>
    </>
  );
}

export default UserOldRecordUpdateView;
