import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'

const PrivateFilesPage = () => {
    const [privateFiles, setPrivateFiles] = useState([]);
    const [fileTypes, setFileTypes] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [loadingFileTypes, setLoadingFileTypes] = useState(false);

    // Fetch all private files
    const fetchPrivateFiles = async () => {
        setLoadingFiles(true);
        try {
            const response = await axios.get('https://scoreboard.codingindia.co.in/PrivateFiles/private-files/', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            setPrivateFiles(response.data);
        } catch (error) {
            console.error("Error fetching private files:", error);
        } finally {
            setLoadingFiles(false);
        }
    };

    // Fetch all file types
    const fetchFileTypes = async () => {
        setLoadingFileTypes(true);
        try {
            const response = await axios.get('https://scoreboard.codingindia.co.in/PrivateFiles/private-files/', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            setFileTypes(response.data);
        } catch (error) {
            console.error("Error fetching file types:", error);
        } finally {
            setLoadingFileTypes(false);
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        fetchPrivateFiles();
        fetchFileTypes();
    }, []);

    return (
        <div className="container mt-5">
            {/* Private Files Section */}
            <h2 className="text-center mb-4">Private Files</h2>
            <Link to="/privateFiles">Create New Private Files</Link>

            {loadingFiles ? (
                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th>ID</th>
                                <th>File Type</th>
                                <th>File Name</th>
                                <th>At Percentage</th>
                                <th>Advance Amount</th>
                                <th>Total Advance</th>
                                <th>Remarks</th>
                                <th>Date Entered</th>
                            </tr>
                        </thead>
                        <tbody>
                            {privateFiles.length > 0 ? (
                                privateFiles.map((file) => (
                                    <tr key={file.id}>
                                        <td>{file.id}</td>
                                        <td>{file.file_type.name}</td>
                                        <td>{file.file_name}</td>
                                        <td>{file.at_percentage}</td>
                                        <td>{file.adv_amnt}</td>
                                        <td>{file.total_adv}</td>
                                        <td>{file.remarks || 'N/A'}</td>
                                        <td>{file.date_entered}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        No private files found.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}

            {/* File Types Section */}
            <h2 className="text-center mt-5 mb-4">File Types</h2>
            {loadingFileTypes ? (
                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th>ID</th>
                                <th>Type Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fileTypes.length > 0 ? (
                                fileTypes.map((type) => (
                                    <tr key={type.id}>
                                        <td>{type.id}</td>
                                        <td>{type.name}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="2" className="text-center">
                                        No file types found.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default PrivateFilesPage;
