import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../NavBar";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import "./sop.css";

const SopModelPage = () => {
  const [sops, setSops] = useState([]);
  const [filteredSops, setFilteredSops] = useState([]);
  const [newSop, setNewSop] = useState({
    designation: "",
    docs: null,
    desc: "",
  });
  const [editingSop, setEditingSop] = useState(null);
  const [filter, setFilter] = useState("");
  const [designations, setDesignations] = useState([]);
  const [showCreateEditModal, setShowCreateEditModal] = useState(false); // For Create/Edit modal
  const [showViewDescModal, setShowViewDescModal] = useState(false); // For Description modal
  const [currentDesc, setCurrentDesc] = useState(""); // Store current description

  const API_BASE_URL = "https://scoreboard.codingindia.co.in";

  useEffect(() => {
    fetchSops();
    fetchDesignations();
  }, []);

  const fetchSops = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/SOP/sops/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setSops(response.data);
      setFilteredSops(response.data);
    } catch (error) {
      console.error("Error fetching SOPs:", error);
    }
  };

  const fetchDesignations = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/designations/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setDesignations(response.data);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setNewSop({ ...newSop, [name]: files ? files[0] : value });
  };

  const handleDescChange = (value) => {
    setNewSop({ ...newSop, desc: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append(
      "designation",
      editingSop ? editingSop.designation : newSop.designation
    );
    formData.append("docs", editingSop ? editingSop.docs : newSop.docs);
    formData.append("desc", editingSop ? editingSop.desc : newSop.desc);

    try {
      if (editingSop) {
        await axios.put(
          `${API_BASE_URL}/SOP/sops/update/${editingSop.id}/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
      } else {
        await axios.post(`${API_BASE_URL}/SOP/sops/create/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
      }
      fetchSops();
      closeCreateEditModal();
    } catch (error) {
      console.error("Error saving SOP:", error);
    }
  };

  const openCreateEditModal = (sop = null) => {
    if (sop) {
      setEditingSop(sop);
    } else {
      setEditingSop(null);
      setNewSop({ designation: "", docs: null, descs__desc: "" });
    }
    setShowCreateEditModal(true);
  };

  const closeCreateEditModal = () => {
    setShowCreateEditModal(false);
    setEditingSop(null);
    setNewSop({ designation: "", docs: null, desc: "" });
  };

  const handleViewDescription = (desc) => {
    setCurrentDesc(desc); // Set the description to be displayed
    setShowViewDescModal(true); // Open the description modal
  };

  const closeViewDescModal = () => {
    setShowViewDescModal(false); // Close the description modal
    setCurrentDesc(""); // Reset the description
  };

  const deleteSop = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/SOP/sops/delete/${id}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      fetchSops();
    } catch (error) {
      console.error("Error deleting SOP:", error);
    }
  };

  const filterSops = (e) => {
    setFilter(e.target.value);
    const filtered = sops.filter((sop) =>
      sop.designation_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredSops(filtered);
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid mt-5">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2 className="mb-4 text-center">SOP Management</h2>

          <div>
            <button
              className="btn btn-primary mb-4"
              onClick={() => openCreateEditModal()}
            >
              Add New SOP
            </button>

            <Link
              to="/designationpage"
              className="bg-blue-800 text-white px-4 py-2 rounded hover:bg-blue-600"
              style={{ color: "black", marginLeft: "20px" }}
            >
              Add Designation
            </Link>
          </div>
        </div>

        {/* Filter */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Filter by Designation"
            value={filter}
            onChange={filterSops}
            className="form-control"
          />
        </div>

        {/* SOP List */}
        <table className="table table-bordered table-striped">
          <thead className="table-dark">
            <tr>
              <th>#</th>
              <th>Designation</th>
              <th>Department</th>
              <th>Description</th>
              <th>SOP Name</th>

              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredSops.map((sop, index) => (
              <tr key={sop.id}>
                <td>{index + 1}</td>
                <td>{sop.designation__name}</td>
                <td>{sop.designation__dept__name}</td>
                <td>{sop.descs__name}</td>
                <td>
                  <button
                    className="btn btn-link"
                    onClick={() => handleViewDescription(sop.descs__desc)}
                  >
                    View
                  </button>
                </td>

                <td>
                  <button
                    className="btn btn-warning me-2"
                    onClick={() => openCreateEditModal(sop)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteSop(sop.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Create/Edit Modal */}
        {showCreateEditModal && (
          <div
            className="modal show d-block fade"
            tabIndex="-1"
            role="dialog"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="modal-dialog modal-fullscreen modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header bg-primary text-white">
                  <h5 className="modal-title">
                    {editingSop ? "Update SOP" : "Create New SOP"}
                  </h5>
                  <button
                    type="button"
                    className="btn-close btn-close-white"
                    onClick={closeCreateEditModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label fw-bold">Designation</label>
                      <select
                        name="designation"
                        value={
                          editingSop
                            ? editingSop.designation
                            : newSop.designation
                        }
                        onChange={(e) =>
                          editingSop
                            ? setEditingSop({
                                ...editingSop,
                                designation: e.target.value,
                              })
                            : handleChange(e)
                        }
                        className="form-select"
                        required
                      >
                        <option value="">Select Designation</option>
                        {designations.map((designation) => (
                          <option key={designation.id} value={designation.id}>
                            {designation.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label fw-bold">Description</label>
                      {/* <ReactQuill
                        value={editingSop ? editingSop.desc : newSop.desc}
                        onChange={(value) =>
                          editingSop
                            ? setEditingSop({ ...editingSop, desc: value })
                            : handleDescChange(value)
                        }
                      /> */}
                      <CKEditor
                        editor={ClassicEditor}
                        data={
                          editingSop?.descs__desc || newSop.descs__desc || ""
                        } // Ensure a valid string
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          editingSop
                            ? setEditingSop({ ...editingSop, desc: data })
                            : setNewSop({ ...newSop, desc: data });
                        }}
                      />
                    </div>

                    <div className="text-end mt-4">
                      <button
                        type="button"
                        className="btn btn-secondary me-2"
                        onClick={closeCreateEditModal}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* View Description Modal */}
        {showViewDescModal && (
          <div
            className="modal show d-block fade"
            tabIndex="-1"
            role="dialog"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="modal-dialog modal-fullscreen modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header bg-primary text-white">
                  <h5 className="modal-title" style={{ color: "white" }}>
                    Description
                  </h5>
                  <button
                    type="button"
                    className="btn-close btn-close-white"
                    onClick={closeViewDescModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <div dangerouslySetInnerHTML={{ __html: currentDesc }}></div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeViewDescModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SopModelPage;
