import React, { useState, useEffect } from "react";
import axios from "axios";
// import Navbar from "./NavBar";

const CreatePrivateFilesPage = () => {
  const [files, setFiles] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [fileDetails, setFileDetails] = useState({
    file_type: "",
    file_name: "",
    at_percentage: "",
    adv_amnt: "",
    total_adv: "",
    remarks: "",
  });
  const [editFileId, setEditFileId] = useState(null);

  useEffect(() => {
    fetchAllFiles();
    fetchFileTypes();
  }, []);

  const fetchAllFiles = async () => {
    try {
      const response = await axios.get(
        "https://scoreboard.codingindia.co.in/PrivateFiles/files/",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setFiles(response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const fetchFileTypes = async () => {
    try {
      const response = await axios.get(
        "https://scoreboard.codingindia.co.in/PrivateFiles/file-types/",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setFileTypes(response.data);
    } catch (error) {
      console.error("Error fetching file types:", error);
    }
  };

  const handleDeleteFile = async (id) => {
    try {
      await axios.delete(
        `https://scoreboard.codingindia.co.in/PrivateFiles/files/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      fetchAllFiles(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFileDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = editFileId
      ? `https://scoreboard.codingindia.co.in/PrivateFiles/files/${editFileId}/`
      : "https://scoreboard.codingindia.co.in/PrivateFiles/files/";
    const method = editFileId ? "put" : "post";

    try {
      await axios({
        method,
        url,
        data: fileDetails,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      fetchAllFiles();
      setModalOpen(false);
      resetForm();
    } catch (error) {
      console.error("Error saving file:", error);
      alert("Failed to save the file!");
    }
  };

  const resetForm = () => {
    setFileDetails({
      file_type: "",
      file_name: "",
      at_percentage: "",
      adv_amnt: "",
      total_adv: "",
      remarks: "",
    });
    setEditFileId(null);
  };

  const handleEditFile = (file) => {
    setFileDetails({
      file_type: file.file_type.id,
      file_name: file.file_name,
      at_percentage: file.at_percentage,
      adv_amnt: file.adv_amnt,
      total_adv: file.total_adv,
      remarks: file.remarks,
    });
    setEditFileId(file.id);
    setModalOpen(true);
  };

  const onClose = () => {
    setModalOpen(false);
    resetForm();
  };

  return (
    <div className="font-sans">
      {/* <Navbar /> */}
      <div className="container mt-4 flex justify-between items-center">
        <h1 className="text-3xl font-bold text-blue-800">Private Files</h1>
        <button
          className="bg-blue-800 text-white px-4 py-2 rounded hover:bg-blue-900"
          onClick={() => {
            setModalOpen(true);
            setEditFileId(null);
          }}
        >
          Add New File
        </button>
      </div>

      <div className="px-5 mt-4">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Sr. No</th>
              <th className="py-2 px-4 border-b">File Type</th>
              <th className="py-2 px-4 border-b">File Name</th>
              <th className="py-2 px-4 border-b">At Percentage</th>
              <th className="py-2 px-4 border-b">Advance Amount</th>
              <th className="py-2 px-4 border-b">Total Advance</th>
              <th className="py-2 px-4 border-b">Remarks</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {files.length > 0 ? (
              files.map((file, index) => (
                <tr key={file.id}>
                  <td className="py-2 px-4 border-b text-center">
                    {index + 1}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    {file.file_type.name}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    {file.file_name}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    {file.at_percentage}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    {file.adv_amnt}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    {file.total_adv}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    {file.remarks}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    <button
                      onClick={() => handleEditFile(file)}
                      className="text-sm font-medium text-green-600 hover:text-green-800 ml-2 px-2"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        const confirmDelete = window.confirm(
                          "Are you sure you want to delete this file?"
                        );
                        if (confirmDelete) {
                          handleDeleteFile(file.id);
                        }
                      }}
                      className="text-sm font-medium text-red-600 hover:text-red-800 ml-2 px-2"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center py-4">
                  No records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {modalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg mx-4 my-8 w-full max-w-2xl">
            <h2 className="text-xl font-bold mb-4">
              {editFileId ? "Edit File" : "Add File"}
            </h2>
            <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
              <select
                name="file_type"
                value={fileDetails.file_type}
                onChange={handleFormChange}
                required
                className="p-2 border rounded"
              >
                <option value="">Select File Type</option>
                {fileTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="file_name"
                placeholder="File Name"
                value={fileDetails.file_name}
                onChange={handleFormChange}
                required
                className="p-2 border rounded"
              />
              <input
                type="text"
                name="at_percentage"
                placeholder="At Percentage"
                value={fileDetails.at_percentage}
                onChange={handleFormChange}
                required
                className="p-2 border rounded"
              />
              <input
                type="text"
                name="adv_amnt"
                placeholder="Advance Amount"
                value={fileDetails.adv_amnt}
                onChange={handleFormChange}
                required
                className="p-2 border rounded"
              />
              <input
                type="text"
                name="total_adv"
                placeholder="Total Advance"
                value={fileDetails.total_adv}
                onChange={handleFormChange}
                required
                className="p-2 border rounded"
              />
              <textarea
                name="remarks"
                placeholder="Remarks"
                value={fileDetails.remarks}
                onChange={handleFormChange}
                className="p-2 border rounded"
              />
              <div className="flex justify-between">
                <button
                  type="submit"
                  className="bg-blue-800 text-white px-4 py-2 rounded hover:bg-blue-900"
                >
                  {editFileId ? "Update" : "Create"}
                </button>
                <button
                  type="button"
                  onClick={onClose}
                  className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatePrivateFilesPage;
