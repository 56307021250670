import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Navbar from "../NavBar";
import Announcementslider from "../Announcementslider";

function ShowTeamLeader({ prop }) {
  const location = useLocation();
  const stateValue = location.state && location.state["newUser"];

  const [data, setData] = useState(null);
  const [weeklyData, setWeeklyData] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dailyEntryData, setdailyEntryData] = useState(null);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    async function fetchLeaders() {
      try {
        // const response = await fetch('https://scoreboard.codingindia.co.in/LeadersTable/leaders/');
        const response = await fetch(
          "https://scoreboard.codingindia.co.in/LeadersTable/leaders/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch leaders");
        }
        const data = await response.json();
        console.log("Response: ", data);
        console.log("Leaders: ", data.leaders);

        setData(data.leaders);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }

    fetchLeaders();
  }, []);

  const openModal = (record) => {
    setSelectedRecord(record);
    setShowModal(true);
  };

  return (
    <>
      <section>
        <Navbar dataProp={stateValue} />
        <div>
          <div>
            <div>
              <div>
                {/****************************** Viewer Code (Someone) ******************************/}
                <table
                  className="myTable min-w-full bg-white"
                  style={{ width: "95%", margin: "auto" }}
                >
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col" className="imageWidth">
                        Image
                      </th>
                      <th scope="col" className="nameWidth">
                        Name
                      </th>
                      <th scope="col" className="percentageWidth">
                        Percentage
                      </th>
                      <th scope="col" className="percentageWidth">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((record, index) => (
                        <tr key={record.id}>
                          <td className="py-2 px-4 border-b text-center">
                            {index + 1}
                          </td>
                          <td
                            className=""
                            style={{ height: "150px", width: "15%" }}
                          >
                            <img
                              src={`https://scoreboard.codingindia.co.in${record.image}`}
                              className=""
                              style={{
                                height: "120px",
                                marginLeft: "20%",
                                width: "60%",
                              }}
                              alt={record.image}
                            />
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {record.name}
                          </td>
                          <td
                            className="py-2 px-4 border-b text-center progress-bar-container"
                            style={{ width: "50%", height: "20px" }}
                          >
                            <div
                              className={`progress-bar ${
                                record.calculatePercentage < 50
                                  ? "progress-bar-red"
                                  : record.calculatePercentage < 80
                                  ? "progress-bar-light-green"
                                  : "progress-bar-green"
                              }`}
                              style={{
                                width: `${record.calculatePercentage}%`,
                              }}
                            >
                              {record.calculatePercentage === 50 ? (
                                <span style={{ marginLeft: "-15px" }}>
                                  {Math.ceil(record.calculatePercentage)}%
                                </span>
                              ) : (
                                Math.ceil(record.calculatePercentage) + "%"
                              )}
                            </div>
                          </td>
                          <td className="py-2 px-4 border-b text-center progress-bar-container">
                            <Link to={`/currentmonthTaskForm/${record.id}`}>
                              {" "}
                              <button style={{ marginRight: "20px" }}>
                                Add Task
                              </button>{" "}
                              |
                            </Link>
                            <Link to={`/leadersTask/${record.id}`}>
                              <button style={{ marginLeft: "20px" }}>
                                View Task
                              </button>
                            </Link>
                            <Link to={`/leadersOldRecords/${record.id}`}>
                              <button style={{ marginLeft: "20px" }}>
                                Old Month Records
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <Announcementslider />
      </section>
    </>
  );
}
export default ShowTeamLeader;
