import React, { useState, forwardRef } from 'react';
import axios from 'axios';

const FilterMarketingValue = forwardRef(({ onResults }, ref) => {
    const [query, setQuery] = useState('');
    const [location, setLocation] = useState('');
    const [state, setState] = useState('');
    const [category, setCategory] = useState('');
    const [activeStatus, setActiveStatus] = useState('');
    const [source, setSource] = useState(''); // Initially empty
    const [percentageClosed, setPercentageClosed] = useState('');
    const [vacancy, setVacancy] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [loading, setLoading] = useState(false);
    const [sources, setSources] = useState([]); // To store the sources fetched from the API
    const [loadingSources, setLoadingSources] = useState(false); // Loading state for sources
    const [sourcesLoaded, setSourcesLoaded] = useState(false); // To track if sources have been loaded

    // Fetch sources when the user clicks on the dropdown
    const fetchSources = async () => {
        if (!sourcesLoaded) { // Only fetch if not already loaded
            setLoadingSources(true);
            try {
                const response = await axios.get('https://scoreboard.codingindia.co.in/Marketing/marketing-sources/');
                setSources(response.data); // Assuming response.data is an array of source objects
                setSourcesLoaded(true); // Mark that the sources have been loaded
            } catch (error) {
                console.error("There was an error fetching the sources!", error);
            } finally {
                setLoadingSources(false); // Set loading to false after fetch is done
            }
        }
    };

    // Fetch all files
    const handleSearch = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://scoreboard.codingindia.co.in/Marketing/FilterMarketingValue/', {
                params: {
                    query,
                    location,
                    state,
                    category,
                    active_status: activeStatus,
                    source,
                    percentage_closed: percentageClosed,
                    selected_month: selectedMonth,
                    selected_year: selectedYear,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            onResults(response.data.data); // Pass filtered results back to the parent component
        } catch (error) {
            console.error("Error fetching filtered data", error);
        } finally {
            setLoading(false);
        }
    };

    const months = [
        { name: "January", value: 1 },
        { name: "February", value: 2 },
        { name: "March", value: 3 },
        { name: "April", value: 4 },
        { name: "May", value: 5 },
        { name: "June", value: 6 },
        { name: "July", value: 7 },
        { name: "August", value: 8 },
        { name: "September", value: 9 },
        { name: "October", value: 10 },
        { name: "November", value: 11 },
        { name: "December", value: 12 }
    ];

    const currentYear = new Date().getFullYear();
    const years = [
        currentYear,
        currentYear - 1,
        ...Array.from({ length: 17 }, (_, i) => currentYear - (i + 2))  // Generate previous 5 years
    ];

    return (
        <div className="filter-marketing-value p-4">
            <h2 className="text-2xl font-bold mb-4">Filter Marketing Files</h2>

            <div className="flex flex-wrap space-x-4">
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search by Client Name"
                    className="p-2 border rounded"
                />
                <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder="Search by Location"
                    className="p-2 border rounded"
                />
                <input
                    type="text"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    placeholder="Search by State"
                    className="p-2 border rounded"
                />
                <input
                    type="text"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    placeholder="Search by Category"
                    className="p-2 border rounded"
                />
                <input
                    type="text"
                    value={percentageClosed}
                    onChange={(e) => setPercentageClosed(e.target.value)}
                    placeholder="Search by Percentage Closed"
                    className="p-2 border rounded"
                />

                <select
                    value={selectedMonth}
                    onChange={e => setSelectedMonth(e.target.value)}
                    className="p-2 border rounded"
                >
                    <option value="">Select a month</option>
                    {months.map((month) => (
                        <option key={month.value} value={month.value}>
                            {month.name}
                        </option>
                    ))}
                </select>

                <select
                    value={selectedYear}
                    onChange={e => setSelectedYear(e.target.value)}
                    className="p-2 border rounded ml-2"
                >
                    <option value="">Select a year</option>
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>

                <select
                    value={activeStatus}
                    onChange={e => setActiveStatus(e.target.value)}
                    className="p-2 border rounded ml-2"
                >
                    <option value="">Select Active Status</option>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                </select>

                {/* Source Dropdown */}
                <select
                    name="source"
                    value={source}
                    onChange={(e) => setSource(e.target.value)}
                    onFocus={fetchSources} // Fetch sources when the user clicks on the dropdown
                    className="p-2 border rounded ml-2"
                >
                    <option value="">Select Source</option>
                    {loadingSources ? (
                        <option value="">Loading...</option>
                    ) : (
                        sources.map((source) => (
                            <option key={source.id} value={source.id}>
                                {source.name}
                            </option>
                        ))
                    )}
                </select>

                <button
                    onClick={handleSearch}
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
                >
                    Search
                </button>
            </div>

            {loading && <p>Loading...</p>}
        </div>
    );
});

export default FilterMarketingValue;
