import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Navbar from '../NavBar';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CreateMoMs = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    date: '',
    summary: '',
  });

  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('https://scoreboard.codingindia.co.in/current-user/', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });
        const currentUser = response.data;
        setFormData((prevData) => ({
          ...prevData,
          byuser: currentUser.username,
        }));
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    fetchCurrentUser();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      summary: data,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        'https://scoreboard.codingindia.co.in/api/moms/',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );

      setSuccessMessage('Record created successfully!');
      console.log('Record created successfully!');

      // Reset form fields
      setFormData({
        title: '',
        date: '',
        summary: '',
        byuser: formData.byuser,
      });

    } catch (error) {
      console.error('Error creating MoM:', error);
      setSuccessMessage('Error creating MoM');

      console.error('Error message:', error.response?.data);
    }
  };

  return (
    <>
      <Navbar/>
      <div>
        {successMessage && (
          <div className="bg-green-200 text-green-700 border border-green-600 px-4 py-3 rounded-md my-4">
            {successMessage}
          </div>
        )}
        <div className="container mx-auto px-4" style={{ width: '50%', margin: 'auto', marginTop: '35px' }}>
          <div className="container" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 className="text-3xl font-bold text-center">Add MoM</h1>
            <Link
              to="/momlist"
              style={{ marginTop: '8px', border: '1px solid blue', padding: '15px', borderRadius: '7px 15px' }}
            >
              All MoM
            </Link>
          </div>

          <form onSubmit={handleSubmit} method="post">
            <div className="grid grid-cols-2 gap-3">
              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor="title">
                  Title
                </label>
                <input
                  className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                  value={formData.title}
                  onChange={handleChange}
                  type="text"
                  id="title"
                  name="title"
                  required
                />
              </div>
              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor="date">
                  Date
                </label>
                <input
                  className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                  value={formData.momdate}
                  onChange={handleChange}
                  type="date"
                  id="momdate"
                  name="momdate"
                  required
                />
              </div>
              <div className="col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor="summary">
                  Summary
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.summary}
                  onChange={handleEditorChange}
                />
              </div>
              
            </div>

            <div className="flex justify-end mt-4">
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring"
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateMoMs;
