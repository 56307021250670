import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from "../NavBar";

const CreateTeamLeader = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const [departments, setDepartments] = useState([]);
    const [designations, setDesignations] = useState([]);

    const [newTeamLeader, setNewTeamLeader] = useState({
        name: '',
        username: '',
        password: '',
        dept: '',
        image: null,
        designation: '',
    });

    // Handle form input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewTeamLeader((prevTeamLeader) => ({
            ...prevTeamLeader,
            [name]: value,
        }));
    };

    // Handle department change
    const handleDeptChange = (event) => {
        const { value } = event.target;
        setNewTeamLeader((prevTeamLeader) => ({
            ...prevTeamLeader,
            dept: value,
        }));
    };

    // Handle designation change
    const handleDesignationChange = (event) => {
        const { value } = event.target;
        setNewTeamLeader((prevTeamLeader) => ({
            ...prevTeamLeader,
            designation: value,
        }));
    };

    // Handle image file change
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setNewTeamLeader((prevTeamLeader) => ({
            ...prevTeamLeader,
            image: file,
        }));
    };

    // Handle form submission
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('name', newTeamLeader.name);
            formData.append('username', newTeamLeader.username);
            formData.append('password', newTeamLeader.password);
            formData.append('dept', newTeamLeader.dept);
            formData.append('image', newTeamLeader.image);
            formData.append('designation', newTeamLeader.designation);

            await axios.post('https://scoreboard.codingindia.co.in/LeadersTable/create_TeamLeader_api/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });

            setSuccessMessage('Team Leader created successfully!');
            setNewTeamLeader({
                name: '',
                username: '',
                password: '',
                dept: '',
                image: null,
                designation: '',
            });
        } catch (error) {
            console.error('Error saving Team Leader:', error);
        }
    };

    // Fetch departments and designations on component mount
    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await axios.get('https://scoreboard.codingindia.co.in/LeadersTable/teamleader-types/', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                setDepartments(response.data);
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };

        const fetchDesignations = async () => {
            try {
                const response = await axios.get('https://scoreboard.codingindia.co.in/designations/', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                setDesignations(response.data);
            } catch (error) {
                console.error('Error fetching designations:', error);
            }
        };

        fetchDepartments();
        fetchDesignations();
    }, []);

    return (
        <div>
            <Navbar />
            <div className="messagedi">
                <h1 className="text-center">Add Team Leader</h1>
                {successMessage && (
                    <p style={{ width: '50%', margin: 'auto', padding: '15px', backgroundColor: '#99BC85', color: 'white' }}>
                        {successMessage}
                    </p>
                )}
            </div>

            <div className="container mx-auto px-4" style={{ width: '50%', margin: 'auto', marginTop: '35px' }}>
                <form onSubmit={handleFormSubmit} encType="multipart/form-data">
                    <div className="grid grid-cols-2 gap-3">
                        <div className="col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor="name">
                                Name
                            </label>
                            <input
                                className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md"
                                value={newTeamLeader.name}
                                onChange={handleInputChange}
                                type="text"
                                id="name"
                                name="name"
                                required
                            />
                        </div>
                        <div className="col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor="username">
                                Username
                            </label>
                            <input
                                className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md"
                                value={newTeamLeader.username}
                                onChange={handleInputChange}
                                type="text"
                                id="username"
                                name="username"
                                required
                            />
                        </div>
                        <div className="col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor="password">
                                Password
                            </label>
                            <input
                                className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md"
                                value={newTeamLeader.password}
                                onChange={handleInputChange}
                                type="password"
                                id="password"
                                name="password"
                                required
                            />
                        </div>
                        <div className="col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor="dept">
                                Department
                            </label>
                            <select
                                className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md"
                                value={newTeamLeader.dept}
                                onChange={handleDeptChange}
                                id="dept"
                                name="dept"
                                required
                            >
                                <option value="">Select Department</option>
                                {departments.map((type) => (
                                    <option key={type.id} value={type.id}>
                                        {type.id} - {type.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor="designation">
                                Designation
                            </label>
                            <select
                                className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md"
                                value={newTeamLeader.designation}
                                onChange={handleDesignationChange}
                                id="designation"
                                name="designation"
                                required
                            >
                                <option value="">Select Designation</option>
                                {designations.map((designation) => (
                                    <option key={designation.id} value={designation.id}>
                                        {designation.id} - {designation.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor="image">
                                Image
                            </label>
                            <input
                                className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md"
                                type="file"
                                id="image"
                                onChange={handleImageChange}
                                name="image"
                                required
                            />
                        </div>
                    </div>

                    <div className="flex justify-end mt-4 mb-4">
                        <button
                            type="submit"
                            className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600"
                        >
                            Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateTeamLeader;
