import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../NavBar";
import "./DealsPage.css";
import DealsFilter from "./Dealsfilters";

const DealsPage = () => {
  const [deals, setDeals] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [editingDeal, setEditingDeal] = useState(null);
  const [formData, setFormData] = useState({});
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [filters, setFilters] = useState({
    deal_name: "",
    deal_status: "",
    percentage: "",
    hospital_name: "",
  });

  useEffect(() => {
    fetchDeals();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filters, deals]);

  const applyFilters = () => {
    let filtered = deals.filter((deal) => {
      return (
        deal.deal_name
          .toLowerCase()
          .includes(filters.deal_name.toLowerCase()) &&
        deal.deal_status
          .toLowerCase()
          .includes(filters.deal_status.toLowerCase()) &&
        deal.hospital_name
          .toLowerCase()
          .includes(filters.hospital_name.toLowerCase()) &&
        (filters.percentage === "" || deal.percentage == filters.percentage)
      );
    });
    setFilteredDeals(filtered);
  };

  const fetchDeals = async () => {
    const response = await axios.get(
      "https://scoreboard.codingindia.co.in/Deals/deals-models-list-create/"
    );
    setDeals(response.data);
  };

  const handleEdit = (deal) => {
    setEditingDeal(deal);
    setFormData(deal);
    setModalShow(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this deal?")) {
      await axios.delete(
        `https://scoreboard.codingindia.co.in/Deals/deals-models-detail/${id}/`
      );
      alert("Deal deleted successfully");
      fetchDeals();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formPayload = new FormData();
    Object.keys(formData).forEach((key) => {
      formPayload.append(key, formData[key]);
    });
    if (editingDeal) {
      await axios.put(
        `https://scoreboard.codingindia.co.in/Deals/deals-models-detail/${editingDeal.id}/`,
        formPayload,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      alert("Deal updated successfully");
    } else {
      await axios.post(
        "https://scoreboard.codingindia.co.in/Deals/deals-models-list-create/",
        formPayload,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      alert("Deal created successfully");
    }
    setModalShow(false);
    fetchDeals();
    setFormData({
      deal_name: "",
      deal_description: "",
      deal_status: "",
      deal_date: "",
      hospital_name: "",
      concern_person: "",
      concern_person_contact: "",
      concern_person_email: "",
      payment_terms: "",
      percentage: "",
      deal_attachment: null,
    });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const toggleRowExpansion = (id) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  return (
    <div className="container-fluid mt-5">
      <Navbar />
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Deals Management</h1>
        <button className="btn btn-primary" onClick={() => setModalShow(true)}>
          Add New Deal
        </button>
      </div>
      <DealsFilter filters={filters} setFilters={setFilters} />
      <div className="">
        <div className="table-responsive">
          <table className="tables table-hover deals-table">
            <thead className="table-dark">
              <tr style={{ width: "100vw" }}>
                <th style={{ width: "5vw" }}>Sr. No.</th>
                <th style={{ width: "20vw" }}>Deal Name</th>
                <th style={{ width: "10vw" }}>Deal Date</th>
                <th style={{ width: "20vw" }}>Hospital</th>
                <th style={{ width: "10vw" }}>Payment Terms</th>
                <th style={{ width: "10vw" }}>Percentage</th>
                <th style={{ width: "5vw" }}>Status</th>
                <th style={{ width: "20vw" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredDeals.map((deal, index) => (
                <React.Fragment key={deal.id}>
                  <tr
                    className="clickable-row"
                    onClick={() => toggleRowExpansion(deal.id)}
                    style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
                  >
                    <td>{index + 1}</td>
                    <td>{deal.deal_name}</td>
                    <td>{deal.deal_date}</td>
                    <td>{deal.hospital_name}</td>
                    <td>{deal.payment_terms || "N/A"}</td>
                    <td>{deal.percentage || "N/A"}</td>
                    <td>{deal.deal_status}</td>
                    <td style={{ padding: "8px", paddingTop: "25px" }}>
                      {deal.deal_attachment && (
                        <>
                          <a
                            href={`https://scoreboard.codingindia.co.in${deal.deal_attachment}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-info me-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-eye-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                            </svg>
                          </a>
                        </>
                      )}

                      <button
                        className="btn btn-warning me-2"
                        onClick={() => handleEdit(deal)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                          />
                        </svg>
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(deal.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-archive-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1M.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8z" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                  {expandedRow === deal.id && (
                    <tr
                      className="expanded-row"
                      style={{ backgroundColor: "#e9ecef" }}
                    >
                      <td colSpan="8">
                        <div
                          className="expanded-content"
                          style={{
                            // padding: 20,
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <strong>Concern Person:</strong>{" "}
                              {deal.concern_person}
                            </span>{" "}
                            <span>
                              <strong>Contact:</strong>{" "}
                              {deal.concern_person_contact || "N/A"}
                            </span>{" "}
                            <span>
                              <strong>Email:</strong>{" "}
                              {deal.concern_person_email || "N/A"}
                            </span>{" "}
                          </div>
                          <strong>Description:</strong> {deal.deal_description}{" "}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {modalShow && (
        <div className="modal show " tabIndex="-1" style={{ width: "200vw !important", maxWidth: "200vw" }}>
          <div className="modal-dialog modal-dialog-centered" style={{ width: "200vw !important", maxWidth: "200vw" }}>
            <div className="modal-content" style={{ width: "100vw !important" }}>
              <div className="modal-header">
                <h5 className="modal-title">
                  {editingDeal ? "Edit Deal" : "Add New Deal"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setModalShow(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <label>Deal Name</label>
                  <input
                    type="text"
                    name="deal_name"
                    className="form-control"
                    value={formData.deal_name || ""}
                    onChange={handleChange}
                    required
                  />
                  <label>Description</label>
                  <textarea
                    name="deal_description"
                    className="form-control"
                    value={formData.deal_description || ""}
                    onChange={handleChange}
                  ></textarea>
                  <label>Status</label>
                  <input
                    type="text"
                    name="deal_status"
                    className="form-control"
                    value={formData.deal_status || ""}
                    onChange={handleChange}
                    required
                  />
                  <label>Deal Date</label>
                  <input
                    type="date"
                    name="deal_date"
                    className="form-control"
                    value={formData.deal_date || ""}
                    onChange={handleChange}
                    required
                  />
                  <label>Hospital Name</label>
                  <input
                    type="text"
                    name="hospital_name"
                    className="form-control"
                    value={formData.hospital_name || ""}
                    onChange={handleChange}
                    required
                  />
                  <label>Concern Person</label>
                  <input
                    type="text"
                    name="concern_person"
                    className="form-control"
                    value={formData.concern_person || ""}
                    onChange={handleChange}
                    required
                  />
                  <label>Contact</label>
                  <input
                    type="text"
                    name="concern_person_contact"
                    className="form-control"
                    value={formData.concern_person_contact || ""}
                    onChange={handleChange}
                    required
                  />
                  <label>Payment Terms</label>
                  <input
                    type="text"
                    name="payment_terms"
                    className="form-control"
                    value={formData.payment_terms || ""}
                    onChange={handleChange}
                    required
                  />
                  <label>Percentage</label>
                  <input
                    type="text"
                    name="percentage"
                    className="form-control"
                    value={formData.percentage || ""}
                    onChange={handleChange}
                  />
                  <label>Email</label>
                  <input
                    type="email"
                    name="concern_person_email"
                    className="form-control"
                    value={formData.concern_person_email || ""}
                    onChange={handleChange}
                  />
                  <label>Attachment</label>
                  <input
                    type="file"
                    name="deal_attachment"
                    className="form-control"
                    onChange={handleChange}
                  />
                  <div className="d-flex justify-content-end mt-4">
                    <button type="submit" className="btn btn-success">
                      {editingDeal ? "Update" : "Create"} Deal
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DealsPage;
