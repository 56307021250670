import React from 'react'
import { Link } from 'react-router-dom';


export const PageNotFound = ({prop}) => {

  return (
    <div>

      <section class="page_404">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 ">
            <div class="four_zero_four_bg text-center">
            <h1>Sorry!</h1>
                  <p>No Permission allowed to this user. Ask <b>Administrator</b> to assign permission.</p>
                </div>  
            </div>
          </div>
        </div>
      </section>
    </div>


  )
}
