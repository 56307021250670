import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "./NavBar";

import { useRef } from "react"; // Import useRef

const ClosingReportPage = () => {
  const [companies, setCompanies] = useState([]);
  const [activeCompany, setActiveCompany] = useState(null);
  const [activeDepartment, setActiveDepartment] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [formDetails, setFormDetails] = useState({
    CompanyName: "",
    docs: null,
    formonth: "",
    year: "",
    revenue: "",
    expense: "",
    dept: "",
    collection: "",
    profit: "",
    extra: "",
  });

  const [departments, setDepartments] = useState([]);
  const [editId, setEditId] = useState(null);

  const lastSelected = useRef({ company: null, department: null });

  useEffect(() => {
    fetchCompanies();
    fetchDepartments();
  }, []);

  const dynamicFieldConfig = {
    "Docladder Digital": {
      Operations: [
        { name: "collection", label: "Client" },
        { name: "revenue", label: "Revenue" },
      ],
    
      Finance: [
        { name: "collection", label: "Collection" },
        { name: "revenue", label: "Next Month Proposed Collection" },
        { name: "expense", label: "Expense" },
        { name: "profit", label: "Profit" },
        { name: "extra", label: "Bank Balance" },
      ],
      Marketing:[
        { name:"collection", label:"Client Onboard"},
        { name:"revenue", label:"Revenue"},
        { name:"profit", label:"Advance Amount"},
      ],
      Tech: [
        { name: "collection", label: "Module Completed" },
        { name: "revenue", label: "Department" },
        { name: "extra", label: "Module Working" },
        { name: "expense", label: "Deadline" },
        { name: "profit", label: "Completion" },
      ],

      HR: [
        { name: "collection", label: "Vacancy" },
        { name: "revenue", label: "On-boarded" },
        { name: "expense", label: "Salary" },
        { name: "profit", label: "Next Month Expected Salary" },
      ],
      Assets: [
        { name: "collection", label: "Assets"},
        { name: "revenue", label: "Type" },
        { name: "expense", label: "Problem" },
        { name: "profit", label: "Quantity" },
      ],
    },
    "Docladder Pvt Ltd": {
      Operations: [
        { name: "collection", label: "Closures" },
        { name: "revenue", label: "Revenue" },
        { name: "expense", label: "S Revenue" },
        { name: "profit", label: "SS Revenue" },
        { name: "extra", label: "Docladder Closures" },
      ],
      Finance: [
        { name: "collection", label: "Collection" },
        { name: "revenue", label: "Next Month Proposed Collection" },
        { name: "expense", label: "Expense" },
        { name: "profit", label: "Profit" },
        { name: "extra", label: "Bank Balance" },
      ],
      Marketing:[
        { name:"collection", label:"Client Onboard"},
        { name:"revenue", label:"Revenue"},
        { name:"profit", label:"Advance Amount"},
      ],
      Tech: [
        { name: "collection", label: "Module Completed" },
        { name: "revenue", label: "Department" },
        { name: "extra", label: "Module Working" },
        { name: "expense", label: "Deadline" },
        { name: "profit", label: "Completion" },
      ],
      HR: [
        { name: "collection", label: "Vacancy" },
        { name: "revenue", label: "On-boarded" },
        { name: "expense", label: "Salary" },
        { name: "profit", label: "Next Month Expected Salary" },
      ],
      Assets: [
        { name: "collection", label: "Assets"},
        { name: "revenue", label: "Type" },
        { name: "expense", label: "Problem" },
        { name: "profit", label: "Quantity" },
      ],
    },
    "Pace": {
      Operations: [{ name: "collection", label: "Closures" },
        { name: "revenue", label: "Revenue" },
        { name: "expense", label: "S Revenue" },
        { name: "profit", label: "SS Revenue" },
      ],
      Finance: [
        { name: "collection", label: "Collection" },
        { name: "revenue", label: "Next Month Proposed Collection" },
        { name: "expense", label: "Expense" },
        { name: "profit", label: "Profit" },
        { name: "extra", label: "Bank Balance" },
      ],
      Marketing:[
        { name:"collection", label:"Client Onboard"},
        { name:"revenue", label:"Revenue"},
        { name:"profit", label:"Advance Amount"},
      ],
      Tech: [
        { name: "collection", label: "Module Completed" },
        { name: "revenue", label: "Department" },
        { name: "extra", label: "Module Working" },
        { name: "expense", label: "Deadline" },
        { name: "profit", label: "Completion" },
      ],
      HR: [
        { name: "collection", label: "Vacancy" },
        { name: "revenue", label: "On-boarded" },
        { name: "expense", label: "Salary" },
        { name: "profit", label: "Next Month Expected Salary" },
      ],
      Assets: [
        { name: "collection", label: "Assets"},
        { name: "revenue", label: "Type" },
        { name: "expense", label: "Problem" },
        { name: "profit", label: "Quantity" },
      ],
    },
    
  };

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(
        "https://scoreboard.codingindia.co.in/hierarchical-closing-reports/",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      const sortedCompanies = response.data.map((company) => {
        if (company.company_name === "Pace") {
          company.departments.forEach((department) => {});
        }

        company.departments.forEach((department) => {
          department.reports.sort(
            (a, b) => b.year - a.year || b.formonth - a.formonth // Sort by year and month
          );
        });

        const latestReport = company.departments
          .flatMap((dept) => dept.reports)
          .sort((a, b) => b.year - a.year || b.formonth - a.formonth)[0];

        company.latestReport = latestReport; // Attach the latest report to the company

        return company;
      });

      // Sort companies by their latest report date
      sortedCompanies.sort((a, b) => {
        const aDate = a.latestReport
          ? new Date(a.latestReport.year, a.latestReport.formonth - 1)
          : new Date(0); // Default to the earliest date if no reports
        const bDate = b.latestReport
          ? new Date(b.latestReport.year, b.latestReport.formonth - 1)
          : new Date(0);

        return bDate - aDate; // Sort descending by date
      });

      setCompanies(sortedCompanies);
      // Restore last selected company and department if available
      if (lastSelected.current.company && lastSelected.current.department) {
        setActiveCompany(lastSelected.current.company);
        setActiveDepartment(lastSelected.current.department);
      } else if (sortedCompanies.length > 0) {
        // Default to the first company and department on reload
        setActiveCompany(sortedCompanies[0]?.company_id);
        setActiveDepartment(sortedCompanies[0]?.departments[0]?.department_id);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await axios.get("https://scoreboard.codingindia.co.in/departments/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setDepartments(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const getActiveCompanyData = () =>
    companies.find((company) => company.company_id === activeCompany);

  const getActiveDepartmentReports = () =>
    getActiveCompanyData()?.departments.find(
      (dept) => dept.department_id === activeDepartment
    );
  const getDynamicFields = () => {
    const companyName = getActiveCompanyData()?.company_name;
    const departmentName = getActiveDepartmentReports()?.department_name;

    if (dynamicFieldConfig[companyName]?.[departmentName]) {
      return dynamicFieldConfig[companyName][departmentName];
    }
    return [];
  };

  const openModal = (report = null) => {
    const dynamicFields = getDynamicFields();

    // Initialize dynamic fields
    const initialDynamicFields = dynamicFields.reduce((acc, { name }) => {
      acc[name] = report ? report[name] || "" : ""; // Use report values or empty
      return acc;
    }, {});

    setFormDetails({
      CompanyName: activeCompany,
      docs: null,
      formonth: report?.formonth || "",
      year: report?.year || "",
      dept: activeDepartment,
      ...initialDynamicFields, // Add dynamic fields to the form details
    });



    setEditId(report ? report.id : null);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setFormDetails({
      CompanyName: "",
      docs: null,
      formonth: "",
      year: "",
      revenue: "",
      expense: "",
      dept: "",
      collection: "",
      profit: "",
      extra: "",
    });
    setEditId(null);
  };

  const handleSubmit = async (e) => {



    e.preventDefault();
    const formData = new FormData();
    Object.entries(formDetails).forEach(([key, value]) => {
      if (value) formData.append(key, value);
    });

    const url = editId
      ? `https://scoreboard.codingindia.co.in/closing-reports/${editId}/`
      : "https://scoreboard.codingindia.co.in/closing-reports/";
    const method = editId ? "put" : "post";


    console.log(formDetails,"Data")
    try {
      // Save the current active company and department
      lastSelected.current = {
        company: activeCompany,
        department: activeDepartment,
      };
      await axios({
        method,
        url,
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      fetchCompanies();
      setActiveCompany(lastSelected.current.company);
      setActiveDepartment(lastSelected.current.department);
      closeModal();
    } catch (error) {
      console.error("Error saving report:", error);
    }
  };

  // Map headers based on department
  const getDynamicHeaders = (departmentName) => {
    switch (departmentName) {
      case "Tech":
        return [
          "Modules Completed",
          "Dept",
          "Deadline",
          "Modules Working",
          "Completion",
        ];
      case "Operations":
        return ["Closures", "Revenue", "S Revenue", "SS Revenue"];
      case "Marketing":
        return ["Client Onboard", "Revenue", "Advance Amount"];
      case "HR":
        return ["Vacancy", "Onboarded", "Salary", "Next Month Expected Salary"];
      case "Finance":
        return [
          "Collection",
          "Next Month Proposed Collection",
          "Expenses",
          "Profit",
          "Bank Balance",
        ];
        case "Assets":
        return [
          "Assets",
          "Type",
          "Problem",
          "Quantity",
          "Bank Balance",
        ];
      default:
        return ["Collection", "Revenue", "Expense", "Profit"];
    }
  };

  const renderDynamicFields = (departmentName, report) => {
    switch (departmentName) {
      case "Tech":
        return (
          <>
            <td className="border px-4 py-2">{report.collection || ""}</td>
            <td className="border px-4 py-2">{report.revenue || ""}</td>
            <td className="border px-4 py-2">{report.expense || ""}</td>
            <td className="border px-4 py-2">{report.extra || ""}</td>
            <td className="border px-4 py-2">{report.profit || ""}</td>
          </>
        );
      case "Operations":
        return (
          <>
            <td className="border px-4 py-2">{report.collection || ""}</td>
            <td className="border px-4 py-2">{report.revenue || ""}</td>
            <td className="border px-4 py-2">{report.expense || ""}</td>
            <td className="border px-4 py-2">{report.profit || ""}</td>
          </>
        );
      case "Marketing":
        return (
          <>
            <td className="border px-4 py-2">{report.collection || ""}</td>
            <td className="border px-4 py-2">{report.revenue || ""}</td>
            <td className="border px-4 py-2">{report.profit || ""}</td>
          </>
        );
      case "HR":
        return (
          <>
            <td className="border px-4 py-2">{report.collection || ""}</td>
            <td className="border px-4 py-2">{report.revenue || ""}</td>
            <td className="border px-4 py-2">{report.expense || ""}</td>
            <td className="border px-4 py-2">{report.profit || ""}</td>
          </>
        );
      case "Finance":
        return (
          <>
            <td className="border px-4 py-2">{report.collection || ""}</td>
            <td className="border px-4 py-2">{report.revenue || ""}</td>
            <td className="border px-4 py-2">{report.expense || ""}</td>
            <td className="border px-4 py-2">{report.profit || ""}</td>
            <td className="border px-4 py-2">{report.extra || ""}</td>
          </>
        );
        case "Assets":
        return (
          <>
            <td className="border px-4 py-2">{report.collection || ""}</td>
            <td className="border px-4 py-2">{report.revenue || ""}</td>
            <td className="border px-4 py-2">{report.expense || ""}</td>
            <td className="border px-4 py-2">{report.extra || ""}</td>
            <td className="border px-4 py-2">{report.profit || ""}</td>
          </>
        );
      default:
        return (
          <>
            <td className="border px-4 py-2">{report.collection || ""}</td>
            <td className="border px-4 py-2">{report.revenue || ""}</td>
            <td className="border px-4 py-2">{report.expense || ""}</td>
            <td className="border px-4 py-2">{report.profit || ""}</td>
          </>
        );
    }
  };

  const renderDynamicPlaceholders = (departmentName) => {
    switch (departmentName) {
      case "Tech":
        return {
          collection: "Modules Completed",
          revenue: "Department",
          extra: "Modules Working",
          expense: "Deadline",
          profit: "Completion",
        };
      // case "Operations":
      //   return {
      //     collection: "Closures",
      //     revenue: "Revenue",
      //     // revenue: "S Revenue",
      //     expense: "S Revenue",
      //     profit: "SS Revenue",
      //   };
      case "Marketing":
        return {
          collection: "Client Onboard",
          revenue: "Revenue",
          profit: "Advance Amount",
        };
      case "HR":
        return {
          collection: "Vacancy",
          revenue: "Onboarded",
          expense: "Salary",
          profit: "Next Month Expected Salary",
        };
      case "Finance":
        return {
          collection: "Collection",
          revenue: "Next Month Proposed",
          expense: "Expenses",
          profit: "Profit",
          extra: "Bank Balance",
        };
        case "Assets":
        return {
          collection: "Assets",
          revenue: "Type",
          expense: "Problem",
          profit: "Quantity",
        };
      default:
        return {
          collection: "Collection",
          revenue: "Revenue",
          expense: "Expense",
          profit: "Profit",
        };
    }
  };

  const placeholders = renderDynamicPlaceholders(
    getActiveDepartmentReports()?.department_name
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);

  const openDeleteModal = (report) => {
    setReportToDelete(report);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setReportToDelete(null);
  };

  const handleDelete = async () => {
    if (reportToDelete) {
      try {
        const response = await fetch(
          `https://scoreboard.codingindia.co.in/closing-reports/${reportToDelete.id}/`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );

        if (response.ok) {
          alert("Report deleted successfully!");
          fetchCompanies();
          // Optionally, refresh data or remove the report from the state
        } else {
          alert("Failed to delete the report.");
        }
      } catch (error) {
        console.error("Error deleting report:", error);
        alert("An error occurred. Please try again.");
      } finally {
        closeDeleteModal();
      }
    }
  };

  return (
    <>
      <Navbar />

      <div className="flex min-h-screen bg-gray-100">
        {/* Left Sidebar */}
        <div className="w-1/4 bg-white shadow-md">
          <h2 className="text-xl font-bold p-4 border-b">Companies</h2>
          <ul className="space-y-1 p-4">
            {companies.map((company) => (
              <li
                key={company.company_id}
                onClick={() => setActiveCompany(company.company_id)}
                className={`p-2 rounded-lg cursor-pointer font-medium ${
                  activeCompany === company.company_id
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 hover:bg-gray-300 text-gray-800"
                }`}
              >
                {company.company_name}
              </li>
            ))}
          </ul>
        </div>

        {/* Main Content */}
        <div className="flex-1 p-4">
          {getActiveCompanyData() ? (
            <>
              <h1 className="text-2xl font-bold mb-4">
                {getActiveCompanyData().company_name} - Departments
              </h1>

              {/* Department Buttons */}
              <div className="flex flex-wrap gap-2 mb-4">
                {getActiveCompanyData()?.departments.map((department) => (
                  <button
                    key={department.department_id}
                    onClick={() =>
                      setActiveDepartment(department.department_id)
                    }
                    className={`px-3 py-1 rounded-lg font-medium ${
                      activeDepartment === department.department_id
                        ? "bg-green-500 text-white"
                        : "bg-gray-200 hover:bg-gray-300 text-gray-800"
                    }`}
                  >
                    {department.department_name}
                  </button>
                ))}
              </div>

              {/* Add Report Button */}
              <div className="mb-4">
                <button
                  onClick={() => openModal()}
                  className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 transition"
                >
                  Add New Report
                </button>
              </div>

              {/* Reports Section */}
              <div className="bg-white p-4 rounded-lg shadow-md">
                {activeDepartment ? (
                  <>
                    <h2 className="text-xl font-bold mb-4">
                      {getActiveDepartmentReports()?.department_name} Reports
                    </h2>
                    {getActiveDepartmentReports()?.reports.length > 0 ? (
                      <table className="min-w-full bg-white border border-gray-200">
                        
                        
                        <thead className="bg-gray-100">
  <tr>
    <th className="border px-4 py-2 text-left">#</th>
    <th className="border px-4 py-2 text-left">Month</th>
    <th className="border px-4 py-2 text-left">Year</th>
    {getDynamicFields().map((field) => (
      <th key={field.name} className="border px-4 py-2 text-left">
        {field.label}
      </th>
    ))}
    <th className="border px-4 py-2 text-left">Document</th>
    <th className="border px-4 py-2 text-left">Actions</th>
  </tr>
</thead>


<tbody>
  {getActiveDepartmentReports()?.reports.map((report, index) => (
    <tr key={report.id} className="hover:bg-gray-100 transition">
      <td className="border px-4 py-2">{index + 1}</td>
      <td className="border px-4 py-2">{report.formonth}</td>
      <td className="border px-4 py-2">{report.year}</td>
      {getDynamicFields().map((field) => (
        <td key={field.name} className="border px-4 py-2">
          {report[field.name] || ""}
        </td>
      ))}
      <td className="border px-4 py-2">
        <a
          href={`https://scoreboard.codingindia.co.in/media/${report.docs}/`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          View
        </a>
      </td>
      <td className="border px-4 py-2">
        <button
          onClick={() => openModal(report)}
          className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 transition"
        >
          Edit
        </button>
        <button
          onClick={() => openDeleteModal(report)}
          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 transition ml-3"
        >
          Delete
        </button>
      </td>
    </tr>
  ))}
</tbody>
                        {/* Delete Confirmation Modal */}
                        {isDeleteModalOpen && (
                          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                            <div className="bg-white p-6 rounded shadow-md">
                              <h2 className="text-lg font-semibold">
                                Confirm Deletion
                              </h2>
                              <p className="my-4">
                                Are you sure you want to delete this report?
                                This action cannot be undone.
                              </p>
                              <div className="flex justify-end space-x-4">
                                <button
                                  onClick={closeDeleteModal}
                                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition"
                                >
                                  Cancel
                                </button>
                                <button
                                  onClick={handleDelete}
                                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </table>
                    ) : (
                      <p className="text-gray-500">
                        No reports available for this department.
                      </p>
                    )}
                  </>
                ) : (
                  <p className="text-gray-500">
                    Select a department to view reports.
                  </p>
                )}
              </div>
            </>
          ) : (
            <p className="text-gray-500">Loading companies...</p>
          )}
        </div>
      </div>

      {/* Modal */}
      {modalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded shadow-lg w-1/2">
            <h2 className="text-lg font-bold mb-4">
              {editId ? "Edit Report" : "Add Report"}
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <select
                name="CompanyName"
                value={formDetails.CompanyName}
                onChange={(e) =>
                  setFormDetails((prev) => ({
                    ...prev,
                    CompanyName: e.target.value,
                  }))
                }
                className="border p-2 rounded w-full"
              >
                <option value="">Select Company</option>
                {companies.map((company) => (
                  <option key={company.company_id} value={company.company_id}>
                    {company.company_name}
                  </option>
                ))}
              </select>

              <select
                name="formonth"
                value={formDetails.formonth}
                onChange={(e) =>
                  setFormDetails((prev) => ({
                    ...prev,
                    formonth: parseInt(e.target.value, 10),
                  }))
                }
                className="border p-2 rounded w-full"
              >
                <option value="">Select Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <select
                name="dept"
                value={formDetails.dept}
                onChange={(e) =>
                  setFormDetails((prev) => ({
                    ...prev,
                    dept: e.target.value,
                  }))
                }
                className="border p-2 rounded w-full"
              >
                <option value="">Select Department</option>
                {departments.map((dept) => (
                  <option key={dept.id} value={dept.id}>
                    {dept.name}
                  </option>
                ))}
              </select>

              <input
                type="number"
                name="year"
                value={formDetails.year}
                onChange={(e) =>
                  setFormDetails((prev) => ({
                    ...prev,
                    year: e.target.value,
                  }))
                }
                placeholder="Year"
                className="border p-2 rounded w-full"
              />

              <input
                type="file"
                name="docs"
                onChange={(e) =>
                  setFormDetails((prev) => ({
                    ...prev,
                    docs: e.target.files[0],
                  }))
                }
                className="border p-2 rounded w-full"
              />

              {getDynamicFields().map(({ name, label }) => (
                <div key={name} className="form-group">
                  <input
                    type="text"
                    id={name}
                    name={name}
                    placeholder={label}
                    className="border p-2 rounded w-full"
                    value={formDetails[name] || ""}
                    onChange={(e) =>
                      setFormDetails((prev) => ({
                        ...prev,
                        [name]: e.target.value,
                      }))
                    }
                  />
                </div>
              ))}

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={closeModal}
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ClosingReportPage;
