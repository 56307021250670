import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Announcementslider from "./Announcementslider";

function Viewers({ prop }) {
  const location = useLocation();
  const stateValue = location.state && location.state["userPermissions"];
  const [dailyEntryData, setdailyEntryData] = useState([]);
  // console.log("State Value", stateValue);
  const [data, setData] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [isAuth, setIsAuth] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isSuperuser, setIsSuperuser] = useState(false); // State to store superuser status
  const navigate = useNavigate();
  const [latestNews, setLatestNews] = useState(null);
  const [pipelineandturnedup, setPipelineandturnedup] = useState(null);
  const handleSignOut = () => {
    // For illustration purposes, let's clear the access token from localStorage
    localStorage.removeItem("access_token");

    // Redirect to the home page
    navigate("/");
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      setIsAuth(true); // Set authentication status to true if access token is present
      fetch("https://scoreboard.codingindia.co.in/GetAllRecord/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setData(data.records || []); // Update state with fetched data
          setdailyEntryData(data.monthly_records || []); // Update state with fetched data

          setIsSuperuser(data.is_superuser);
          setUserPermissions(data.user_permissions || []);
          setLatestNews(data.latest_news);
          setPipelineandturnedup(data.pipelineandturnedup || []);

          if (data.latest_news == "New News Updated") {
            navigate("/celebrationPage");
          }
        })
        .catch((error) => {});
    }
  }, []);

  // const sortedData = [...dailyEntryData].sort((a, b) => {
  //   // Get the pipeline values from pipelineAndTurnedUpEntry
  //   const pipelineA = pipelineandturnedup.find(entry => entry.user === a.user)?.pipline || 0;
  //   const pipelineB = pipelineandturnedup.find(entry => entry.user === b.user)?.pipline || 0;

  //   // First, sort by revenue in descending order
  //   if (b.revenue !== a.revenue) {
  //     return b.revenue - a.revenue; // Sort in descending order by revenue
  //   }

  //   // If revenue is the same, sort by selected in descending order
  //   if (b.selected !== a.selected) {
  //     return b.selected - a.selected; // Sort in descending order by selected
  //   }

  //   // Calculate the percentage of turned_up based on resume_forwarded for both a and b
  //   const percentageA = a.resume_forwarded !== 0 ? (a.turned_up / a.resume_forwarded) * 100 : 0;
  //   const percentageB = b.resume_forwarded !== 0 ? (b.turned_up / b.resume_forwarded) * 100 : 0;

  //   // Sort by the percentage in descending order
  //   if (percentageB !== percentageA) {
  //     return percentageB - percentageA; // Sort in descending order by the percentage
  //   }

  //   // If selected is the same, sort by resume_forwarded in descending order
  //   if (b.resume_forwarded !== a.resume_forwarded) {
  //     return b.resume_forwarded - a.resume_forwarded; // Sort in descending order by resume forwarded
  //   }

  //   // If resume_forwarded is the same, sort by pipeline in descending order
  //   if (pipelineB !== pipelineA) {
  //     return pipelineB - pipelineA;
  //   }

  //   // If pipeline is the same, sort by turned up in descending order
  //   if (b.turned_up !== a.turned_up) {
  //     return b.turned_up - a.turned_up; // Sort in descending order by turned up
  //   }

  //   return 0; // If all criteria are the same, keep the original order
  // });
  const sortedData = useMemo(() => {
    if (!dailyEntryData || !pipelineandturnedup) return [];

    return dailyEntryData
      .map((entry) => {
        const pipeline =
          pipelineandturnedup.find((item) => item.user === entry.user)
            ?.pipline || 0;

        const turnedUpRatio =
          entry.turned_up && pipeline
            ? ((entry.selected + entry.turned_up) / entry.resume_forwarded) *
              100
            : 0;

        return {
          ...entry,
          pipeline,
          turnedUpRatio,
        };
      })
      .sort((a, b) => {
        // Sort by revenue
        if (b.revenue !== a.revenue) return b.revenue - a.revenue;
        // Sort by selected candidates
        if (b.selected !== a.selected) return b.selected - a.selected;
        // Sort by turned-up ratio
        if (b.turnedUpRatio !== a.turnedUpRatio)
          return b.turnedUpRatio - a.turnedUpRatio;
        // Sort by resumes forwarded
        if (b.resume_forwarded !== a.resume_forwarded)
          return b.resume_forwarded - a.resume_forwarded;
        // Sort by pipeline
        return b.pipeline - a.pipeline;
      });
  }, [dailyEntryData, pipelineandturnedup]);

  // Calculate the totals for each column
  const totalForwarded = sortedData.reduce(
    (acc, curr) => acc + curr.resume_forwarded,
    0
  );
  const totalPipeline = sortedData.reduce(
    (acc, curr) => acc + curr.pipeline,
    0
  );
  const totalTurnedUp = sortedData.reduce(
    (acc, curr) => acc + curr.turned_up,
    0
  );
  const totalSelected = sortedData.reduce(
    (acc, curr) => acc + curr.selected,
    0
  );
  const totalRevenue = sortedData.reduce(
    (acc, curr) => acc + (isNaN(curr.revenue) ? 0 : parseFloat(curr.revenue)),
    0
  );
  const totalTargetRevenue = sortedData.reduce((acc, curr) => {
    const correspondingRecord = data.find((record) => record.id === curr.user);
    return acc + (correspondingRecord?.revenue || 0);
  }, 0);

  // ############################ Iteration ########################################
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const totalPages = Math.ceil((data && data.length) / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentViewers =
    sortedData && sortedData.slice(indexOfFirstRecord, indexOfLastRecord);
  const globalRecordCount = (currentPage - 1) * recordsPerPage;

  const paginate = () => {
    setCurrentPage((prevPage) => (prevPage === totalPages ? 1 : prevPage + 1));
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const focusOnRecord = (index) => {
        setFocusedIndex(index);

        const timer = setTimeout(() => {
          const nextIndex = index + 1;

          if (nextIndex >= currentViewers.length) {
            if (currentPage < totalPages) {
              paginate();
              setFocusedIndex(0);
            } else {
              if (
                !isSuperuser &&
                !userPermissions.includes("website.see_records") &&
                !userPermissions.includes("website.update_target")
              ) {
                navigate("/slider");
              }
            }
          } else {
            setFocusedIndex(nextIndex); // Focus on the next record
          }
        }, 2000);

        // Cleanup timer
        return () => clearTimeout(timer);
      };

      // Start focusing on the first record when the component mounts or data changes
      focusOnRecord(focusedIndex);

      // Reset focus index when the component unmounts
      return () => setFocusedIndex(0);
    }
  }, [
    focusedIndex,
    data,
    currentViewers,
    currentPage,
    totalPages,
    paginate,
    navigate,
    isSuperuser,
    userPermissions,
  ]);

  // ############################ Iteration ########################################

  const openModal = (record) => {
    setSelectedRecord(record);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedRecord(null);
  };

  const handleUpdate = () => {
    if (selectedRecord) {
      const formData = new FormData();
      formData.append("id", selectedRecord.id);
      formData.append("name", document.getElementById("recordName").value);
      formData.append(
        "resume_forwarded",
        document.getElementById("forwarded").value
      );
      formData.append("pipline", document.getElementById("pipline").value);
      formData.append("turned_up", document.getElementById("turned_up").value);
      formData.append("selected", document.getElementById("selected").value);
      formData.append("target", document.getElementById("target").value);
      // Handle file upload, if available
      const imageInput = document.getElementById("image");
      if (imageInput.files.length > 0) {
        formData.append("image", imageInput.files[0]);
      }

      fetch("https://scoreboard.codingindia.co.in/UpdateRecord/", {
        method: "PUT",
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          closeModal();
          fetch("https://scoreboard.codingindia.co.in/GetAllRecord/", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((data) => {
              setData(data.records || []); // Update state with fetched data
              setdailyEntryData(data.monthly_records || []); // Update state with fetched data

              setIsSuperuser(data.is_superuser);
            })
            .catch((error) => {});
        })
        .catch((error) => {
          // console.error("There was a problem with the fetch operation:", error);
          // Handle errors here
        });
    }
  };

  const handleDelete = (id, e) => {
    e.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );

    if (isConfirmed) {
      fetch(`https://scoreboard.codingindia.co.in/DelRecord/${id}/`, {
        method: "DELETE",
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          // console.log("Deleted Successfully");
        })
        .then((data) => {
          // console.log("Deleted record:", data);
          closeModal();

          fetch("https://scoreboard.codingindia.co.in/GetAllRecord/", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((data) => {
              setData(data.records || []);
              setdailyEntryData(data.monthly_records || []); // Update state with fetched data

              setIsSuperuser(data.is_superuser);
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    }
  };

  return (
    <>
      <section>
        <div>
          <div>
            <div>
              <div>
                {/****************************** Viewer Code (Someone) ******************************/}
                <table
                  class="myTable min-w-full bg-white"
                  style={{ width: "95%", margin: "auto" }}
                >
                  <thead>
                    <tr>
                      {/* <th scope="col">Rank</th>  */}
                      <th scope="col" class="rankWidth">
                        Ranks
                      </th>
                      <th scope="col" class="hrWidth">
                        Image
                      </th>
                      <th scope="col" class="nameWidth">
                        Name
                      </th>
                      <th scope="col" class="resumeWidth">
                        Resume Forwarded
                      </th>
                      <th scope="col" class="pipWidth">
                        Pipeline
                      </th>
                      <th scope="col" class="turWidth">
                        Turned Up
                      </th>
                      <th scope="col" class="selWidth">
                        Selected Candidate
                      </th>
                      <th scope="col" className="py-2 px-4 border-b">
                        Turned Up Ratio
                        <Link
                          className="signOutLink"
                          to={{ pathname: "/" }}
                          onClick={handleSignOut}
                        >
                          <i class="fa-solid fa-right-from-bracket"></i>
                        </Link>
                      </th>
                      {!isSuperuser &&
                        userPermissions.includes("website.see_records") && (
                          <th className="col">Options</th>
                        )}
                    </tr>
                  </thead>

                  <tbody>
                    {/* {dailyEntryData && sortedData && */}
                    {sortedData &&
                      currentViewers.map((record, index) => {
                        // Calculate the actual index of the record in the entire dataset
                        const actualIndex = indexOfFirstRecord + index;

                        // Find the corresponding entry in pipelineandturnedup
                        const correspondingEntry = pipelineandturnedup.find(
                          (entry) => entry.user === record.user
                        );

                        const correspondingRecord = data.find(
                          (i) => i.id === record.user
                        );

                        // If a corresponding record is found, extract the image and name
                        const imageSrc = correspondingRecord
                          ? `https://scoreboard.codingindia.co.in${correspondingRecord.image}`
                          : "";
                        const userName = correspondingRecord
                          ? correspondingRecord.name
                          : "";

                        // If corresponding entry is found, extract the values
                        const pipeline = correspondingEntry
                          ? correspondingEntry.pipline
                          : "";
                        const turnedUp = correspondingEntry
                          ? correspondingEntry.turned_up
                          : "";

                        return (
                          <>
                            {globalRecordCount + index + 1 === 1 &&
                            record.resume_forwarded >= 1 ? (
                              <>
                                <tr
                                  key={actualIndex}
                                  className={`${
                                    index === focusedIndex ? "firstactives" : ""
                                  }`}
                                  style={{
                                    height: "10vh!important",
                                    position: "relative",
                                  }}
                                >
                                  {/* Award Ranking */}
                                  <td
                                    style={{ textAlign: "center" }}
                                    className="py-2 px-4 border-b text-center"
                                  >
                                    <div className="awards">
                                      <span
                                        className={`${
                                          index === focusedIndex
                                            ? "text-4xl font-bold  recordRanking award-content"
                                            : "text-xl recordRanking award-content"
                                        }`}
                                      >
                                        {globalRecordCount + index + 1}
                                      </span>
                                      <span className="image-content"></span>
                                    </div>
                                  </td>
                                  {/* Image */}
                                  <td className="py-2 px-4 border-b text-center">
                                    {/* Display the image if available */}
                                    {imageSrc && (
                                      <img
                                        src={imageSrc}
                                        className="w-auto"
                                        style={{ height: "100px" }}
                                        alt=""
                                      />
                                    )}
                                  </td>
                                  <td className="py-2 px-4 border-b text-center">
                                    {/* Display the user name if available */}
                                    {userName && <span>{userName}</span>}
                                    <br />
                                  </td>
                                  {/* Resume forwarded */}
                                  <td className="py-2 px-4 border-b text-center">
                                    {record.resume_forwarded}
                                  </td>
                                  {/* Pipeline */}
                                  <td className="py-2 px-4 border-b text-center">
                                    {pipeline}
                                  </td>
                                  {/* Turned Up */}
                                  <td className="py-2 px-4 border-b text-center">
                                    {record.turned_up}
                                  </td>
                                  {/* Selected */}
                                  <td className="py-2 px-4 border-b text-center">
                                    {record.selected}
                                  </td>

                                  <td className="py-2 px-4 border-b text-center">
                                    {/* Calculate percentage here */}
                                    {record.resume_forwarded !== 0
                                      ? (
                                          (record.turned_up /
                                            record.resume_forwarded) *
                                          100
                                        ).toFixed(2)
                                      : "0.00"}
                                    %
                                  </td>
                                  {/* Edit Button */}
                                  {!isSuperuser &&
                                    userPermissions.includes(
                                      "website.see_records"
                                    ) && (
                                      <td className="py-2 px-4 border-b text-center">
                                        <button
                                          className="btn btn-primary"
                                          onClick={() => openModal(record)}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-pencil"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                          </svg>
                                        </button>
                                      </td>
                                    )}
                                </tr>
                              </>
                            ) : (
                              <tr
                                key={actualIndex}
                                className={`${
                                  index === focusedIndex ? "actives" : ""
                                }`}
                                style={{
                                  height: "10vh!important",
                                  position: "relative",
                                }}
                              >
                                {/* Award Ranking */}
                                <td
                                  style={{ textAlign: "center" }}
                                  className="py-2 px-4 border-b text-center"
                                >
                                  <div className="awards">
                                    <span
                                      className={`${
                                        index === focusedIndex
                                          ? "text-4xl font-bold  recordRanking award-content"
                                          : "text-xl recordRanking award-content"
                                      }`}
                                    >
                                      {globalRecordCount + index + 1}
                                    </span>
                                    <span className="image-content"></span>
                                  </div>
                                </td>
                                {/* Image */}
                                <td className="py-2 px-4 border-b text-center">
                                  {/* Display the image if available */}
                                  {imageSrc && (
                                    <img
                                      src={imageSrc}
                                      className="w-auto"
                                      style={{ height: "100px" }}
                                      alt=""
                                    />
                                  )}
                                </td>
                                <td className="py-2 px-4 border-b text-center">
                                  {/* Display the user name if available */}
                                  {userName && <span>{userName}</span>}
                                  <br />
                                </td>
                                {/* Resume forwarded */}
                                <td className="py-2 px-4 border-b text-center">
                                  {record.resume_forwarded}
                                </td>
                                {/* Pipeline */}
                                <td className="py-2 px-4 border-b text-center">
                                  {pipeline}
                                </td>
                                {/* Turned Up */}
                                <td className="py-2 px-4 border-b text-center">
                                  {record.turned_up}
                                </td>
                                {/* Selected */}
                                <td className="py-2 px-4 border-b text-center">
                                  {record.selected}
                                </td>

                                <td className="py-2 px-4 border-b text-center">
                                  {/* Calculate percentage here */}
                                  {record.resume_forwarded !== 0
                                    ? (
                                        (record.turned_up /
                                          record.resume_forwarded) *
                                        100
                                      ).toFixed(2)
                                    : "0.00"}
                                  %
                                </td>
                                {/* Edit Button */}
                                {!isSuperuser &&
                                  userPermissions.includes(
                                    "website.see_records"
                                  ) && (
                                    <td className="py-2 px-4 border-b text-center">
                                      <button
                                        className="btn btn-primary"
                                        onClick={() => openModal(record)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-pencil"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                      </button>
                                    </td>
                                  )}
                              </tr>
                            )}
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <Announcementslider />
      </section>

      {showModal && (
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Record</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body">
                {selectedRecord && (
                  <form>
                    <input
                      type="hidden"
                      className="form-control myModelForm"
                      id="recordId"
                      value={selectedRecord.id}
                    />

                    <div className="mb-3">
                      <label htmlFor="recordName" className="form-label">
                        Record Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recordName"
                        value={selectedRecord.name}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="recordName" className="form-label">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recordName"
                        value={selectedRecord.username}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="forwarded" className="form-label">
                        Resume Forwarded
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="forwarded"
                        value={selectedRecord.resume_forwarded}
                        onChange={(e) =>
                          setSelectedRecord({
                            ...selectedRecord,
                            resume_forwarded: e.target.value,
                          })
                        }
                        readOnly={
                          !isSuperuser &&
                          !userPermissions.includes("website.see_records") &&
                          userPermissions.includes("website.update_target")
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="pipline" className="form-label">
                        Pipeline
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="pipline"
                        value={selectedRecord.pipline}
                        onChange={(e) =>
                          setSelectedRecord({
                            ...selectedRecord,
                            pipline: e.target.value,
                          })
                        }
                        readOnly={
                          !isSuperuser &&
                          !userPermissions.includes("website.see_records") &&
                          userPermissions.includes("website.update_target")
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="turned_up" className="form-label">
                        Turned Up
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="turned_up"
                        value={selectedRecord.turned_up}
                        onChange={(e) =>
                          setSelectedRecord({
                            ...selectedRecord,
                            turned_up: e.target.value,
                          })
                        }
                        readOnly={
                          !isSuperuser &&
                          !userPermissions.includes("website.see_records") &&
                          userPermissions.includes("website.update_target")
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="selected" className="form-label">
                        Selected
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="selected"
                        value={selectedRecord.selected}
                        onChange={(e) =>
                          setSelectedRecord({
                            ...selectedRecord,
                            selected: e.target.value,
                          })
                        }
                        readOnly={
                          !isSuperuser &&
                          !userPermissions.includes("website.see_records") &&
                          userPermissions.includes("website.update_target")
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="target" className="form-label">
                        Target
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="target"
                        value={selectedRecord.target}
                        onChange={(e) =>
                          setSelectedRecord({
                            ...selectedRecord,
                            target: e.target.value,
                          })
                        }
                        readOnly={
                          !isSuperuser &&
                          !userPermissions.includes("website.update_target") &&
                          userPermissions.includes("website.see_records")
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="image" className="form-label">
                        Image URL
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        id="image"
                        onChange={(e) =>
                          setSelectedRecord({
                            ...selectedRecord,
                            image: e.target.value,
                          })
                        }
                        readOnly={
                          !(
                            !isSuperuser &&
                            userPermissions.includes("website.update_target") &&
                            !userPermissions.includes("website.see_records")
                          )
                        }
                      />
                    </div>
                    <div className="btncontainer">
                      <button
                        type="button"
                        className="btn btn-primary m-2"
                        onClick={handleUpdate}
                      >
                        Save Changes
                      </button>
                      <button
                        className="btn btn-danger m-2"
                        onClick={(e) => handleDelete(selectedRecord.id, e)}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary m-2"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Viewers;
