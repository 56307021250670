import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./NavBar";

const FilterMonthlyRecords = () => {
  const [records, setRecords] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await axios.get(
          "https://scoreboard.codingindia.co.in/FilterMonthlyRecords/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        setRecords(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch records");
        setLoading(false);
      }
    };

    fetchRecords();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // Sort the records by username
  const sortedRecords = Object.entries(records).sort(([a], [b]) =>
    a.localeCompare(b)
  );

  return (
    <>
      <Navbar />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Monthly Records</h1>
        {sortedRecords.length > 0 ? (
          sortedRecords.map(([username, userRecords]) => {
            // Calculate totals
            const totals = userRecords.reduce(
              (acc, record) => ({
                total_resume_forwarded:
                  acc.total_resume_forwarded + record.total_resume_forwarded,
                total_selected: acc.total_selected + record.total_selected,
                total_revenue: acc.total_revenue + record.total_revenue,
              }),
              { total_resume_forwarded: 0, total_selected: 0, total_revenue: 0 }
            );

            return (
              <div key={username} className="mb-6">
                <h2 className="text-xl font-semibold mb-2">{username}</h2>
                <table className="w-full border-collapse border border-gray-300">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="border border-gray-300 text-dark px-4 py-2">
                        Month Start
                      </th>
                      <th className="border border-gray-300 text-dark px-4 py-2">
                        Month End
                      </th>
                      <th className="border border-gray-300 text-dark px-4 py-2">
                        Resume Forwarded
                      </th>
                      <th className="border border-gray-300 text-dark px-4 py-2">
                        Selected
                      </th>
                      <th className="border border-gray-300 text-dark px-4 py-2">
                        Revenue
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userRecords.map((record, index) => (
                      <tr key={index} className="text-center">
                        <td className="border border-gray-300 px-4 py-2">
                          {record.month_start}
                        </td>
                        <td className="border border-gray-300 px-4 py-2">
                          {record.month_end}
                        </td>
                        <td className="border border-gray-300 px-4 py-2">
                          {record.total_resume_forwarded}
                        </td>
                        <td className="border border-gray-300 px-4 py-2">
                          {record.total_selected}
                        </td>
                        <td className="border border-gray-300 px-4 py-2">
                          {record.total_revenue.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    {/* Total Row */}
                    <tr className="bg-gray-200 font-bold text-center">
                      <td
                        className="border border-gray-300 px-4 py-2"
                        colSpan={2}
                      >
                        Total
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {totals.total_resume_forwarded}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {totals.total_selected}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {totals.total_revenue.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })
        ) : (
          <div>No records found.</div>
        )}
      </div>
    </>
  );
};

export default FilterMonthlyRecords;
