import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Sidebar from "../CustomNavbar";

const AllannouncementsForUser = () => {
  const [announcements, setAnnouncements] = useState([]);
  const location = useLocation();
  const additionalData = location.state?.additionalProp;

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(
          "https://scoreboard.codingindia.co.in/ShowAnnouncements/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        setAnnouncements(response.data);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, []);

  const formatDateTime = (datetimeString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(datetimeString).toLocaleString(undefined, options);
  };

  const handleDelete = async (id) => {
    const userConfirmed = window.confirm("Are you sure you want to delete?");
    if (userConfirmed) {
      try {
        await axios.delete(`https://scoreboard.codingindia.co.in/DeleteAnnouncement/${id}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        setAnnouncements((prev) =>
          prev.filter((announcement) => announcement.id !== id)
        );
      } catch (error) {
        console.error("Error deleting announcement:", error);
      }
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="p-4 mt-3">
        <div
          className="container"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h2
            className="text-4xl text-center mb-4"
            style={{ color: "var(--bs-blue)", letterSpacing: "0.5px" }}
          >
            Announcements
          </h2>
          <Link to="/createannouncements">
            <button
              className="bottom-4 right-4 shadow-lg text-xl focus:outline-none"
              style={{
                borderRadius: "7px",
                background: "var(--bg-blue)",
                color: "white",
                display: "flex",
                justifyContent: "space-around",
                padding: "15px"
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-file-earmark-plus"
                viewBox="0 0 16 16"
                style={{ marginRight: "8px" }}
              >
                <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5" />
                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
              </svg>
              <span style={{ fontSize: "0.8rem" }}>Create Announcements</span>
            </button>
          </Link>
        </div>
        <div>
          <table
            className="min-w-full bg-white border rounded-lg shadow-md"
            style={{ width: "100%", overflowX: "hidden", borderRadius: "15px" }}
          >
            <thead className=" text-white">
              <tr style={{ background: "var(--bg-blue)", borderRadius: "16px" }}>
                <th className="py-3 px-6 text-center">Announcement</th>
                <th className="py-3 px-6 text-center">Date</th>
                <th className="py-3 px-6 text-center">Expiry Date</th>
                <th className="py-3 px-6 text-center">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {announcements.length > 0 ? (
                announcements.map((announcement) => (
                  <tr key={announcement.id} className="hover:bg-gray-50">
                    <td className="py-4 px-6">{announcement.name}</td>
                    <td className="py-4 px-6">
                      {formatDateTime(announcement.dateadded)}
                    </td>
                    <td className="py-4 px-6">
                      {formatDateTime(announcement.activity_date)}
                    </td>
                    <td className="py-4 px-6 text-center">
                      <button
                        onClick={() => handleDelete(announcement.id)}
                        className="text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
                        style={{ background: "var(--bg-blue)" }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="4"
                    className="py-6 px-4 text-center text-lg text-gray-500"
                  >
                    No announcements available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AllannouncementsForUser;
