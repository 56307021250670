// import Login from './components/styles/Login';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Records from './components/Records';
import CreateRecord from './components/CreateRecord';
import UpdateRecord from './components/UpdateRecord';
import RecordSlider from './components/RecordSlider';
import TLSlider from "./components/Leaderboard/Leaders/TLSlider";
import CreateAnnouncements from "./components/CreateAnnouncements";
import Viewers from "./components/Viewers";
import UserView from "./components/UserView";
import SubAdminPage from "./components/SubAdminPage";
import Login from "./components/Login";
import Navbar from "./components/NavBar";
import Announcements from "./components/Announcement";
import BusinessHead from "./components/BusinessHead";
import CentralManager from "./components/CentralManager";
import Allannouncements from "./components/Allannouncements";
import WelcomeUser from "./components/WelcomeUser";

import OnBoardingUser from "./components/OnBoardingUser";
import CreateTeamLeader from "./components/TeamLeader/createteamleader";
import ShowTeamLeader from "./components/TeamLeader/showTeamLeader";
// import CreateTeamLeadertarget from './components/TeamLeader/CreateTeamLeaderTarget';
import CreateTeamLeaderTarget from "./components/TeamLeader/CreateTeamLeaderTarget";
import ShowTeamLeaderType from "./components/TeamLeader/ShowTeamLeaderType";
import UserWiseTeamLeader from './components/TeamLeader/UserWiseTeamLeader';
import { PageNotFound } from "./components/PageNotFound";
import CelebrationPage from "./components/CelebrationPage";
import LeadersTable from "./components/Leaderboard/Leaders/LeadersTable";
import LeaderTasks from "./components/Leaderboard/Tasks/LeaderTasks";
import CurrentMonthTaskForm from './components/Leaderboard/Tasks/CurrentMonthTaskForm';
import ViewTeamLead from "./components/TeamLeader/ViewTeamLead";
import Training from "./components/Training/training";
import AllMinutesOfMeeting from "./components/MoM/momlist";
import CreateMoMs from './components/MoM/CreateMom';
import CreateTeamMember from './components/Docladder/createteammember';
import ShowDocladderMember from './components/Docladder/ShowDocladderMember';
import MemberTasks from "./components/Docladder/MemberTasks";
import UserWiseTeammember from "./components/Docladder/UserWiseTeamMember";
import CurrentMonthDocladderTaskForm from "./components/Leaderboard/Tasks/CurrentMonthDocladderTaskForm";
import DocladderSlider from "./components/Docladder/DocladderSlider";
import UserRecordComparison from "./components/UserRecordComparison";
import MarketingFilesPage from "./components/MarketingFilesPage";
import TeamRecords from "./components/TeamRecord";
import DocladderRecordSlider from "./components/Docladder/DocladderRecordSlider";
import AddFileType from "./components/PartnerFileType/FileType";
import PartnersFiles from "./components/PartnerFileType/PartnersFile";
import ClosingReportPage from "./components/ClosingReport";
import SopModelPage from "./components/SOP/sop";
import DesignationPage from "./components/SOP/designationPage";
import DailyUserRecordsForm from "./components/DailyUserRecordOldEntry";
import UserOldRecordUpdateView from "./components/userviewupdateoldrecord";
import PrivateFileList from "./components/PrivateFiles/ShowPrivateFiles";
import CreatePrivateFilesPage from "./components/PrivateFiles/PrivateFile";

import OperationDashboard from "./components/CustomPermissions/customPerms";
import AllannouncementsForUser from "./components/CustomPermissions/Pages/AllAnnouncementsforUsers";
import AllMinutesOfMeetingForUser from "./components/CustomPermissions/Pages/MOMForUser";
import MarketingFilesPageForUsers from "./components/CustomPermissions/Pages/MarketingFileListForUsersForUsers";
import ClosingReportPageForUser from "./components/CustomPermissions/Pages/ClosingReportForUsers";
import PartnerProgramTableForUsers from "./components/CustomPermissions/Pages/PrivateFileForUsers";
import LatestNewsPage from "./components/CustomPermissions/Pages/CustomNews";
import DocladderMarketingFilesPage from './components/DocladderMarketingFiles/DocladderMarketingFilesPage'
import DocladderMarketingFilesPageForUser from "./components/CustomPermissions/Pages/DocladderMarketingFilesForUser";
import DealsPage from "./components/Deals/dealpage";
import DocladderMarketingFilesPageForUserNoEdit from "./components/CustomPermissions/vandana/marketing";
import LeaderOldRecords from "./components/TeamLeader/usersOldRecord";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="records" element={<Records />} />
        <Route path="viewers" element={<Viewers />} />
        <Route path="UserViews/" element={<UserView />} />
        <Route path="create" element={<CreateRecord />} />
        <Route path="update" element={<UpdateRecord />} />
        <Route path="slider" element={<RecordSlider />} />
        <Route path="navbar" element={<Navbar />} />
        <Route path="/welcomepage" element={<WelcomeUser />} />
        <Route path="news" element={<Allannouncements />} />
        <Route path="business" element={<BusinessHead />} />
        <Route path="centralmanager" element={<CentralManager />} />
        <Route path="announce" element={<Announcements />} />
        <Route path="createannouncements" element={<CreateAnnouncements />} />
        <Route path="sub_admin_view" element={<SubAdminPage />} />
        <Route path="pagenotfound" element={<PageNotFound/>} />

        {/* ************** TeamLeader Value ************** */}
        <Route path="createteamleader" element={<CreateTeamLeader />} />
        <Route path="showteamleader" element={<ShowTeamLeader />} />
        <Route path="showteamleadertype" element={<ShowTeamLeaderType />} />
        <Route path="createteamleadertarget" element={<CreateTeamLeaderTarget />} />
        <Route path="teamleaderview" element={<UserWiseTeamLeader />} />

        {/* *********** Celebration Page ************ */}
        <Route path="celebrationPage" element={<CelebrationPage />} />

        {/* *********** LeaderBoard Page ************ */}
        <Route path="leadersTable" element={<LeadersTable />} />
        <Route path="/leadersTask/:leaderId" element={<LeaderTasks />} />
        <Route path="tlslider" element={<TLSlider />} />
        <Route path="/viewteamlead" element={<ViewTeamLead/>}/>

        <Route path="/currentmonthTaskForm/:leaderId" element={<CurrentMonthTaskForm />} />
        <Route path="/training" element={<Training />} />


        {/* ****************  Minutes of Meeting ************************* */}
        <Route path="/momlist" element={<AllMinutesOfMeeting />} />
        <Route path="/createmom" element={<CreateMoMs />} />


        {/* ****************  Docladder ************************* */}
        <Route path="/createdocladdermember" element={<CreateTeamMember />} />
        <Route path="/showdocladdermember" element={<ShowDocladderMember />} />
        <Route path="/membersTask/:memberId" element={<MemberTasks />} />
        <Route path="/teamdocladderview" element={<UserWiseTeammember />} />        
        <Route path="/currentmonthdocladderTaskForm/:leaderId" element={<CurrentMonthDocladderTaskForm />} />
        <Route path="/docladdertableslider" element={<DocladderSlider />} />

        <Route path="/user-comparison" element={<UserRecordComparison />} />
        <Route path="/marketingFilePage" element={<MarketingFilesPage />} />
        <Route path="/teamrecord" element={<TeamRecords />} />
        <Route path="/docladderslider" element={<DocladderRecordSlider />} />

        {/* ****************  Partners Program ************************* */}
        <Route path="/fileType" element={<AddFileType />} />
        <Route path="/partnersFiles" element={<PartnersFiles />} />
        <Route path="/closingReport" element={<ClosingReportPage />} />

        {/* ****************  SOP Model ************************* */}
        <Route path="/soppage" element={<SopModelPage />} />
        <Route path="/designationpage" element={<DesignationPage />} />

        <Route path="/oldrecordentry" element={<UserOldRecordUpdateView />} />
        <Route path="/privateFiles" element={<PrivateFileList />} />
        <Route path="/CreatePrivateFilesPage" element={<CreatePrivateFilesPage />} />
        <Route path="/OperationDashboard" element={<OperationDashboard />} />

        <Route path="/AllannouncementsForUser" element={<AllannouncementsForUser />} />
        <Route path="/AllMinutesOfMeetingForUser" element={<AllMinutesOfMeetingForUser />} />
        <Route path="/closingReportForUser" element={<ClosingReportPageForUser />} />
        <Route path="/MarketingFilesPageForUsers" element={<MarketingFilesPageForUsers />} />
        <Route path="/CreatePrivateFilesPageForUsers" element={<PartnerProgramTableForUsers />} />
        <Route path="/latestnewspage" element={<LatestNewsPage />} />
        <Route path="/DocladderMarketingFilesPage" element={<DocladderMarketingFilesPage />} />
        <Route path="/DocladderMarketingFilesPageForUsers" element={<DocladderMarketingFilesPageForUser />} />
        <Route path="/DocladderMarketingFilesPageForUserNoEdit" element={<DocladderMarketingFilesPageForUserNoEdit />} />

        <Route path="/DealsPage" element={<DealsPage />} />
        <Route path="/leadersOldRecords/:id" element={<LeaderOldRecords />} />


      </Routes>
    </BrowserRouter>
  );
}

export default App;
