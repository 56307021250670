import React, { useState, useEffect } from "react";
import axios from "axios";
import FilterMarketingValue from "../../MarketingDateView";

const MarketingFilesPageForUsers = () => {
  const [files, setFiles] = useState([]);
  const [vacancies, setVacancies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sources, setSources] = useState([]);
  const [contactPersonTypes, setContactPersonTypes] = useState([]);
  const [contactPersonTypes2, setContactPersonTypes2] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [fileDetails, setFileDetails] = useState({
    name: "",
    city: "",
    state: "",
    cat: "",
    source: "",
    contact_person_type: "",
    poc_name: "",
    poc_number: "",
    date: "",
    percentage_closed: "",
    payment_in_days: "",
    scanned_file: null,
    active_status: true,
    contact_person2_type: "",
    contact_person_name2: "",
    contact_person_number2: "",
    comment: "",
    callback: "",
    callback_date: "",
  });
  const [editFileId, setEditFileId] = useState(null);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [userName, setUserName] = useState(null);

  useEffect(() => {
    fetchAllFiles();
  }, []);

  const fetchAllFiles = async () => {
    try {
      const response = await axios.get(
        "https://scoreboard.codingindia.co.in/Marketing/marketing-files/",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setFiles(response.data.data);
      setFilteredFiles(response.data.data);

      const userName = localStorage.getItem("user_name");
      if (userName) {
        console.log("Logged in as:", userName);
        // Use this to display on the page
        setUserName(userName);
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const handleDeleteFile = async (id) => {
    try {
      await axios.delete(
        `https://scoreboard.codingindia.co.in/Marketing/marketing-files/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      await fetchAllFiles(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleFormChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "scanned_file") {
      setFileDetails((prev) => ({ ...prev, scanned_file: files[0] }));
    } else if (name === "active_status") {
      setFileDetails((prev) => ({ ...prev, [name]: value === "true" }));
    } else {
      setFileDetails((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(fileDetails).forEach(([key, value]) => {
      if (value !== null) formData.append(key, value);
    });

    const url = editFileId
      ? `https://scoreboard.codingindia.co.in/Marketing/marketing-files/${editFileId}/`
      : "https://scoreboard.codingindia.co.in/Marketing/marketing-files/";
    const method = editFileId ? "put" : "post";

    try {
      await axios({
        method,
        url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      if (editFileId) {
        const updatedFiles = files.map((file) =>
          file.id === editFileId ? { ...file, ...fileDetails } : file
        );
        setFiles(updatedFiles);
        setFilteredFiles(updatedFiles);
      } else {
        fetchAllFiles();
      }

      setModalOpen(false);
      resetForm();
    } catch (error) {
      console.error("Error saving file:", error);
      alert("Failed to save the file!");
    }
  };

  const resetForm = () => {
    setFileDetails({
      name: "",
      city: "",
      state: "",
      cat: "",
      source: "",
      contact_person_type: "",
      poc_name: "",
      poc_number: "",
      date: "",
      percentage_closed: "",
      payment_in_days: "",
      scanned_file: null,
      active_status: true,
      contact_person2_type: "",
      contact_person_name2: "",
      contact_person_number2: "",
      comment: "",
      callback: "",
      callback_date: "",
    });
    setEditFileId(null);
  };

  const handleEditFile = (file) => {
    setFileDetails({
      ...file,
      cat:
        categories.find((category) => category.name === file.cat__name)?.id ||
        "",
      source:
        sources.find((source) => source.name === file.source__name)?.id || "",
      contact_person_type:
        contactPersonTypes.find(
          (type) => type.name === file.contact_person_type__name
        )?.id || "",
      contact_person2_type:
        contactPersonTypes.find(
          (type) => type.name === file.contact_person2_type__name
        )?.id || "",
      active_status: file.active_status,
    });
    setEditFileId(file.id);
    setModalOpen(true);
  };

  const handleFilteredResults = (data) => {
    setFilteredFiles(Array.isArray(data) ? data : []);
  };

  const toggleRowExpansion = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const onClose = () => {
    setModalOpen(false);
    setFileDetails({
      name: "",
      city: "",
      state: "",
      cat: "",
      source: "",
      contact_person_type: "",
      poc_name: "",
      poc_number: "",
      date: "",
      percentage_closed: "",
      payment_in_days: "",
      scanned_file: null,
      contact_person2_type: "",
      contact_person_name2: "",
      contact_person_number2: "",
      comment: "",
      callback: "",
      callback_date: "",
    });
    setEditFileId(null);
  };

  useEffect(() => {
    axios
      .get("https://scoreboard.codingindia.co.in/Marketing/vacancies/")
      .then((response) => setVacancies(response.data))
      .catch((error) =>
        console.error("There was an error fetching the vacancies!", error)
      );

    axios
      .get("https://scoreboard.codingindia.co.in/Marketing/categories/")
      .then((response) => setCategories(response.data))
      .catch((error) =>
        console.error("There was an error fetching the categories!", error)
      );

    axios
      .get("https://scoreboard.codingindia.co.in/Marketing/marketing-sources/")
      .then((response) => setSources(response.data))
      .catch((error) =>
        console.error("There was an error fetching the sources!", error)
      );

    axios
      .get(
        "https://scoreboard.codingindia.co.in/Marketing/contact-person-types/"
      )
      .then((response) => setContactPersonTypes(response.data))
      .catch((error) =>
        console.error(
          "There was an error fetching the contact person types!",
          error
        )
      );
  }, []);

  const totalClients = files.length;
  const activeClientsCount = files.filter((file) => file.active_status).length;
  const inactiveClientsCount = totalClients - activeClientsCount;

  return (
    <div className="font-sans">
      <div
        className="container mt-2"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h1 className="text-center text-3xl text-blue-800 mb-2">
          Marketing Files
        </h1>
       
      </div>

      {/* Display total, active, and inactive clients */}
      <div
        className="container mt-4 text-center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h2 className="text-xl font-bold text-gray-700">
          Total Clients: {totalClients}
        </h2>
        <h2 className="text-xl font-bold text-green-700">
          Active Clients: {activeClientsCount}
        </h2>
        <h2 className="text-xl font-bold text-red-700">
          Inactive Clients: {inactiveClientsCount}
        </h2>
      </div>

      <div className="px-5">
        <div className="mt-4 mb-5">
          <FilterMarketingValue onResults={handleFilteredResults} />
          <h2 className="text-center text-2xl text-blue-800 mb-2">
            Existing Files
          </h2>
          <table
            className="min-w-full bg-white border border-gray-300"
            style={{ width: "95%", margin: "auto" }}
          >
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Sr. No</th>
                <th className="py-2 px-4 border-b">Client Name</th>
                <th className="py-2 px-4 border-b">City</th>
                <th className="py-2 px-4 border-b">State</th>
                <th className="py-2 px-4 border-b">Category</th>
                <th className="py-2 px-4 border-b">Active Status</th>
                {/* <th className="py-2 px-4 border-b">Source</th> */}
                <th className="py-2 px-4 border-b">%</th>
                <th className="py-2 px-4 border-b">Payment Terms (Days)</th>
                <th className="py-2 px-4 border-b">Callback Date</th>
                {/* <th className="py-2 px-4 border-b">Actions</th> */}
              </tr>
            </thead>
            {userName === "Ruchi Bedi" ? (
              <>
                <tbody>
                  {filteredFiles.length > 0 ? (
                    filteredFiles.map((file, index) => (
                      <React.Fragment key={file.id}>
                        <tr
                          className="cursor-pointer bg-white hover:bg-gray-100 transition-colors"
                          onClick={() => toggleRowExpansion(index)}
                        >
                          <td className="py-2 px-4 border-b text-center">
                            {index + 1}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.name}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.city}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.state}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.cat__name}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.active_status ? "Active" : "Inactive"}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.percentage_closed}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.payment_in_days}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.callback_date}
                          </td>
                        </tr>
                        {expandedRow === index && (
                      <tr className="bg-gray-50">
                        <td colSpan="11" className="px-4 py-4">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p>
                              <strong>Contact Person Type:</strong>{" "}
                              {file.contact_person_type__name}
                            </p>
                            <p>
                              <strong>Name:</strong> {file.poc_name}
                            </p>
                            <p>
                              <strong>Number:</strong> {file.poc_number}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p>
                              <strong>Contact Person 2 Type:</strong>{" "}
                              {file.contact_person2_type__name}
                            </p>
                            <p>
                              <strong>Name:</strong> {file.contact_person_name2}
                            </p>
                            <p>
                              <strong>Number:</strong>{" "}
                              {file.contact_person_number2}
                            </p>
                          </div>
                          <p>
                              <strong>Callback:</strong>{" "}
                              {file.callback}
                            </p>
                          <p>
                            <strong>Comment:</strong> {file.comment}
                          </p>
                        </td>
                      </tr>
                    )}
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center py-4">
                        No records found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </> // Render something or leave empty
            ) : (
              <>
                <tbody>
                  {filteredFiles.length > 0 ? (
                    filteredFiles.map((file, index) => (
                      <React.Fragment key={file.id}>
                        <tr
                          className="cursor-pointer bg-white hover:bg-gray-100 transition-colors"
                       
                        >
                          <td className="py-2 px-4 border-b text-center">
                            {index + 1}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.name}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.city}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.state}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.cat__name}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.active_status ? "Active" : "Inactive"}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.percentage_closed}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.payment_in_days}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {file.callback_date}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center py-4">
                        No records found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </> // Render something else or leave empty
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default MarketingFilesPageForUsers;
