import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./LeadersTable.css";

import Navbar from "../../NavBar";
function LeadersTable() {
    const [leaders, setLeaders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchLeaders() {
            try {
                // const response = await fetch('https://scoreboard.codingindia.co.in/LeadersTable/leaders/');
                const response = await fetch('https://scoreboard.codingindia.co.in/LeadersTable/leaders/', {
                    method: 'GET',
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                  });
                if (!response.ok) {
                    throw new Error('Failed to fetch leaders');
                }
                const data = await response.json();
                console.log("Response: ", data)
                console.log("Leaders: ", data.leaders)

                setLeaders(data.leaders);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        }

        fetchLeaders();
    }, []);

    // console.log(leader.id)

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
      <>
    <Navbar/>

        <div className="container-fluid">
            <h2 className="text-center mt-2">Leaders</h2>
            <table className="leadersTable w-90 border-separate text-center m-auto">
                <thead>
                    <tr class="text-center">
                        <th>Id</th>
                        <th>Name</th>
                        <th>Department</th>
                        <th>Action</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {leaders.map(leader => (
                        <tr key={leader.id}>
                            <td>{leader.id}</td>

                            <td>{leader.name}</td>
                            <td>{leader.department}</td>
                            <td>
                                <Link to={`/leadersTask/${leader.id}`}>
                                    <button>View Tasks</button>
                                </Link>
                            </td>
                            <td style={{marginLeft: "15px"}}>
                                <Link to={`/currentmonthTaskForm/${leader.id}`}>
                                    <button>Add Tasks</button>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </>
    );
}

export default LeadersTable;
