import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
// import Navbar from "./NavBar";
import axios from "axios";
import Announcementslider from "./Announcementslider.jsx";
import CustomNavbar from "./CustomPermissions/CustomNavbar.jsx";

function Records() {
  const [data, setData] = useState(null);
  const [dailyEntryData, setDailyEntryData] = useState([]);
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [pipelineandturnedup, setPipelineandturnedup] = useState([]);
  const navigate = useNavigate();

  const handlePermissionNavigation = (userPermissions) => {
    console.log("User Permissions: ", userPermissions);
    if (userPermissions.includes("website.update_target")) {
      navigate("/sub_admin_view", {
        replace: true,
        state: { userPermissions: "update_target" },
      });
    } else if (userPermissions.includes("website.custom_viewer_perm")) {
      navigate("/viewers", {
        replace: true,
        state: { userPermissions: "custom_viewer_perm" },
      });
    } else if (
      userPermissions.includes("website.custom_user_view") &&
      userPermissions.includes("auth.center_manager_view")
    ) {
      navigate("/UserViews", {
        replace: true,
        state: { userPermissions: "custom_user_view and center_manager_view" },
      });
    } else if (
      userPermissions.includes("auth.Custom_team_leader_perm") &&
      userPermissions.includes("auth.leaderanddocladderteam") &&
      userPermissions.includes("auth.view_marketing")
    ) {
      navigate("/teamleaderview", {
        replace: true,
        state: {
          userPermissions: "teamleader and docladdermember and marketing",
        },
      });
    } else if (
      userPermissions.includes("auth.Custom_team_leader_perm") &&
      userPermissions.includes("auth.leaderanddocladderteam")
    ) {
      navigate("/teamleaderview", {
        replace: true,
        state: { userPermissions: "teamleader and docladdermember" },
      });
    } else if (
      userPermissions.includes("auth.Custom_team_leader_perm") &&
      userPermissions.includes("auth.view_marketing")
    ) {
      navigate("/teamleaderview", {
        replace: true,
        state: { userPermissions: "teamleader and marketing" },
      });
    } else if (userPermissions.includes("website.custom_user_view")) {
      navigate("/UserViews", {
        replace: true,
        state: { userPermissions: "custom_user_view" },
      });
    } else if (userPermissions.includes("auth.custom_operations")) {
      navigate("/OperationDashboard", {
        replace: true,
        state: { userPermissions: "custom_operation" },
      });
    } else if (userPermissions.includes("auth.hr_permissions")) {
      navigate("/hrview", {
        replace: true,
        state: { userPermissions: "hr_permission" },
      });
    } else if (userPermissions.includes("auth.operations")) {
      navigate("/operations", {
        replace: true,
        state: { userPermissions: "operations" },
      });
    } else if (userPermissions.includes("auth.custom_marketting_view")) {
      navigate("/markettingview", {
        replace: true,
        state: { userPermissions: "custom_marketting" },
      });
    } else if (userPermissions.includes("auth.Custom_team_docladder_perm")) {
      navigate("/teamdocladderview", {
        replace: true,
        state: { userPermissions: "Custom_team_docladder_perm" },
      });
    } else if (userPermissions.includes("auth.Custom_team_leader_perm")) {
      navigate("/teamleaderview", {
        replace: true,
        state: { userPermissions: "Custom_team_leader_perm" },
      });
    } else {
      navigate("/pagenotfound", {
        replace: true,
        state: { userPermissions: "notassigned" },
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken) {
          const response = await axios.get(
            "https://scoreboard.codingindia.co.in/GetAllRecord/",
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          const {
            records,
            monthly_records,
            is_superuser,
            user_permissions,
            pipelineandturnedup,
          } = response.data;

          setData(records || []);
          setDailyEntryData(monthly_records || []);
          setIsSuperuser(is_superuser);
          setPipelineandturnedup(pipelineandturnedup || []);

          if (is_superuser) {
            // Superuser logic
            localStorage.setItem("is_superuser", is_superuser);
          } else if (user_permissions) {
            localStorage.setItem("is_superuser", false);
            localStorage.setItem(
              "custom_permissions",
              JSON.stringify(user_permissions)
            );
            handlePermissionNavigation(user_permissions);
          }
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchData();
  }, [navigate]);

  const sortedData = useMemo(() => {
    if (!dailyEntryData || !pipelineandturnedup) return [];

    return dailyEntryData
      .map((entry) => {
        const pipeline =
          pipelineandturnedup.find((item) => item.user === entry.user)
            ?.pipline || 0;
        const turnedUpRatio =
          entry.turned_up && pipeline
            ? ((entry.selected + entry.turned_up) / entry.resume_forwarded) *
              100
            : 0;

        console.log("Turned Up ratio: ", turnedUpRatio);

        return {
          ...entry,
          pipeline,
          turnedUpRatio,
        };
      })
      .sort((a, b) => {
        // Sort by revenue
        if (b.revenue !== a.revenue) return b.revenue - a.revenue;
        // Sort by selected candidates
        if (b.selected !== a.selected) return b.selected - a.selected;
        // Sort by turned-up ratio
        if (b.turnedUpRatio !== a.turnedUpRatio)
          return b.turnedUpRatio - a.turnedUpRatio;
        // Sort by resumes forwarded
        if (b.resume_forwarded !== a.resume_forwarded)
          return b.resume_forwarded - a.resume_forwarded;
        // Sort by pipeline
        return b.pipeline - a.pipeline;
      });
  }, [dailyEntryData, pipelineandturnedup]);

  // Calculate the totals for each column
  const totalForwarded = sortedData.reduce(
    (acc, curr) => acc + curr.resume_forwarded,
    0
  );
  const totalPipeline = sortedData.reduce(
    (acc, curr) => acc + curr.pipeline,
    0
  );
  const totalTurnedUp = sortedData.reduce(
    (acc, curr) => acc + curr.turned_up,
    0
  );
  const totalSelected = sortedData.reduce(
    (acc, curr) => acc + curr.selected,
    0
  );
  const totalRevenue = sortedData.reduce(
    (acc, curr) => acc + (isNaN(curr.revenue) ? 0 : parseFloat(curr.revenue)),
    0
  );
  const totalTargetRevenue = sortedData.reduce((acc, curr) => {
    const correspondingRecord = data.find((record) => record.id === curr.user);
    return acc + (correspondingRecord?.revenue || 0);
  }, 0);

  return (
    <>
      {/* <Navbar /> */}
      <section style={{ maxHeight: "100vh" }}>
        <div style={{ display: "flex" }}>
          {isSuperuser && (
            <>
              <CustomNavbar />

              <div
                style={{
                  height: "100vh",
                  overflowY: "scroll",
                  border: "1px solid #ddd",
                  // padding: "10px",
                  padding: '0 10px 10px 10px'
                }}
              >
                <h2
                  style={{
                    marginLeft: "30px",
                    color: "var(--bs-blue)",
                    marginTop: "10px",
                  }}
                >
                  HR Associate's Records
                </h2>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    backgroundColor: "white",
                  }}
                >
                  <thead>
                    <tr style={{ background: "var(--bg-blue)", position: 'sticky', top: "0", padding: 0, marginTop: 0 }}>
                      <th className="py-2 px-4 border-b">Image</th>
                      <th className="py-2 px-4 border-b">Name</th>
                      <th className="py-2 px-4 border-b">Forwarded</th>
                      <th className="py-2 px-4 border-b">Pipeline</th>
                      <th className="py-2 px-4 border-b">Turned Up</th>
                      <th className="py-2 px-4 border-b">Selected Candidate</th>
                      <th className="py-2 px-4 border-b">Revenue</th>
                      <th className="py-2 px-4 border-b">Turned Up Ratio</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-300">
                    {sortedData.map((dailyEntry) => {
                      const correspondingRecord = data.find(
                        (record) => record.id === dailyEntry.user
                      );
                      const imageSrc = correspondingRecord
                        ? `https://scoreboard.codingindia.co.in${correspondingRecord.image}`
                        : "";
                      const userName = correspondingRecord
                        ? correspondingRecord.name
                        : "";
                      const targetRev = correspondingRecord
                        ? correspondingRecord.revenue
                        : "";

                      const pipeline =
                        pipelineandturnedup.find(
                          (entry) => entry.user === dailyEntry.user
                        )?.pipline || "";

                      return (
                        <tr key={dailyEntry.user}>
                          <td className="py-2 px-4 border-b text-center">
                            {imageSrc && (
                              <img
                                src={imageSrc}
                                className="w-auto"
                                style={{ height: "100px" }}
                                alt=""
                              />
                            )}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {userName}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {dailyEntry.resume_forwarded}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {pipeline === ""
                              ? "0"
                              : pipeline !== undefined
                              ? pipeline
                              : 0}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {dailyEntry.turned_up}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {dailyEntry.selected}
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {isNaN(dailyEntry.revenue)
                              ? 0
                              : parseFloat(dailyEntry.revenue)}{" "}
                            / {targetRev} lac
                          </td>
                          <td className="py-2 px-4 border-b text-center">
                            {dailyEntry.resume_forwarded !== 0
                              ? (
                                  ((dailyEntry.turned_up +
                                    dailyEntry.selected) /
                                    dailyEntry.resume_forwarded) *
                                  100
                                ).toFixed(2)
                              : "0.00"}
                            %
                          </td>
                        </tr>
                      );
                    })}

                    {/* Totals Row */}
                    <tr>
                      <td className="py-2 px-4 border-b text-center font-bold">
                        Total
                      </td>
                      <td className="py-2 px-4 border-b"></td>
                      <td className="py-2 px-4 border-b text-center font-bold">
                        {totalForwarded}
                      </td>
                      <td className="py-2 px-4 border-b text-center font-bold">
                        {totalPipeline}
                      </td>
                      <td className="py-2 px-4 border-b text-center font-bold">
                        {totalTurnedUp}
                      </td>
                      <td className="py-2 px-4 border-b text-center font-bold">
                        {totalSelected}
                      </td>
                      <td className="py-2 px-4 border-b text-center font-bold">
                        {totalRevenue.toFixed(2)} /{" "}
                        {totalTargetRevenue.toFixed(2)} lac
                      </td>
                      <td className="py-2 px-4 border-b"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </section>
      {/* <Announcementslider /> */}
    </>
  );
}

export default Records;
