import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Navbar from "../NavBar";



function ShowTeamLeaderType({ prop }) {
    const location = useLocation();
    const stateValue = location.state && location.state['newUser'];

    console.log("State Value", stateValue)

    const [data, setData] = useState(null);
    const [userPermissions, setUserPermissions] = useState([]);

    const [isAuth, setIsAuth] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isSuperuser, setIsSuperuser] = useState(false); // State to store superuser status
    const navigate = useNavigate();


    useEffect(() => {
        if (localStorage.getItem("access_token") !== null) {
            setIsAuth(true); // Set authentication status to true if access token is present
            fetch("https://scoreboard.codingindia.co.in/teamleader-types/", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    setData(data || []); // Update state with fetched data
                    setIsSuperuser(data.is_superuser);
                    setUserPermissions(data.user_permissions || []);

                    console.log("User Permission: ", data.user_permissions);

                })
                .catch((error) => {
                    console.error("There was a problem with the fetch operation:", error);
                });
        }
    }, []);

    const openModal = (record) => {
        setSelectedRecord(record);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedRecord(null);
    };


    const handleUpdate = (e) => {
        e.preventDefault();

        if (selectedRecord) {
            const formData = new FormData();
            formData.append("id", selectedRecord.id);
            formData.append("name", document.getElementById("name").value);

            formData.append("collection", document.getElementById("collection").value);
            formData.append("confirmation", document.getElementById("confirmation").value);
            formData.append("closure", document.getElementById("closure").value);

            // Adjust other fields based on your Marketting model


            fetch(`https://scoreboard.codingindia.co.in/UpdateTeamLeaderType/
            `, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                body: formData,
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log("Updated record:", data);
                    closeModal();
                    // Fetch updated records or update state as needed

                    fetch("https://scoreboard.codingindia.co.in/teamleader-types/", {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Network response was not ok");
                            }
                            return response.json();
                        })
                        .then((data) => {
                            setData(data || []); // Update state with fetched data
                            setIsSuperuser(data.is_superuser);
                            setUserPermissions(data.user_permissions || []);

                            console.log("User Permission: ", data.user_permissions);

                        })
                        .catch((error) => {
                            console.error("There was a problem with the fetch operation:", error);
                        });
                })
                .catch((error) => {
                    console.error("There was a problem with the fetch operation:", error);
                });
        }
    };

    console.log("Data: ", data)

    const handleDelete = (id, e) => {
        e.preventDefault();
        const isConfirmed = window.confirm(
            "Are you sure you want to delete this record?"
        );

        if (isConfirmed) {
            fetch(`https://scoreboard.codingindia.co.in/DelRecord/${id}/`, {
                method: "DELETE",
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    console.log("Deleted Successfully");
                })
                .then((data) => {
                    console.log("Deleted record:", data);
                    closeModal();

                    fetch("https://scoreboard.codingindia.co.in/teamleader-types/", {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Network response was not ok");
                            }
                            return response.json();
                        })
                        .then((data) => {
                            setData(data.records || []);
                            setIsSuperuser(data.is_superuser);
                        })
                        .catch((error) => {
                            console.error(
                                "There was a problem with the fetch operation:",
                                error
                            );
                        });
                })
                .catch((error) => {
                    console.error("There was a problem with the fetch operation:", error);
                });
        }
    };

    // Announcements
    const [announcements, setAnnouncements] = useState([]);

    useEffect(() => {
        const fetchAnnouncements = async () => {
            try {
                const response = await axios.get(
                    "https://scoreboard.codingindia.co.in/ShowAnnouncements/"
                );
                setAnnouncements(response.data);
            } catch (error) {
                console.error("Error fetching announcements:", error);
            }
        };

        fetchAnnouncements();
    }, []);
    // End Announcements Page

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <></>, // Hide the previous arrow
        nextArrow: <></>, // Hide the next arrow
    };

    return (
        <>

        <Navbar/>
            <section>
                <div>
                    <div>
                        <div>
                            <div>

                                {/****************************** Viewer Code (Someone) ******************************/}

                                {!isSuperuser &&
                                    !userPermissions.includes("website.see_records") &&
                                    !userPermissions.includes("website.update_target") && (
                                        <table class="myTable min-w-full bg-white" style={{ width: "95%", margin: "auto" }}>
                                            <thead>
                                                <tr>
                                                    {/* <th scope="col">Rank</th>  */}

                                                    {/* <th scope="col" class="nameWidth">
                                                        Image
                                                    </th> */}
                                                    <th scope="col" class="nameWidth">
                                                        Name
                                                    </th>
                                                    <th scope="col" class="resumeWidth">
                                                        collection
                                                    </th>
                                                    <th scope="col" class="pipWidth">
                                                        confirmation
                                                    </th>
                                                    <th scope="col" class="turWidth">
                                                        closure
                                                    </th>

                                                    <th className="col">Options</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {data &&
                                                    data.map((record) => (
                                                        <tr key={record.id}>
                                                            {/* <td className="py-2 px-4 border-b">
                                                                <img src={`https://scoreboard.codingindia.co.in${record.image}/`} className="w-auto" style={{ height: "100px" }} alt="" />
                                                            </td> */}
                                                            <td className="py-2 px-4 border-b text-center">{record.name}</td>
                                                            <td className="py-2 px-4 border-b text-center">{record.collection}</td>
                                                            <td className="py-2 px-4 border-b text-center">{record.confirmation}</td>
                                                            <td className="py-2 px-4 border-b text-center">{record.closure}</td>

                                                            <td className="py-2 px-4 border-b text-center">
                                                                <button className="btn btn-primary" onClick={() => openModal(record)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>

                                                    ))}

                                            </tbody>
                                            <Link to="/createteamleadertarget">
                    <button className="fixed bottom-5 left-8 bg-blue-700 text-white p-4 rounded-full">
                        +
                    </button>
                </Link>
                                        </table>
                                    )}


                            </div>
                        </div>
                    </div>
                </div>

                <div className="recentContainer">
                    <div className="row recentNews">
                        <div className="col-3">
                            <h4>Recent Event</h4>
                        </div>
                        <div className="col-9">
                            <Slider {...settings}>
                                {announcements.map((announcement) => (
                                    <div key={announcement.id}>
                                        <span style={{ fontSize: "1.2rem" }}>
                                            {announcement.name}
                                        </span>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
            {showModal && (
                <div className="modal" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Team Leader Record</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                {selectedRecord && (
                                    <form onSubmit={handleUpdate}>
                                        <input type="hidden" className="form-control" id="recordId" value={selectedRecord.id} />

                                        <div className="mb-3">
                                            <label htmlFor="recordName" className="form-label">
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                value={selectedRecord.name}
                                                readOnly
                                            />
                                        </div>
                                       
                                        <div className="mb-3">
                                            <label htmlFor="collection" className="form-label">
                                                Collection
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="collection"
                                                placeholder={selectedRecord.collection}
                                                onChange={(e) =>
                                                    setSelectedRecord({
                                                        ...selectedRecord,
                                                        collection: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>


                                        <div className="mb-3">
                                            <label htmlFor="confirmation" className="form-label">
                                                Confirmation
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="confirmation"
                                                placeholder={selectedRecord.confirmation}
                                                onChange={(e) =>
                                                    setSelectedRecord({
                                                        ...selectedRecord,
                                                        confirmation: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="closure" className="form-label">
                                                Closure
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="closure"
                                                placeholder={selectedRecord.closure}
                                                onChange={(e) =>
                                                    setSelectedRecord({
                                                        ...selectedRecord,
                                                        closure: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                       

                                        {/* Repeat similar blocks for other fields */}

                                        <div className="btncontainer">
                                            <button type="submit" className="btn btn-primary m-2">
                                                Save Changes
                                            </button>
                                            {/* <button
                                                className="btn btn-danger m-2"
                                                onClick={(e) => handleDelete(selectedRecord.id, e)}
                                            >
                                                Delete
                                            </button> */}
                                            <button type="button" className="btn btn-secondary m-2" onClick={closeModal}>
                                                Close
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
export default ShowTeamLeaderType;
