import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../NavBar";

const DesignationPage = () => {
  const [designations, setDesignations] = useState([]);
  const [newDesignation, setNewDesignation] = useState("");
  const [editingDesignation, setEditingDesignation] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const API_BASE_URL = "https://scoreboard.codingindia.co.in"; // Replace with your backend base URL

  useEffect(() => {
    fetchDesignations();
  }, []);

  const fetchDesignations = async () => {
    try {
      // const response = await axios.get(`${API_BASE_URL}/designations/`);
      const response = await axios.get(`${API_BASE_URL}/designations/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setDesignations(response.data);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingDesignation) {
        await axios.put(
          `${API_BASE_URL}/designations/update/${editingDesignation.id}/`,
          { name: newDesignation },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
      } else {
        await axios.post(
          `${API_BASE_URL}/designations/create/`,
          { name: newDesignation },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
      }
      fetchDesignations();
      closeModal();
    } catch (error) {
      console.error("Error saving designation:", error);
    }
  };

  const deleteDesignation = async (id) => {
    try {
      // await axios.delete(`${API_BASE_URL}/designations/delete/${id}/`);
      await axios.delete(`${API_BASE_URL}/designations/delete/${id}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      fetchDesignations();
    } catch (error) {
      console.error("Error deleting designation:", error);
    }
  };

  const openModal = (designation = null) => {
    if (designation) {
      setEditingDesignation(designation);
      setNewDesignation(designation.name);
    } else {
      setEditingDesignation(null);
      setNewDesignation("");
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditingDesignation(null);
    setNewDesignation("");
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid mt-5">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 className="mb-4 text-center">Designation Management</h3>

        {/* Add New Designation Button */}
        <button className="btn btn-primary mb-4" onClick={() => openModal()}>
          Add New Designation
        </button>
        
        </div>

        {/* Designation List */}
        <table className="table table-bordered table-striped">
          <thead className="table-dark">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Department</th>

              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {designations.map((designation) => (
              <tr key={designation.id}>
                <td>{designation.id}</td>
                <td>{designation.name}</td>
                <td>{designation.dept__name}</td>

                <td>
                  <button
                    className="btn btn-warning me-2"
                    onClick={() => openModal(designation)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteDesignation(designation.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Modal for Create/Update */}
        {showModal && (
          <div
            className="modal show d-block fade"
            tabIndex="-1"
            role="dialog"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header bg-primary text-white">
                  <h5 className="modal-title text-white">
                    {editingDesignation
                      ? "Update Designation"
                      : "Create New Designation"}
                  </h5>
                  <button
                    type="button"
                    className="btn-close btn-close-white"
                    onClick={closeModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label className="form-label fw-bold">
                        Designation Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={newDesignation}
                        onChange={(e) => setNewDesignation(e.target.value)}
                        className="form-control"
                        placeholder="Enter Designation Name"
                        required
                      />
                    </div>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-secondary me-2"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DesignationPage;
