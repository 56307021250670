import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../NavBar";
import { Link } from "react-router-dom";

const PartnerProgramTable = () => {
  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc"); // Ascending by default
  const [filters, setFilters] = useState({
    filetype: "",
    company: "",
    name: "",
    desc: "",
    for_date: "",
    valid_date: "",
    rate: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [fileDetails, setFileDetails] = useState({
    filetype: null,
    company: null,
    name: "",
    desc: "",
    docs: null,
    for_date: "",
    valid_date: "",
    rate: "",
  });
  const [editFileId, setEditFileId] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedRow((prev) => (prev === index ? null : index));
  };
  useEffect(() => {
    fetchAllFiles();
    fetchFileTypes();
    fetchCompanies();
  }, []);

  const fetchAllFiles = async () => {
    try {
      const response = await axios.get(
        "https://scoreboard.codingindia.co.in/PartnerFiles/partners-files/",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      // Sort files by company name by default
      const sortedFiles = response.data.sort((a, b) => {
        const companyA = a.company__name?.toLowerCase() || "";
        const companyB = b.company__name?.toLowerCase() || "";

        if (companyA < companyB) return -1;
        if (companyA > companyB) return 1;

        // If company names are the same, sort by file name
        const nameA = a.name?.toLowerCase() || "";
        const nameB = b.name?.toLowerCase() || "";

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;

        return 0;
      });

      setFiles(sortedFiles);
      setFilteredFiles(sortedFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const fetchFileTypes = async () => {
    try {
      const response = await axios.get(
        "https://scoreboard.codingindia.co.in/PartnerFiles/filetypes/",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Include token if required
          },
        }
      );
      setFileTypes(response.data);
    } catch (error) {
      console.error("Error fetching file types:", error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(
        "https://scoreboard.codingindia.co.in/PartnerFiles/company/",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Replace with your token retrieval logic
          },
        }
      );
      setCompanies(response.data.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleDeleteFile = async (id) => {
    try {
      await axios.delete(
        `https://scoreboard.codingindia.co.in/PartnerFiles/partners-files/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      fetchAllFiles();
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const applyFilters = () => {
    const filtered = files.filter((file) => {
      // Ensure for_date is a valid date
      const forDate =
        file.for_date && Date.parse(file.for_date)
          ? new Date(file.for_date)
          : null;
      const fileMonth = forDate ? forDate.getMonth() + 1 : null;
      const fileYear = forDate ? forDate.getFullYear() : null;

      return (
        (!filters.filetype || file.filetype === parseInt(filters.filetype)) &&
        (!filters.company || file.company === parseInt(filters.company)) &&
        (!filters.name ||
          file.name.toLowerCase().includes(filters.name.toLowerCase())) &&
        (!filters.desc ||
          file.desc?.toLowerCase().includes(filters.desc.toLowerCase())) &&
        (!filters.for_date || file.for_date === filters.for_date) &&
        (!filters.valid_date || file.valid_date === filters.valid_date) &&
        (!filters.rate || file.rate.includes(filters.rate)) &&
        (!filters.month || fileMonth === parseInt(filters.month)) &&
        (!filters.year || fileYear === parseInt(filters.year))
      );
    });
    setFilteredFiles(filtered);
  };

  const resetFilters = () => {
    setFilters({
      filetype: "",
      company: "",
      name: "",
      desc: "",
      for_date: "",
      valid_date: "",
      rate: "",
      month: "",
      year: "",
    });
    setFilteredFiles(files);
  };

  const handleFormChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "docs") {
      setFileDetails((prev) => ({ ...prev, docs: files[0] }));
    } else {
      setFileDetails((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(fileDetails).forEach(([key, value]) => {
      if (value) formData.append(key, value);
    });

    const url = editFileId
      ? `https://scoreboard.codingindia.co.in/PartnerFiles/partners-files/${editFileId}/`
      : "https://scoreboard.codingindia.co.in/PartnerFiles/partners-files/";
    const method = editFileId ? "put" : "post";

    try {
      await axios({
        method,
        url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      fetchAllFiles();
      setModalOpen(false);
      resetForm();
    } catch (error) {
      console.error("Error saving file:", error);
    }
  };

  const resetForm = () => {
    setFileDetails({
      filetype: "",
      company: "",
      name: "",
      desc: "",
      docs: null,
      for_date: "",
      valid_date: "",
      rate: "",
    });
    setEditFileId(null);
  };

  const handleEdit = (file) => {
    setFileDetails({
      filetype: file.filetype ? String(file.filetype) : "",
      company: file.company ? String(file.company) : "",
      name: file.name,
      desc: file.desc || "",
      docs: null,
      for_date: file.for_date,
      valid_date: file.valid_date,
      rate: file.rate,
    });
    setEditFileId(file.id);
    setModalOpen(true);
  };

  const onClose = () => {
    setModalOpen(false);
    resetForm();
  };

  return (
    <div className="font-sans">
      <Navbar />
      <div className="container-fluid mt-2">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1 className="text-3xl text-blue-800 mb-4">
            Legal Files Agreements
          </h1>

          <div>
            <button
              className="bg-green-400 text-white px-4 py-2 rounded hover:bg-blue-900"
              onClick={() => setModalOpen(true)}
            >
              Add Legal File
            </button>
            <Link
              to="/fileType"
              className="bg-blue-800 text-white px-4 py-2 rounded hover:bg-blue-600"
              // state={{ userPermissions: "teamleader and docladdermember" }}
              style={{ color: "black", marginLeft: "20px" }}
            >
              Add File-Type
            </Link>
          </div>
        </div>

        {/* Filters */}
        <div className="mb-4 mt-4 grid grid-cols-3 gap-4">
          <select
            name="filetype"
            value={filters.filetype}
            onChange={handleFilterChange}
            className="border p-2 rounded"
          >
            <option value="">All File Types</option>
            {fileTypes.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>

          <select
            name="company"
            value={filters.company}
            onChange={handleFilterChange}
            className="border p-2 rounded"
          >
            <option value="">All Companies</option>
            {companies.map((company) => (
              <option key={company.id} value={company.id}>
                {company.name}
              </option>
            ))}
          </select>

          <input
            type="text"
            name="name"
            value={filters.name}
            onChange={handleFilterChange}
            placeholder="Search by Name"
            className="border p-2 rounded"
          />

          <input
            type="text"
            name="desc"
            value={filters.desc}
            onChange={handleFilterChange}
            placeholder="Search by Description"
            className="border p-2 rounded"
          />

          <input
            type="number"
            name="year"
            value={filters.year}
            onChange={handleFilterChange}
            placeholder="Search by Year"
            className="border p-2 rounded"
          />
        </div>
        <div className="flex justify-end mb-4">
          <button
            onClick={applyFilters}
            className="bg-blue-800 text-white px-4 py-2 rounded"
          >
            Apply Filters
          </button>
          <button
            onClick={resetFilters}
            className="bg-gray-600 text-white px-4 py-2 rounded ml-2"
          >
            Reset Filters
          </button>
        </div>

        {/* Table */}
        <table className="w-100 bg-white">
          <thead className="bg-[#012E47] text-white">
            <tr>
              <th className="py-2 px-4 text-left">Sr. No</th>
              <th className="py-2 px-4 text-left">Name</th>
              <th className="py-2 px-4 text-left">File Type</th>
              <th className="py-2 px-4 text-left">For Date</th>
              <th className="py-2 px-4 text-left">Valid Date</th>
              <th className="py-2 px-4 text-right">Rate</th>
              <th className="py-2 px-4 text-left">Description</th>
              <th className="py-2 px-4 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredFiles.map((file, index) => (
              <React.Fragment key={file.id}>
                {/* Main Row */}
                <tr className="border-b border-gray-200 hover:bg-gray-50">
                  <td className="py-2 px-4 text-left">{index + 1}</td>
                  <td className="py-2 px-4 text-left">
                    {file.name}
                    <br />
                    <span className="text-gray-500 text-sm">
                      Company: {file.company__name || "N/A"}
                    </span>
                  </td>
                  <td className="py-2 px-4 text-left">{file.filetype__name}</td>
                  <td className="py-2 px-4 text-left">{file.for_date}</td>
                  <td className="py-2 px-4 text-left">{file.valid_date}</td>
                  <td className="py-2 px-4 text-right">Rs. {file.rate}</td>
                  <td className="py-2 px-4 text-left">
                    <button
                      onClick={() => toggleAccordion(index)}
                      className="text-sm font-medium text-blue-600 hover:text-blue-800"
                    >
                      {expandedRow === index ? "Hide" : "View"} Description
                    </button>
                  </td>
                  <td className="py-2 px-4 text-center flex space-x-2 justify-center">
                    {/* View Button */}
                    <a
                      href={`https://scoreboard.codingindia.co.in/media/${file.docs}/`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800"
                      title="View File"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                      </svg>
                    </a>

                    {/* Edit Button */}
                    <button
                      onClick={() => handleEdit(file)}
                      className="text-gray-600 hover:text-gray-800"
                      title="Edit File"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pen-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001" />
                      </svg>
                    </button>

                    {/* Delete Button */}
                    <button
                      onClick={() => {
                        const confirmDelete = window.confirm(
                          "Are you sure you want to delete this file? This action cannot be undone."
                        );
                        if (confirmDelete) {
                          handleDeleteFile(file.id);
                        }
                      }}
                      className="text-red-600 hover:text-red-800"
                      title="Delete File"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                      </svg>
                    </button>
                  </td>
                </tr>

                {/* Accordion Row */}
                {expandedRow === index && (
                  <tr>
                    <td colSpan="8" className="p-4 bg-gray-50">
                      <strong>Description:</strong> {file.desc || "N/A"}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {modalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-60 flex justify-center items-center z-50">
          <div className="bg-white w-4/5 max-w-4xl rounded-lg shadow-lg">
            <form onSubmit={handleSubmit} className="p-6 space-y-4">
              {/* Grid Layout */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* File Type */}
                <div>
                  <label
                    htmlFor="filetype"
                    className="block text-sm font-medium text-gray-700"
                  >
                    File Type
                  </label>
                  <select
                    id="filetype"
                    name="filetype"
                    value={fileDetails.filetype}
                    onChange={handleFormChange}
                    required
                    className="w-full mt-1 p-2 border rounded"
                  >
                    <option value="">Select Type</option>
                    {fileTypes.map((type) => (
                      <option key={type.id} value={String(type.id)}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Company */}
                <div>
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company
                  </label>
                  <select
                    id="company"
                    name="company"
                    value={fileDetails.company}
                    onChange={handleFormChange}
                    required
                    className="w-full mt-1 p-2 border rounded"
                  >
                    <option value="">Select Company</option>
                    {companies.map((company) => (
                      <option key={company.id} value={String(company.id)}>
                        {company.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Name */}
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    File Name
                  </label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    value={fileDetails.name}
                    onChange={handleFormChange}
                    required
                    className="w-full mt-1 p-2 border rounded"
                  />
                </div>

                {/* Description */}
                <div>
                  <label
                    htmlFor="desc"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <textarea
                    id="desc"
                    name="desc"
                    value={fileDetails.desc}
                    onChange={handleFormChange}
                    className="w-full mt-1 p-2 border rounded"
                  />
                </div>

                {/* For Date */}
                <div>
                  <label
                    htmlFor="for_date"
                    className="block text-sm font-medium text-gray-700"
                  >
                    For Date
                  </label>
                  <input
                    id="for_date"
                    type="date"
                    name="for_date"
                    value={fileDetails.for_date}
                    onChange={handleFormChange}
                    className="w-full mt-1 p-2 border rounded"
                  />
                </div>

                {/* Valid Date */}
                <div>
                  <label
                    htmlFor="valid_date"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Valid Date
                  </label>
                  <input
                    id="valid_date"
                    type="date"
                    name="valid_date"
                    value={fileDetails.valid_date}
                    onChange={handleFormChange}
                    className="w-full mt-1 p-2 border rounded"
                  />
                </div>

                {/* Rate */}
                <div>
                  <label
                    htmlFor="rate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Rate
                  </label>
                  <input
                    id="rate"
                    type="text"
                    name="rate"
                    value={fileDetails.rate}
                    onChange={handleFormChange}
                    required
                    className="w-full mt-1 p-2 border rounded"
                  />
                </div>

                {/* Document */}
                <div>
                  <label
                    htmlFor="docs"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Upload Document
                  </label>
                  <input
                    id="docs"
                    type="file"
                    name="docs"
                    onChange={handleFormChange}
                    className="w-full mt-1 p-2 border rounded"
                  />
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-800 rounded"
                >
                  {editFileId ? "Update" : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerProgramTable;
