import React, { useState, useEffect } from "react";
import axios from "axios";

const LatestNewsPage = () => {
  const [newsList, setNewsList] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    dept: "",
    status: false,
    userid: "",
    expiry_date: "",
  });
  const [editingNewsId, setEditingNewsId] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const API_BASE_URL = "https://scoreboard.codingindia.co.in";

  // Fetch all LatestNews on component mount
  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/latest-news/`);
      setNewsList(response.data);
    } catch (error) {
      console.error("Error fetching news:", error);
      setErrorMessage("Failed to load news.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingNewsId) {
        // Update existing news
        await axios.put(
          `${API_BASE_URL}/latest-news/${editingNewsId}/`,
          formData
        );
        setSuccessMessage("News updated successfully!");
      } else {
        // Create new news
        await axios.post(`${API_BASE_URL}/latest-news/create/`, formData);
        setSuccessMessage("News created successfully!");
      }
      fetchNews();
      resetForm();
    } catch (error) {
      console.error("Error saving news:", error);
      setErrorMessage("Failed to save news.");
    }
  };

  const handleEdit = (news) => {
    setFormData({
      name: news.name,
      dept: news.dept || "",
      status: news.status,
      userid: news.userid || "",
      expiry_date: news.expiry_date || "",
    });
    setEditingNewsId(news.id);
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this news?")) return;

    try {
      await axios.delete(`${API_BASE_URL}/latest-news/${id}/`);
      setSuccessMessage("News deleted successfully!");
      fetchNews();
    } catch (error) {
      console.error("Error deleting news:", error);
      setErrorMessage("Failed to delete news.");
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      dept: "",
      status: false,
      userid: "",
      expiry_date: "",
    });
    setEditingNewsId(null);
    setSuccessMessage("");
    setErrorMessage("");
  };

  return (
    <div className="container">
      <h1 className="text-center mt-4">Latest News Management</h1>

      {successMessage && (
        <p className="alert alert-success">{successMessage}</p>
      )}
      {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}

      <form onSubmit={handleFormSubmit} className="mt-4">
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <select
            name="name"
            id="name"
            className="form-control"
            onChange={handleInputChange}
          >
            <option value="">--Select Any News--</option>

            <option value="Birthday Wishes">Birthday Wishes</option>
            <option value="Work Anniversary">Work Anniversary</option>
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="dept" className="form-label">
            Department
          </label>
          <select
            name="dept"
            id="dept"
            className="form-control"
            onChange={handleInputChange}
          >
            <option value="">--Select Any News--</option>

            <option value="HR Associate">Pace</option>
            <option value="Recuriter">Docladder</option>
            <option value="HR Associate">Recuriter</option>
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="userid" className="form-label">
            User ID
          </label>
          <input
            type="number"
            id="userid"
            name="userid"
            value={formData.userid}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="expiry_date" className="form-label">
            Expiry Date
          </label>
          <input
            type="date"
            id="expiry_date"
            name="expiry_date"
            value={formData.expiry_date}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>

        <div className="mb-3 form-check">
          <input
            type="checkbox"
            id="status"
            name="status"
            checked={formData.status}
            onChange={handleInputChange}
            className="form-check-input"
          />
          <label htmlFor="status" className="form-check-label">
            Status
          </label>
        </div>

        <button type="submit" className="btn btn-primary">
          {editingNewsId ? "Update News" : "Create News"}
        </button>
        <button
          type="button"
          onClick={resetForm}
          className="btn btn-secondary ms-2"
        >
          Reset
        </button>
      </form>

      <h2 className="mt-5">Latest News List</h2>
      <table className="table table-striped mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Department</th>
            <th>Status</th>
            <th>User ID</th>
            <th>Expiry Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {newsList.map((news) => (
            <tr key={news.id}>
              <td>{news.id}</td>
              <td>{news.name}</td>
              <td>{news.dept}</td>
              <td>{news.status ? "Active" : "Inactive"}</td>
              <td>{news.userid}</td>
              <td>{news.expiry_date}</td>
              <td>
                <button
                  className="btn btn-warning btn-sm me-2"
                  onClick={() => handleEdit(news)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => handleDelete(news.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LatestNewsPage;
