import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Sidebar from "./CustomNavbar";

const OperationDashboard = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const additionalData = location.state?.userPermissions || props.dataProp;

  const handleSignOut = () => {
    localStorage.removeItem("access_token");
    navigate("/");
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1">
        {/* Navbar */}
        <nav className="bg-blue-600 shadow-md">
          <div className="max-w-7xl mx-auto px-4 py-3 flex justify-between items-center">
            <h1 className="text-white text-2xl font-semibold">
              Operation Dashboard
            </h1>
            <div className="flex space-x-4">
              <Link
                to={{
                  pathname: "/AllannouncementsForUser",
                  state: { additionalData },
                }}
                className="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Announcements
              </Link>
              <a
                href="/AllMinutesOfMeetingForUser"
                className="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                MOM
              </a>
              <a
                href="/closingReportForUser"
                className="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Closing Report
              </a>
              <a
                href="/latestnewspage"
                className="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Latest News
              </a>
              <a
                href="/MarketingFilesPageForUsers"
                className="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Marketing
              </a>
              <a
                href="/DocladderMarketingFilesPageForUsers"
                className="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Docladder Marketing Files
              </a>
              <a
                href="/CreatePrivateFilesPageForUsers"
                className="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Legal
              </a>
              <Link
                className="nav-item dropdown block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                to={{ pathname: "/", state: { additionalData } }}
                onClick={handleSignOut}
              >
                Sign Out
              </Link>
            </div>
          </div>
        </nav>

        <div className="max-w-7xl mx-auto px-4 py-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Card 1 */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h2 className="text-lg font-semibold text-gray-700">Overview</h2>
              <p className="text-sm text-gray-500 mt-2">
                Monitor overall performance and track progress in real-time.
              </p>
            </div>

            {/* Card 2 */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h2 className="text-lg font-semibold text-gray-700">Tasks</h2>
              <p className="text-sm text-gray-500 mt-2">
                Manage and assign tasks effectively with detailed tracking.
              </p>
            </div>

            {/* Card 3 */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h2 className="text-lg font-semibold text-gray-700">Reports</h2>
              <p className="text-sm text-gray-500 mt-2">
                Generate detailed reports for insights and decision-making.
              </p>
            </div>
          </div>

          {/* Recent Activity Section */}
          <div className="mt-8">
            <h2 className="text-xl font-semibold text-gray-700">
              Recent Activity
            </h2>
            <div className="mt-4 bg-white shadow-md rounded-lg p-4">
              <ul className="space-y-3">
                <li className="flex justify-between items-center">
                  <span className="text-gray-600">Logged in</span>
                  <span className="text-sm text-gray-500">2 hours ago</span>
                </li>
                <li className="flex justify-between items-center">
                  <span className="text-gray-600">
                    Completed Task: Prepare Report
                  </span>
                  <span className="text-sm text-gray-500">1 day ago</span>
                </li>
                <li className="flex justify-between items-center">
                  <span className="text-gray-600">
                    Uploaded File: Sales Data
                  </span>
                  <span className="text-sm text-gray-500">3 days ago</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperationDashboard;
