import React from 'react'

const WelcomeUser = () => {
  return (
    <div>WelcomeUser</div>
  )
}

export default WelcomeUser


        {/* {dailyData && (
            <div className="row">
              <div className="calendar">
                {dailyData.map((dayData, index) => (
                  <div className="day-box" key={index}>
                    <h3>{index}</h3>
                    <p>{dayData.resume_forwarded}</p>
                  </div>
                ))}
              </div>
            </div>
          )} */}
          {/* <div className="row">
            <div className="calendar">
              <div className="day-box">
                <h3>03</h3>
                <p>11</p>
              </div>

              <div className="day-box">
                <h3>02</h3>
                <p>12</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>13</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>14</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>15</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>16</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>17</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>18</p>
              </div>
              <div className="day-box">
                <h3>15</h3>
                <p>19</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>20</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>21</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>22</p>
              </div>
              <div className="day-box">
                <h3>15</h3>
                <p>23</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>24</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>25</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>26</p>
              </div>
              <div className="day-box">
                <h3>15</h3>
                <p>27</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>28</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>29</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>30</p>
              </div>
              <div className="day-box">
                <h3>15</h3>
                <p>31</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>01</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>02</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>03</p>
              </div>
              <div className="day-box">
                <h3>15</h3>
                <p>04</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>05</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>06</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>07</p>
              </div>
              <div className="day-box">
                <h3>15</h3>
                <p>08</p>
              </div>

              <div className="day-box">
                <h3>15</h3>
                <p>09</p>
              </div>
              <div className="day-box">
                <h3>15</h3>
                <p>10</p>
              </div>
            </div> */}