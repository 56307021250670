import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Navbar from "../NavBar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// end for multi select mui

function UserWiseTeamLeader() {
  const location = useLocation();
  const [userPermissions, setUserPermissions] = useState([]);
  const stateValue = location.state && location.state["userPermissions"];

  const [data, setData] = useState(null);
  const [userid, setUserId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [sop, setSop] = useState([]);
  const [tasks, setTasks] = useState(null);
  const [targets, setTargets] = useState(null);
  const [inputValues, setInputValues] = useState({ value: "" });
  const [selectedtaskid, setSelectedTaskId] = useState({ value: "" });
  const [targetDays, setTargetDays] = useState("");
  const [dateassigned, setDateAssigned] = useState("");

  const [selectedTaskName, setSelectedTaskName] = useState("");
  const [ename, setEname] = useState("");
  const [dateJoined, setDateJoined] = useState("");
  const [dateHandovered, setDateHandovered] = useState("");
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [topic, setTopic] = useState([]);
  const [old_emp_training_topic, setold_emp_training_topic] = useState([]);

  const [tdate, setTdate] = useState([]);
  const [isNewSop, setIsNewSop] = useState(false);
  const [customPermissions, setCustomPerms] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleSignOut = () => {
    localStorage.removeItem("access_token");
    navigate("/");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        console.log(token);
        if (token) {
          const response = await axios.get(
            "https://scoreboard.codingindia.co.in/LeadersTable/GetLeaderDatafromUsername/",
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          );

          const allusers = await axios.get(
            "https://scoreboard.codingindia.co.in/get-all-users/",
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          );

          setUsers(allusers.data);
          setData(response.data.leader);
          setUserId(response.data.leader.id);
          setTasks(response.data.leader.currentVal);
          setTargets(response.data.leader.tasks);
          setEname(response.data.leader.ename); // Set ename
          setDateJoined(response.data.leader.date_joined); // Set date_joined
          setDateHandovered(response.data.leader.date_handovered); // Set date_handovered
          setSop(response.data.leader.sop);

          const userName = response.data.leader.name;
          localStorage.setItem("user_name", userName);

          const storedPermissions = JSON.parse(localStorage.getItem("custom_permissions")) || [];
          setCustomPerms(storedPermissions);

          if (
            Array.isArray(response.data.leader.sop) &&
            response.data.leader.sop.length > 0
          ) {
            // Access the first SOP object
            const sopData = response.data.leader.sop[0];
            console.log("SOP Data: ", sopData);

            if (sopData.updated_date) {
              try {
                // Extract only the date parts as strings in YYYY-MM-DD format
                const updatedDate = new Date(sopData.updated_date)
                  .toISOString()
                  .split("T")[0];
                const currentDate = new Date().toISOString().split("T")[0];

                // Calculate the difference in days
                const date1 = new Date(updatedDate);
                const date2 = new Date(currentDate);
                const diffInTime = date2 - date1; // Difference in milliseconds
                const diffInDays = diffInTime / (1000 * 3600 * 24);

                console.log("Difference in Days:", diffInDays);

                // Check if the difference is within 2 days
                if (diffInDays >= 0 && diffInDays <= 2) {
                  console.log("New SOP is updated!");
                  setIsNewSop(true);
                } else {
                  setIsNewSop(false);
                }
              } catch (error) {
                console.error("Error parsing dates:", error);
              }
            } else {
              console.log("No updated_date found in SOP data.");
              setIsNewSop(false);
            }
          } else {
            console.log("SOP array is empty or invalid.");
            setIsNewSop(false);
          }
        } else {
          throw new Error("Access token not found");
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchData();
  }, []);

  const openModal = (task) => {
    setSelectedTask(task);
    setSelectedTaskName(task.name); // Store the selected task name
    setIsModalOpen(true);
    if (task.name === "Induction") {
      setTargetDays("1");
    } else if (task.name === "Smooth handover Process") {
      setTargetDays("3");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTask(null); // Reset selected task
    setInputValues({ value: "" }); // Reset input values
    setDateAssigned({ dateassigned: "" }); // Reset input values
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let postData = {
        name: selectedTask.id,
        value: inputValues.value,
        targetdays: targetDays,
        dateassigned: dateassigned.dateassigned,
      };

      if (selectedTask.name === "Induction") {
        postData = {
          ...postData,
          ename,
          date_joined: dateJoined,
          date_handovered: dateHandovered,
        };
        // Post data to Induction endpoint
        const response = await axios.post(
          "https://scoreboard.codingindia.co.in/LeadersTable/CreateTargetsOfTasks/",
          postData,
          {
            headers: {
              "Content-Type": "application/json", // Ensure the content type is JSON
              Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Add authorization token
            },
          }
        );

        if (response.status === 200) {
          console.log("Induction task saved successfully");
          closeModal();
        } else {
          throw new Error("Failed to save Induction task");
        }
      } else if (selectedTask.name === "New Employee Training") {
        postData = {
          ...postData,
          topic,
          users: selectedUsers,
          task: selectedTask.id,

          tdate: tdate,
        };
        // Post data to New Employee Training endpoint
        const response = await axios.post(
          "https://scoreboard.codingindia.co.in/LeadersTable/CreateTargetsOfTasks/",
          postData,
          {
            headers: {
              "Content-Type": "application/json", // Ensure the content type is JSON
              Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Add authorization token
            },
          }
        );

        if (response.status === 200) {
          console.log("New Employee Training saved successfully");
          closeModal();
        } else {
          throw new Error("Failed to save New Employee Training");
        }
      } else if (selectedTask.name === "Old Emplyees Training") {
        postData = {
          ...postData,
          old_emp_training_topic,
          users: selectedUsers,
          oldtask: selectedTask.id,
          old_t_date: tdate,
        };
        // Post data to New Employee Training endpoint
        const response = await axios.post(
          "https://scoreboard.codingindia.co.in/LeadersTable/CreateTargetsOfTasks/",
          postData,
          {
            headers: {
              "Content-Type": "application/json", // Ensure the content type is JSON
              Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Add authorization token
            },
          }
        );

        if (response.status === 200) {
          console.log("New Employee Training saved successfully");
          closeModal();
        } else {
          throw new Error("Failed to save New Employee Training");
        }
      } else {
        const response = await axios.post(
          "https://scoreboard.codingindia.co.in/LeadersTable/CreateTargetsOfTasks/",
          postData,
          {
            headers: {
              "Content-Type": "application/json", // Ensure the content type is JSON
              Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Add authorization token
            },
          }
        );

        if (response.status === 200) {
          console.log("Task saved successfully");
          closeModal();
        } else {
          throw new Error("Failed to save task");
        }
      }
    } catch (error) {
      console.error("Error ", error);
    }
  };

  const handleUserSelect = (e) => {
    setSelectedUsers(
      Array.from(e.target.selectedOptions, (option) => option.value)
    );
  };

  useEffect(() => {
    setUserPermissions(stateValue);
  });

  const [showViewDescModal, setShowViewDescModal] = useState(false); // For Description modal
  const [currentDesc, setCurrentDesc] = useState(""); // Store current description

  const closeViewDescModal = () => {
    setShowViewDescModal(false); // Close the description modal
    setCurrentDesc(""); // Reset the description
  };

  const handleViewDescription = (desc) => {
    setCurrentDesc(desc); // Set the description to be displayed
    setShowViewDescModal(true); // Open the description modal
  };

  return (
    <>
      <section>
        <div className="container-fluid leaderViewContainer pt-2">
          {isNewSop && sop.length > 0 && (
            <div
              style={{
                width: "90%",
                margin: "auto auto",
                backgroundColor: "#e6ffe6", // Light green background
                color: "#2e7d32", // Dark green text
                border: "1px solid #2e7d32",
                borderRadius: "5px",
                padding: "10px 15px",
                fontSize: "16px",
                fontWeight: "bold",
                // marginTop: "10px",
              }}
            >
              ✅ New SOP has been updated! (Last updated on:{" "}
              {new Date(sop[0].updated_date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              )
            </div>
          )}
          {data && (
            <div className="leaderdetailcontainer">
              <div className="row">
                {/* <div className="col-3">
                                    <img className="w-auto align-horizontal" style={{ height: "100px" }} src={`https://scoreboard.codingindia.co.in${data.image}`} alt="" />
                                </div> */}
                <p
                  style={{
                    color: "white",
                    fontSize: "1.3rem",
                    fontWeight: "800",
                    textAlign: "center",
                  }}
                >
                  Team Leader Page
                </p>

                <div className="col-4">
                  <div className="userViewText">
                    <h3>{data.name}</h3>
                    <span>
                      {data.dept}{" "}
                      <Link
                        className="signOutLink"
                        to={{ pathname: "/" }}
                        onClick={handleSignOut}
                      >
                        <i class="fa-solid fa-right-from-bracket"></i>
                      </Link>
                    </span>
                  </div>
                </div>
                <div className="col-5">
                  <div className="userViewProgress">
                    <div className="userViewProgText"></div>
                    <div className="userViewProgessBar">
                      <p className="mx-1 my-1 text-center">
                        <div
                          className={`progress-bar userViewProgDiv ${
                            data.currentpercentage < 50
                              ? "userViewProgRed"
                              : data.currentpercentage < 80
                              ? "userViewProgYellow"
                              : "userViewProgGreen"
                          }`}
                          style={{ width: `${data.currentpercentage}%` }}
                        >
                          {data.currentpercentage === 50 ? (
                            <span style={{ marginLeft: "-15px" }}>
                              {data.currentpercentage}%
                            </span>
                          ) : (
                            data.currentpercentage + "%"
                          )}
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <p></p> */}
              {/* <a href="">Docladder Page</a> */}
              {/* teamleader and marketing */}
              <Link to={`/leadersOldRecords/${userid}`}>
                <button style={{ color: "white" }}>Old Month Records</button>
              </Link>
              <br />
              {customPermissions &&
                customPermissions.includes("auth.customComparison") && (
                  <>
                    <Link to={`/user-comparison/`}>
                      <button style={{ color: "white" }}>
                        Users Closing Monthly
                      </button>
                    </Link>
                  </>
                )}

              <br />
              {userPermissions &&
                (userPermissions === "teamleader and docladdermember" ? (
                  <Link
                    to="/teamdocladderview"
                    state={{
                      userPermissions: "teamleader and docladdermember",
                    }}
                    style={{ color: "white" }}
                  >
                    Switch To Docladder
                  </Link>
                ) : userPermissions ===
                  "teamleader and docladdermember and marketing" ? (
                  <>
                    <Link
                      to="/teamdocladderview"
                      state={{
                        userPermissions:
                          "teamleader and docladdermember and marketing",
                      }}
                      style={{ color: "white" }}
                    >
                      Switch To Docladder
                    </Link>
                    <br />
                    <Link
                      to="/DocladderMarketingFilesPageForUserNoEdit"
                      state={{
                        userPermissions:
                          "teamleader and docladdermember and marketing",
                      }}
                      style={{ color: "white" }}
                    >
                      Marketing File
                    </Link>
                  </>
                ) : userPermissions === "teamleader and marketing" ? (
                  <>
                    <Link
                      to="/DocladderMarketingFilesPageForUserNoEdit"
                      state={{
                        userPermissions:
                          "teamleader and docladdermember and marketing",
                      }}
                      style={{ color: "white" }}
                    >
                      Marketing File
                    </Link>
                  </>
                ) : (
                  <></>
                ))}
            </div>
          )}
          <table
            className="myTable min-w-full bg-white"
            style={{ width: "95%", margin: "auto" }}
          >
            <thead>
              <tr>
                <th scope="col">Task Name</th>
                <th scope="col" className="imageWidth">
                  Target
                </th>
                <th scope="col" className="nameWidth">
                  Current Value
                </th>
                <th scope="col" className="percentageWidth">
                  Point
                </th>
                <th scope="col" className="percentageWidth">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {targets ? (
                <>
                  {targets &&
                    targets.map((target, index) => (
                      <tr key={index}>
                        <td className="py-2 px-4 border-b text-center">
                          {target.name}
                        </td>
                        <td className="py-2 px-4 border-b text-center">
                          {target.target}
                        </td>
                        {tasks &&
                          tasks.map((task) => {
                            if (target.id === task.name) {
                              return (
                                <React.Fragment key={task.name}>
                                  <td className="py-2 px-4 border-b text-center">
                                    {task.total_taskinput}
                                  </td>
                                  <td className="py-2 px-4 border-b text-center">
                                    {task.total_points}
                                  </td>
                                </React.Fragment>
                              );
                            }
                            return null;
                          })}
                        <td className="py-2 px-4 border-b text-center">
                          <button
                            className="leaderTaskBtn"
                            onClick={() => openModal(target)}
                          >
                            <i className="fa-solid fa-check"></i> Complete
                          </button>
                        </td>
                      </tr>
                    ))}
                </>
              ) : (
                <tr>
                  <td colSpan="3">Loading...</td>
                </tr>
              )}
            </tbody>
          </table>

          {/* SOP Section */}
          <h4 className="mt-4 text-white">SOP Document</h4>
          <div className="table-responsive container-fluid">
            <table className="table table-bordered table-striped">
              <thead className="thead-dark">
                <tr>
                  <th>Sr No</th>
                  <th>Designation</th>
                  <th>Sop Name</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {sop.map((record, index) => (
                  <tr key={record.id}>
                    <td className="text-white">{index + 1}</td>
                    <td className="text-white">{record.designation__name}</td>
                    <td className="text-white">{record.descs__name}</td>

                    {/* <td>
                      <a
                        href={`https://scoreboard.codingindia.co.in/media/${record.docs}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-link"
                      >
                        View Document
                      </a>
                    </td> */}
                    <td>
                      <button
                        className="btn btn-link"
                        onClick={() =>
                          handleViewDescription(record.descs__desc)
                        }
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {showViewDescModal && (
            <div
              className="modal show d-block fade"
              tabIndex="-1"
              role="dialog"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <div className="modal-dialog modal-fullscreen modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header bg-primary text-white">
                    <h5 className="modal-title" style={{ color: "white" }}>
                      Description
                    </h5>
                    <button
                      type="button"
                      className="btn-close btn-close-white"
                      onClick={closeViewDescModal}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div
                      dangerouslySetInnerHTML={{ __html: currentDesc }}
                    ></div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={closeViewDescModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isModalOpen && (
            <div className="modal leaderTaskModel">
              <div className="modal-content">
                <h2>Complete Task</h2>
                <form onSubmit={handleSubmit}>
                  <label className="form-label">Task Name:</label>
                  <input
                    type="text"
                    value={selectedTask ? selectedTask.name : ""}
                    readOnly
                  />

                  <label>Value:</label>
                  <input
                    type="text"
                    value={inputValues.value}
                    onChange={(e) => setInputValues({ value: e.target.value })}
                  />

                  <label>Date:</label>
                  <input
                    type="date"
                    value={inputValues.dateassigned}
                    onChange={(e) =>
                      setDateAssigned({ dateassigned: e.target.value })
                    }
                  />

                  {selectedTask.name === "Induction" && (
                    <>
                      <label>Name:</label>
                      {/* <input type="text" value={ename} onChange={(e) => setInputValues({ ...inputValues, ename: e.target.value })} id='ename' /> */}
                      <input
                        type="text"
                        value={ename}
                        onChange={(e) => setEname(e.target.value)}
                        id="ename"
                      />

                      <label>target Days:</label>
                      {/* <input type="text" value={targetDays} readOnly onChange={(e) => setInputValues({ ...inputValues, targetDays: e.target.value })} id='targetdays' /> */}
                      <input
                        type="text"
                        value={targetDays}
                        readOnly
                        onChange={(e) => setTargetDays(e.target.value)}
                        id="targetdays"
                      />

                      <label>Date Joined:</label>
                      {/* <input type="text" value={dateJoined} onChange={(e) => setInputValues({ ...inputValues, dateJoined: e.target.value })} id='dateJoined' /> */}
                      <input
                        type="text"
                        value={dateJoined}
                        onChange={(e) => setDateJoined(e.target.value)}
                        id="dateJoined"
                      />

                      <label>Date Of Handover:</label>
                      {/* <input type="text" value={dateHandovered} onChange={(e) => setInputValues({ ...inputValues, dateHandovered: e.target.value })} id='dateHandovered' /> */}
                      <input
                        type="text"
                        value={dateHandovered}
                        onChange={(e) => setDateHandovered(e.target.value)}
                        id="dateHandovered"
                      />
                    </>
                  )}
                  {selectedTask.name === "New Employee Training" && (
                    <>
                      <label>Topic Of Training</label>
                      {/* <input type="text" value={targetDays} readOnly onChange={(e) => setInputValues({ ...inputValues, targetDays: e.target.value })} id='targetdays' /> */}
                      <input
                        type="text"
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                        id="topic"
                      />

                      <select
                        multiple
                        id="users"
                        value={selectedUsers}
                        onChange={handleUserSelect}
                      >
                        {users.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.username}
                          </option>
                        ))}
                      </select>

                      <label>Date Of Training:</label>
                      <input
                        type="date"
                        value={tdate}
                        onChange={(e) => setTdate(e.target.value)}
                        id="dateJoined"
                      />
                    </>
                  )}
                  {selectedTask.name === "Old Emplyees Training" && (
                    <>
                      <label>Topic Of Training</label>

                      <input
                        type="text"
                        value={old_emp_training_topic}
                        onChange={(e) =>
                          setold_emp_training_topic(e.target.value)
                        }
                        id="old_emp_training_topic"
                      />

                      <select
                        multiple
                        id="users"
                        value={selectedUsers}
                        onChange={handleUserSelect}
                      >
                        {users.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.username}
                          </option>
                        ))}
                      </select>

                      <label>Date Of Training:</label>
                      <input
                        type="date"
                        value={tdate}
                        onChange={(e) => setTdate(e.target.value)}
                        id="dateJoined"
                      />
                    </>
                  )}

                  <button type="submit" className="saveBtn">
                    Save
                  </button>
                  <button
                    type="button"
                    className="cancelBtn"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default UserWiseTeamLeader;
