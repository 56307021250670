// import React from "react";
import React, { useState, useEffect } from "react";
import pic from '../assets/images/vacant.jpg';
import axios from "axios";
import Slider from "react-slick";

const CentralManager = () => {

const [announcements, setAnnouncements] = useState([]);

useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(
          "https://scoreboard.codingindia.co.in/ShowAnnouncements/"
        );
        setAnnouncements(response.data);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };
  
    fetchAnnouncements();
  }, []);
  // End Announcements Page
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <></>, // Hide the previous arrow
    nextArrow: <></>, // Hide the next arrow
  };
  

  return (
    <div className="businessHead text-center container">
    <div className="recentContainer" style={{width:"100%"}}>
          <div className="row recentNews">
            <div className="col-3">
              <h4>Recent Event</h4>
            </div>
            <div className="col-9">
              <Slider {...settings}>
                {announcements.map((announcement) => (
                  <div key={announcement.id}>
                    <span style={{ fontSize: "1.2rem" }}>
                      {announcement.name}
                    </span>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>

      <div class="row">
        <div class="col-12">
          <div id="carouselExample" class="carousel slide">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-7">
                    <div class="row">
                      <div class="col-8" style={{ marginTop: "30px", textAlign:"left" }}>
                        <h1 class="authorName">Anonymous</h1>
                        <p class="authorPos">Central Manager</p>
                      </div>
                      <div class="col-4" style={{ marginTop: "30px" }}>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4" style={{ marginTop: "30px" }}>
                        <div class="resumeForward" style={{padding:"20px"}}>
                          <div className="weekMonth">
                          <h3>12</h3>
                          <span>/ Monthly</span>
                          </div>
                          <p>Telephonic Doctor & Director</p>
                        </div>
                      </div>
                      <div class="col-4" style={{ marginTop: "30px" }}>
                        <div class="turnedUp" style={{padding:"20px"}}>
                        <div className="weekMonth">
                          <h3>12</h3>
                          <span>/ Monthly</span>
                          </div>
                          <p>Total <br/> Revenue</p>
                        </div>
                      </div>
                      <div class="col-4" style={{ marginTop: "30px" }}>
                        <div class="selectionCand" style={{padding:"20px"}}>
                        <div className="weekMonth">
                          <h3>12</h3>
                          <span>/ Monthly</span>
                          </div>
                          <p>Follow Up & Closure</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-5 userImageDiv" style={{ marginTop: "30px" }}>
                    <img
                      src={pic}
                      alt=""
                      class="userImage"
                    />
                  </div>
                </div>
                <div class="row"></div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CentralManager;
