import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../NavBar";
import { useParams } from "react-router-dom";

const LeaderOldRecords = () => {
  const { id } = useParams();
  const [records, setRecords] = useState([]);
  const [leaderName, setLeaderName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [superUser, setSuperUser] = useState(false);


  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await axios.get(
          `https://scoreboard.codingindia.co.in/LeadersTable/get_old_month_records/${id}/`
        );
        setLeaderName(response.data.leader);
        setRecords(
          response.data.records.filter(
            (record) =>
              record.total_taskinput !== 0 || record.total_points !== 0
          )

        );
        const superUser = localStorage.getItem('is_superuser')
        setSuperUser(superUser === "true")

      } catch (err) {
        setError(err.message || "Something went wrong.");
      } finally {
        setLoading(false);
      }
    };

    fetchRecords();
  }, [id]);

  const groupByMonth = (records) => {
    const grouped = records.reduce((acc, record) => {
      const { year, month_name } = record;
      const key = `${year}-${month_name}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(record);
      return acc;
    }, {});

    // Sort keys in descending order
    return Object.fromEntries(
      Object.entries(grouped).sort((a, b) => {
        const [yearA, monthA] = a[0].split("-");
        const [yearB, monthB] = b[0].split("-");
        return (
          yearB - yearA ||
          new Date(`${monthB} 1, ${yearB}`) - new Date(`${monthA} 1, ${yearA}`)
        );
      })
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-xl font-semibold animate-pulse">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-xl text-red-600 font-semibold">Error: {error}</div>
      </div>
    );
  }

  const groupedRecords = groupByMonth(records);

  return (
    <>
      {superUser && (
        <>
          <Navbar />
        </>
      )}
      <div className="container mx-auto p-3">
        <h1 className="text-3xl font-bold text-gray-800 mb-3 text-center">
          Records for {leaderName}
        </h1>
        {Object.keys(groupedRecords).length === 0 ? (
          <div className="flex justify-center">
            <p className="text-lg text-gray-500">No records available.</p>
          </div>
        ) : (
          Object.entries(groupedRecords).map(([key, monthRecords]) => (
            <div key={key} className="mb-5">
              <h2 className="text-2xl font-semibold mb-2 border-b pb-2">
                {key}
              </h2>
              <div className="overflow-hidden border rounded-md shadow-sm">
                <table className="w-full bg-white">
                  <thead className="bg-gray-200 text-gray-700">
                    <tr>
                      <th className="py-2 px-4 text-left">Task Name</th>
                      <th className="py-2 px-4 text-left">Values</th>
                      {/* <th className="py-2 px-4 text-left">Points</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {monthRecords.map((record, index) => (
                      <tr
                        key={index}
                        className={`border-b ${
                          index % 2 === 0 ? "bg-gray-50" : "bg-white"
                        }`}
                      >
                        <td className="py-3 px-4">{record.task_name}</td>
                        <td className="py-3 px-4">{record.total_taskinput}</td>
                        {/* <td className="py-3 px-4">{record.total_points}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default LeaderOldRecords;
