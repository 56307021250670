import React, { useState, useEffect, useMemo } from "react";

import axios from "axios";
import Announcementslider from './Announcementslider.jsx';

function Records() {
  const [data, setData] = useState(null);
  const [dailyEntryData, setDailyEntryData] = useState([]);
  const [pipelineandturnedup, setPipelineandturnedup] = useState([]);


  // Fetch data on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken) {
          const response = await axios.get("https://scoreboard.codingindia.co.in/GetAllRecord/", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const { records, monthly_records,  pipelineandturnedup } = response.data;

          setData(records || []);
          setDailyEntryData(monthly_records || []);
          setPipelineandturnedup(pipelineandturnedup || []);

        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchData();
  }, []);

  // Use useMemo to memoize sorted data for performance optimization
  const sortedData = useMemo(() => {
    if (!dailyEntryData || !pipelineandturnedup) return [];

    return dailyEntryData.sort((a, b) => {
      const pipelineA = pipelineandturnedup.find(entry => entry.user === a.user)?.pipline || 0;
      const pipelineB = pipelineandturnedup.find(entry => entry.user === b.user)?.pipline || 0;

      if (b.revenue !== a.revenue) return b.revenue - a.revenue;
      if (b.selected !== a.selected) return b.selected - a.selected;

      const percentageA = a.resume_forwarded !== 0 ? (a.turned_up / a.resume_forwarded) * 100 : 0;
      const percentageB = b.resume_forwarded !== 0 ? (b.turned_up / b.resume_forwarded) * 100 : 0;
      if (percentageB !== percentageA) return percentageB - percentageA;

      if (b.resume_forwarded !== a.resume_forwarded) return b.resume_forwarded - a.resume_forwarded;
      if (pipelineB !== pipelineA) return pipelineB - pipelineA;
      if (b.turned_up !== a.turned_up) return b.turned_up - a.turned_up;

      return 0;
    });
  }, [dailyEntryData, pipelineandturnedup]);

  return (
    <>
      <section>
        <div>
            <table className="min-w-full bg-white border border-gray-300" style={{ width: "95%", margin: "auto" }}>
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">HR</th>
                  <th className="py-2 px-4 border-b">Name</th>
                  <th className="py-2 px-4 border-b">Forwarded</th>
                  <th className="py-2 px-4 border-b">Pipeline</th>
                  <th className="py-2 px-4 border-b">Turned Up</th>
                  <th className="py-2 px-4 border-b">Selected Candidate</th>
                  <th className="py-2 px-4 border-b">Revenue</th>
                  <th className="py-2 px-4 border-b">Turned Up Ratio</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300">
                {sortedData.map((dailyEntry) => {
                  const correspondingRecord = data.find((record) => record.id === dailyEntry.user);
                  const imageSrc = correspondingRecord ? `https://scoreboard.codingindia.co.in${correspondingRecord.image}` : '';
                  const userName = correspondingRecord ? correspondingRecord.name : '';
                  const targetRev = correspondingRecord ? correspondingRecord.revenue : '';

                  const pipeline = pipelineandturnedup.find((entry) => entry.user === dailyEntry.user)?.pipline || '';

                  return (
                    <tr key={dailyEntry.user}>
                      <td className="py-2 px-4 border-b text-center">
                        {imageSrc && <img src={imageSrc} className="w-auto" style={{ height: "100px" }} alt="" />}
                      </td>
                      <td className="py-2 px-4 border-b text-center">{userName}</td>
                      <td className="py-2 px-4 border-b text-center">{dailyEntry.resume_forwarded}</td>
                      <td className="py-2 px-4 border-b text-center">{pipeline}</td>
                      <td className="py-2 px-4 border-b text-center">{dailyEntry.turned_up}</td>
                      <td className="py-2 px-4 border-b text-center">{dailyEntry.selected}</td>
                      <td className="py-2 px-4 border-b text-center">{isNaN(dailyEntry.revenue) ? 0 : parseFloat(dailyEntry.revenue)} / {targetRev} lac</td>
                      <td className="py-2 px-4 border-b text-center">
                        {dailyEntry.resume_forwarded !== 0
                          ? ((dailyEntry.turned_up / dailyEntry.resume_forwarded) * 100).toFixed(2)
                          : 0}
                        %
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
        </div>
      </section>
      <Announcementslider />
    </>
  );
}

export default Records;
