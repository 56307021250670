import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import PaceLogo from "../../assets/images/pacelogo.png";
import { FiMenu, FiX, FiChevronDown, FiChevronUp } from "react-icons/fi"; // Import menu icons

const CustomNavbar = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [openDropdown, setOpenDropdown] = useState({});

  const [isSuperuser, setIsSuperuser] = useState(() => {
    return localStorage.getItem("is_superuser") === "true";
  });

  useEffect(() => {
    let x = localStorage.getItem("is_superuser");
    setIsSuperuser(x === "true"); // Convert to boolean
  }, []);

  const toggleSidebar = () => {
    setCollapsed((prevState) => !prevState); // Toggle the collapsed state
  };

  const handleDropdownToggle = (menuName) => {
    setOpenDropdown((prev) => (prev === menuName ? null : menuName));
  };

  return (
    <div style={{ maxHeight: "100vh", backgroundColor: "#082f49" }}>
      <Sidebar
        collapsed={collapsed}
        style={{
          backgroundColor: "#082f49",
          height: "100%",
          opacity: "0.9",
          zIndex: 9999,
          maxHeight: "100vh",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#fff",
            }}
          >
            <Box
              bg="#fff"
              color="#b6c8d9"
              fontSize="xl"
              fontWeight="bold"
              textAlign="center"
              style={{
                padding: "10px",
                color: "white",
                height: "70px",
                marginTop: "5px",
              }}
            >
              {!collapsed && (
                <img
                  src={PaceLogo}
                  alt="Pace Logo"
                  style={{
                    borderRadius: "5px",
                    maxWidth: "160px",
                    height: "50px",
                    width: "160px",
                  }}
                />
              )}
            </Box>

            <Box
              bg="#fff"
              color="var(--bs-blue)"
              fontSize="xl"
              fontWeight="bold"
              textAlign="center"
              style={{
                padding: "10px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
              onClick={toggleSidebar}
            >
              {collapsed ? (
                <FiMenu
                  size={24}
                  style={{ marginLeft: "-35px", alignItems: "center" }}
                />
              ) : (
                <FiX style={{ display: "flex" }} size={24} />
              )}
            </Box>
          </div>
        </div>

        <Menu
          menuItemStyles={{
            button: {
              display: "flex",
              alignItems: "center",
              gap: "10px", // Space between icon and text
              padding: "12px 16px",
              [`&.active`]: {
                backgroundColor: "#082f49",
                color: "#000",
              },
              [`&:hover`]: {
                backgroundColor: "#082f49",
                color: "#fff",
              },
            },
          }}
          style={{
            background: "linear-gradient(180deg, #025aa5, #003366)",
            color: "white",
            width: "auto",
            height: "100%",
          }}
        >
          {isSuperuser === true ? (
            <>
              <MenuItem component={<Link to="/AllannouncementsForUser" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-megaphone"
                    viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 75 75 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0m-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233q.27.015.537.036c2.568.189 5.093.744 7.463 1.993zm-9 6.215v-4.13a95 95 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A61 61 0 0 1 4 10.065m-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68 68 0 0 0-1.722-.082z" />
                  </svg>
                  {!collapsed && <span> Announcements</span>}
                </div>
              </MenuItem>

              {/* **************************************** Pace Team ********************************** */}

              <div
                onClick={() => handleDropdownToggle("hrassociates")}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 15px",
                  backgroundColor: "transparent",
                  color: "white",
                  borderRadius: "5px",
                  cursor: "pointer",
                  marginBottom: "5px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-bar-chart"
                   viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path d="M4 11H2v3h2zm5-4H7v7h2zm5-5v12h-2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1z" />
                  </svg>
                  <span>{!collapsed && <span>Pace Team</span>}</span>
                </div>
                {openDropdown === "hrassociates" ? (
                  <FiChevronUp />
                ) : (
                  <FiChevronDown />
                )}
              </div>
              {openDropdown === "hrassociates" && (
                <div
                  style={{
                    padding: "10px 30px",
                    background: "#CAF0F8",
                    borderRadius: "5px",
                    marginTop: "5px",
                  }}
                >
                  <Link
                    to="/create"
                    style={{
                      textDecoration: "none",
                      color: "#0077B6",
                      marginTop: "10px",
                      padding: "5px",
                      display: "flex",
                    }}
                  >
                    +<span>{!collapsed && <span> Create </span>}</span>
                  </Link>
                  <Link
                    to="/records"
                    style={{
                      textDecoration: "none",
                      color: "#0077B6",
                      marginTop: "10px",
                      padding: "5px",
                      display: "flex",
                    }}
                  >
                    v<span>{!collapsed && <span> HR Associates</span>}</span>
                  </Link>
                </div>
              )}

              {/* **************************************** Team Leaders ********************************** */}
              <div
                onClick={() => handleDropdownToggle("teamleaders")}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 15px",
                  backgroundColor: "transparent",
                  color: "white",
                  borderRadius: "5px",
                  cursor: "pointer",
                  marginBottom: "5px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-diagram-3"
                   viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"
                    />
                  </svg>
                  <span>{!collapsed && <span>Team Leaders</span>}</span>
                </div>
                {openDropdown === "teamleaders" ? (
                  <FiChevronUp />
                ) : (
                  <FiChevronDown />
                )}
              </div>
              {openDropdown === "teamleaders" && (
                <div
                  style={{
                    padding: "10px 30px",
                    background: "#CAF0F8",
                    borderRadius: "5px",
                    marginTop: "5px",
                  }}
                >
                  <Link
                    to="/createteamleader"
                    style={{
                      textDecoration: "none",
                      color: "#0077B6",
                      marginTop: "10px",
                      padding: "5px",
                      display: "flex",
                    }}
                  >
                    +<span>{!collapsed && <span> Create </span>}</span>
                  </Link>
                  <Link
                    to="/showteamleader"
                    style={{
                      textDecoration: "none",
                      color: "#0077B6",
                      marginTop: "10px",
                      padding: "5px",
                      display: "flex",
                    }}
                  >
                    v<span>{!collapsed && <span> Leaders List</span>}</span>
                  </Link>
                </div>
              )}

              {/* *************************************************** Docladder *********************************************** */}
              <div
                onClick={() => handleDropdownToggle("docladder")}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 15px",
                  backgroundColor: "transparent",
                  color: "white",
                  borderRadius: "5px",
                  cursor: "pointer",
                  marginBottom: "5px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "8px", fontWeight: "800" }}>
                    D
                  </span>
                  <span>{!collapsed && <span> Docladder</span>}</span>
                </div>
                {openDropdown === "docladder" ? (
                  <FiChevronUp />
                ) : (
                  <FiChevronDown />
                )}
              </div>
              {openDropdown === "docladder" && (
                <div
                  style={{
                    padding: "10px 30px",
                    background: "#CAF0F8",
                    borderRadius: "5px",
                    marginTop: "5px",
                  }}
                >
                  <Link
                    to="/createdocladdermember"
                    style={{
                      textDecoration: "none",
                      color: "#0077B6",
                      marginTop: "10px",
                      padding: "5px",
                      display: "flex",
                    }}
                  >
                    +<span>{!collapsed && <span> Create </span>}</span>
                  </Link>
                  <Link
                    to="/showdocladdermember"
                    style={{
                      textDecoration: "none",
                      color: "#0077B6",
                      marginTop: "10px",
                      padding: "5px",
                      display: "flex",
                    }}
                  >
                    v<span>{!collapsed && <span> team Members</span>}</span>
                  </Link>
                </div>
              )}

              <MenuItem component={<Link to="/user-comparison" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-activity"
                   viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"
                    />
                  </svg>
                  {!collapsed && <span> Comparison</span>}
                </div>
              </MenuItem>

              <MenuItem component={<Link to="/closingReportForUser" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-graph-up-arrow"
                   viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M0 0h1v15h15v1H0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5"
                    />
                  </svg>
                  {!collapsed && <span> Closing Report</span>}
                </div>
              </MenuItem>

              <MenuItem component={<Link to="/marketingFilePage" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-briefcase"
                   viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5" />
                  </svg>
                  {!collapsed && <span> Marketing File</span>}
                </div>
              </MenuItem>

              <MenuItem component={<Link to="/DocladderMarketingFilesPage" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-briefcase-fill"
                   viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5" />
                    <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85z" />
                  </svg>
                  {!collapsed && <span> Docladder Marketing</span>}
                </div>
              </MenuItem>

              <MenuItem component={<Link to="/DealsPage" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-currency-dollar"
                   viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z" />
                  </svg>
                  {!collapsed && <span> Deals Page</span>}
                </div>
              </MenuItem>

              <MenuItem component={<Link to="/partnersFiles" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-file-earmark-pdf"
                   viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                    <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                  </svg>
                  {!collapsed && <span> Legal</span>}
                </div>
              </MenuItem>

              <MenuItem component={<Link to="/soppage" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-file-earmark-post"
                   viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                    <path d="M4 6.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5H7a.5.5 0 0 1 0 1H4.5a.5.5 0 0 1-.5-.5" />
                  </svg>
                  {!collapsed && <span> SOP</span>}
                </div>
              </MenuItem>

              <MenuItem component={<Link to="/momlist" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-file-earmark-bar-graph"
                    viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5z" />
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                  </svg>
                  {!collapsed && <span> MOM</span>}
                </div>
              </MenuItem>

              <MenuItem component={<Link to="/" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-box-arrow-right"
                    viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                    />
                  </svg>
                  {!collapsed && <span> Sign Out</span>}
                </div>
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem component={<Link to="/AllannouncementsForUser" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-megaphone"
                    viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 75 75 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0m-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233q.27.015.537.036c2.568.189 5.093.744 7.463 1.993zm-9 6.215v-4.13a95 95 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A61 61 0 0 1 4 10.065m-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68 68 0 0 0-1.722-.082z" />
                  </svg>
                  {!collapsed && <span> Announcements</span>}
                </div>
              </MenuItem>

              <MenuItem component={<Link to="/AllMinutesOfMeetingForUser" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-card-checklist"
                    viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z" />
                    <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0M7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0" />
                  </svg>
                  <span>{!collapsed && <span> MOM</span>}</span>
                </div>
              </MenuItem>

              <MenuItem component={<Link to="/closingReportForUser" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-file-earmark-bar-graph"
                    viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5z" />
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                  </svg>
                  {!collapsed && <span> Closing Report</span>}
                </div>
              </MenuItem>
              <MenuItem component={<Link to="/" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-box-arrow-right"
                    viewBox="0 0 16 16"
                    style={{ marginRight: "8px" }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                    />
                  </svg>
                  {!collapsed && <span> Sign Out</span>}
                </div>
              </MenuItem>
            </>
          )}
        </Menu>
      </Sidebar>
    </div>
  );
};

export default CustomNavbar;
