import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import "./CelebrationPage.css";
import welcomeBg from "../assets/images/welcome.jpg";
import myvidbg from "../assets/Videos/conf1.mp4";
import axios from "axios";
import qimg from "../assets/images/qoutes.png";
import conf1 from "../assets/Videos/lightbg.mp4";
import vid from "../assets/Videos/conf1.mp4";

import selectedvid from "../assets/Videos/firework.mp4";

const CelebrationPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [focusedIndex, setFocusedIndex] = useState(0);

  const motivationalQuotes = [
    "Alone we can do so little; together we can do so much. - Helen Keller",
    "Coming together is a beginning; keeping together is progress; working together is success. - Henry Ford",
    "Individually, we are one drop. Together, we are an ocean. - Ryunosuke Satoro",
    "The strength of the team is each individual member. The strength of each member is the team. - Phil Jackson",
    "Great things in business are never done by one person; they're done by a team of people. - Steve Jobs",
  ];

  const workAnniversaryQuotes = [
    "Happy work anniversary! Your hard work and commitment are an inspiration to us all",
    "Your positive influence makes our team stronger",
    "We appreciate your hard work and dedication this year",
    "Your contributions over the years are truly valued",
    "Your positive attitude and collaborative spirit are a breath of fresh air",
    "Your skills and expertise are invaluable to the team",
    "Your commitment and professionalism are truly commendable",
  ]

  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      fetch("https://scoreboard.codingindia.co.in/api/all_latest_news/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setData(data || []);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  }, [navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (data && data.length > 0) {
        setFocusedIndex((prevIndex) => (prevIndex + 1) % data.length);

        if (focusedIndex === data.length - 1) {
          navigate("/records");
        }
      } else {
        navigate("/records");
      }
    }, 12000);

    return () => clearInterval(interval);
  }, [data, focusedIndex, navigate]);

  useEffect(() => {
    const fetchRecordData = async () => {
      try {
        if (data && data.length > 0) {
          const currentRecord = data[focusedIndex];
          // const response = await axios.get(`https://scoreboard.codingindia.co.in/api/latest_news/${currentRecord.id}/`);
          const response = await axios.get(
            `https://scoreboard.codingindia.co.in/api/latest_news/${currentRecord.id}/`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          );
          console.log("response: " + response.data.foruser);
          setUserData(response.data.foruser);
        }
      } catch (error) {
        console.error("Error fetching record data:", error);
      }
    };

    fetchRecordData();
  }, [focusedIndex, data]);

  const randomQuote =
    motivationalQuotes[Math.floor(Math.random() * motivationalQuotes.length)];

  const randomWAQuote =
    workAnniversaryQuotes[Math.floor(Math.random() * workAnniversaryQuotes.length)];

  // Render the component only if data is not null
  if (data === null) {
    return null; // or you can render a loading indicator
  }
  return (
    <>
      <div id="welcomeaboard">
        {data &&
        data.length > 0 &&
        focusedIndex < data.length &&
        data[focusedIndex] &&
        data[focusedIndex].name === "new user created" ? (
          // ******************* new user created **************
          <div className="celebrationPage">
            <Confetti />

            {userData && userData.image && (
              <div className="welcomecontainer">
                <img
                  src={`https://scoreboard.codingindia.co.in${userData.image}`}
                  id="celebimg"
                  alt=""
                />
                <div className="celebrationText">
                  <h1 className="celebHead text-white">WELCOME ABOARD</h1>
                  <h1 className="celebHead heartbeat-text">{userData.name}</h1>
                  <h6 className="celebU text-xl text-white">You are now</h6>

                  {data[focusedIndex].dept === "Docladder" ? (
                    <>
                      <h5
                        className="celebHash text-2xl"
                        style={{ color: "gold", fontWeight: "800" }}
                      >
                        #TeamDocladder
                      </h5>
                    </>
                  ) : data[focusedIndex].dept === "Docladder Digital" ? (
                    <h5
                      className="celebHash text-2xl"
                      style={{ color: "gold", fontWeight: "800" }}
                    >
                      #TeamDocladderDigital
                    </h5>
                  ) : data[focusedIndex].dept === "PACE" ? (
                    <h5
                      className="celebHash text-2xl"
                      style={{ color: "gold", fontWeight: "800" }}
                    >
                      #TeamPace
                    </h5>
                  ) : (
                    <>
                      <h5
                        className="celebHash text-2xl"
                        style={{ color: "gold", fontWeight: "800" }}
                      >
                        #TeamPace
                      </h5>
                    </>
                  )}

                  <p className="celebMsg text-2xl text-white">
                    Your skills and talent will be a great addition to our
                    organization.
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : data &&
          data.length > 0 &&
          focusedIndex < data.length &&
          data[focusedIndex] &&
          data[focusedIndex].name === "Your CV Forwarded" ? (
          // ******************* Your CV Forwarded **************

          <div className="container celebrationPage2">
            <Confetti />

            {userData && userData.image && (
              <div className="container welcomecontainer">
                <img
                  src={`https://scoreboard.codingindia.co.in${userData.image}`}
                  id="celebimg"
                  alt=""
                />
                <div className="celebrationText">
                  <h1 className="celebHead text-blue">Congratulations</h1>
                  <h1 className="celebHead heartbeat-text1">{userData.name}</h1>

                  <h6 className="celebU text-3xl text-blue">
                    Brace yourself for{" "}
                    <span style={{ color: "#d97706", fontWeight: "800" }}>
                      brilliance
                    </span>
                    . Resume forwarded!
                  </h6>
                </div>
              </div>
            )}
          </div>
        ) : // ******************* end Your CV Forwarded **************

        data &&
          data.length > 0 &&
          focusedIndex < data.length &&
          data[focusedIndex] &&
          data[focusedIndex].name === "Recruiter Your CV Forwarded" ? (
          // ******************* Your CV Forwarded **************

          <div className="container celebrationPage2">
            <Confetti />

            {userData && userData.image && (
              <div className="container welcomecontainer">
                <img
                  src={`https://scoreboard.codingindia.co.in${userData.image}`}
                  id="celebimg"
                  alt=""
                />
                <div className="celebrationText">
                  <h1 className="celebHead text-blue">Congratulations</h1>
                  <h1 className="celebHead heartbeat-text1">{userData.name}</h1>

                  <h6 className="celebU text-3xl text-blue">
                    Brace yourself for{" "}
                    <span style={{ color: "#d97706", fontWeight: "800" }}>
                      brilliance
                    </span>
                    . Resume forwarded!
                  </h6>
                </div>
              </div>
            )}
          </div>
        ) : // ******************* end Your CV Forwarded **************

        data &&
          data.length > 0 &&
          focusedIndex < data.length &&
          data[focusedIndex] &&
          data[focusedIndex].name === "Your CV Selected" ? (
          // ******************* Your CV Selected **************
          <>
            {userData && userData.image && (
              <>
                <div className="relative">
                  <video
                    src={selectedvid}
                    autoPlay
                    loop
                    muted
                    className="fireWorkVid absolute object-cover"
                  ></video>

                  {/* <div class="firework absolute inset-0" >
                  <div class="fire" style={{ maxWidth: "100%", maxHeight: "100vh", overflow: "hidden" }}></div>
                  <div class="blast" style={{ maxWidth: "100%", maxHeight: "100vh", overflow: "hidden" }}></div>
                </div> */}
                  <div
                    class="celebpagecontainer"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100vh",
                      overflow: "hidden",
                    }}
                  >
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <h1 style={{ color: "#DAA520" }}>Congratulations</h1>
                        <h2 style={{ color: "white" }}>
                          Your Resume Has Been Selected
                        </h2>

                        <div class="row">
                          <div className="col-12" style={{ marginTop: "9rem" }}>
                            <div class="qoutesBoxx">
                              <img src={qimg} />
                              <q className="text-3xl">
                                Nothing is impossible <br /> The word itself
                                say's I'm Possible
                              </q>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="" style={{ marginLeft: "12rem" }}>
                        <img
                          src={`https://scoreboard.codingindia.co.in${userData.image}`}
                          id="celebimg"
                          alt=""
                        />
                        <div
                          class="box"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100vh",
                            overflow: "hidden",
                            marginLeft: "25px",
                          }}
                        >
                          <div class="title" style={{ textAlign: "center" }}>
                            <span
                              class="block"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100vh",
                                overflow: "hidden",
                              }}
                            ></span>
                            <h1 class="text-white">
                              {userData.name}
                              <span></span>
                            </h1>
                          </div>

                          <div class="role">
                            <div class="block text-center"></div>
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "1.2rem",
                                fontWeight: "600",
                              }}
                            >
                              HR Associate
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : // ******************* end Your CV Forwarded **************

        data &&
          data.length > 0 &&
          focusedIndex < data.length &&
          data[focusedIndex] &&
          data[focusedIndex].name === "Recruiter Your CV Selected" ? (
          // ******************* Your CV Selected **************
          <>
            {userData && userData.image && (
              <>
                <div className="relative">
                  <video
                    src={selectedvid}
                    autoPlay
                    loop
                    muted
                    className="fireWorkVid absolute object-cover"
                  ></video>

                  <div
                    class="celebpagecontainer"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100vh",
                      overflow: "hidden",
                    }}
                  >
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <h1 style={{ color: "#DAA520" }}>Congratulations</h1>
                        <h2 style={{ color: "white" }}>
                          Your Resume Has Been Selected
                        </h2>

                        <div class="row">
                          <div className="col-12" style={{ marginTop: "9rem" }}>
                            <div class="qoutesBoxx">
                              <img src={qimg} />
                              <q className="text-3xl">
                                Nothing is impossible <br /> The word itself
                                say's I'm Possible
                              </q>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="" style={{ marginLeft: "12rem" }}>
                        <img
                          src={`https://scoreboard.codingindia.co.in${userData.image}`}
                          id="celebimg"
                          alt=""
                        />
                        <div
                          class="box"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100vh",
                            overflow: "hidden",
                            marginLeft: "25px",
                          }}
                        >
                          <div class="title" style={{ textAlign: "center" }}>
                            <span
                              class="block"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100vh",
                                overflow: "hidden",
                              }}
                            ></span>
                            <h1 class="text-white">
                              {userData.name}
                              <span></span>
                            </h1>
                          </div>

                          <div class="role">
                            <div class="block text-center"></div>
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "1.2rem",
                                fontWeight: "600",
                              }}
                            >
                              Recruiter
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : // ******************* end Your CV Forwarded **************

        data &&
          data.length > 0 &&
          focusedIndex < data.length &&
          data[focusedIndex] &&
          data[focusedIndex].name === "Work Anniversary" ? (
          // ******************* Work Anniversary Section *******************
          <>
            {userData && userData.image && (
              <div className="relative">
                {/* Background Fireworks Video */}
                <video
                  src={myvidbg}
                  autoPlay
                  loop
                  muted
                  className="absolute w-full h-full object-cover"
                ></video>

                {/* Celebration Content */}
                <div className="relative z-5 celebpagecontainer flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-[#0077B6] via-[#90E0EF] to-[#CAF0F8] text-center ">
                  <div
                    className="flex flex-col items-center justify-center rounded-lg shadow-xl px-5 py-5"
                    style={{
                      background:
                        "linear-gradient(135deg, #00a0f061, #deede70a)",
                      backdropFilter: "blur(15px)",
                      border: "2px solid rgba(255, 255, 255, 0.3)",
                    }}
                  >
                    {/* Congratulations Header */}
                    <h1 className="text-6xl font-bold text-yellow-400 text-center animate-bounce">
                      Congratulations
                    </h1>

                    {/* Happy Work Anniversary */}
                    <h2 className="text-4xl font-semibold text-white  text-center">
                      Happy Work Anniversary!s
                    </h2>

                    {/* User Image */}
                    <div
                      className="relative rounded-full bg-gradient-to-r from-[#ADE8F4] to-[#0077B6] p-3 shadow-xl"
                      style={{
                        boxShadow:
                          "0 0 20px rgba(0, 119, 182, 0.5), 0 0 10px rgba(173, 232, 244, 0.5)",
                      }}
                    >
                      <img
                        src={`https://scoreboard.codingindia.co.in${userData.image}`}
                        alt={userData.name}
                        className="rounded-full border-4 border-white shadow-lg"
                        style={{
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />
                    </div>

                    {/* User Details */}
                    <div className="text-center ">
                      <h1 className="text-3xl font-bold text-white">
                        {userData.name}
                      </h1>
                      <p className="text-lg text-white opacity-90">Recruiter</p>
                    </div>

                    {/* Quote Section */}
                    <div className="flex flex-col items-center rounded-lg shadow-lg">
                      <blockquote className="text-xl font-light text-white italic text-center">
                        "Nothing is impossible. <br />
                        The word itself says 'I'm Possible.'"
                      </blockquote>
                    </div>
                  </div>
                </div>

                {/* Overlay */}
              </div>
            )}
          </>
        ) : data &&
          data.length > 0 &&
          focusedIndex < data.length &&
          data[focusedIndex] ? (
          data[focusedIndex].name === "Birthday Wishes" ? (
            // ******************* Happy Birthday Section *******************
            <>
              {userData && userData.image && (
                <div className="relative w-full h-screen overflow-hidden">
                  {/* Fireworks Video Background */}
                  <video
                    src={vid}
                    autoPlay
                    loop
                    muted
                    className="absolute inset-0 w-full h-full object-cover"
                  ></video>

                  {/* Semi-Transparent Overlay */}
                  <div className="absolute inset-0 bg-black bg-opacity-60"></div>

                  {/* Centered Celebration Card */}
                  <div className="absolute inset-0 flex items-center justify-center z-10">
                    <div
                      className="flex flex-col items-center text-center p-10 rounded-2xl shadow-2xl backdrop-blur-lg"
                      style={{
                        background:
                          "linear-gradient(145deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))",
                        border: "1px solid rgba(255, 255, 255, 0.2)",
                        boxShadow: "0 8px 32px rgba(0, 0, 0, 0.3)",
                        maxWidth: "700px",
                      }}
                    >
                      {/* Congratulations Header */}
                      <h1 className="text-5xl font-extrabold text-yellow-400 drop-shadow-md" style={{ marginTop: "150px" }}>
                        🎉 Happy Birthday! 🎉
                      </h1>

                      {/* Birthday Text */}
                      <h2 className="text-3xl font-semibold text-white mt-2">
                        
                      </h2>

                      {/* User Image Section */}
                      <div
                        className="rounded-full p-3 mt-8 bg-gradient-to-r from-[#ADE8F4] to-[#0077B6] shadow-lg"
                        style={{
                          boxShadow: "0 0 20px rgba(0, 119, 182, 0.7)",
                        }}
                      >
                        <img
                          src={`https://scoreboard.codingindia.co.in${userData.image}`}
                          alt={userData.name}
                          className="rounded-full border-4 border-white shadow-xl"
                          style={{
                            width: "180px",
                            height: "180px",
                            objectFit: "cover",
                          }}
                        />
                      </div>

                      {/* User Details */}
                      <div className="text-center mt-6">
                        <h1 className="text-4xl font-bold text-white">
                          {userData.name}
                        </h1>
                        <p className="text-lg text-white opacity-80">
                          Recruiter
                        </p>
                      </div>

                      {/* Quote Section */}
                      <div
                        className="mt-8 px-6 py-4 rounded-lg"
                        style={{
                          backgroundColor: "rgba(30, 41, 59, 0.9)",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <blockquote className="text-xl font-light text-white italic">
                          "Nothingfsfsfg is impossiblefsd. <br />
                          The word itself says 'I'm Possible.'"
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            // ******************* Your CV Selected Section *******************
            <>
              {/* Add your CV Selected section here */}
              <div className="relative">
                <h1 className="text-4xl font-bold text-center">
                  Your CV Has Been Selected!
                </h1>
                {/* Add additional content */}
              </div>
            </>
          )
        ) : (
          // ******************* end Your CV Selected **************
          <>
            <div
              className=" min-h-screen justify-center"
              style={{
                height: "100vh",
                backgroundImage: `url('https://images.unsplash.com/photo-1513538318453-d0cc70acc86d?&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
                backgroundSize: "100% 100vh",
              }}
            >
              <div
                className=" mx-auto text-center p-8 rounded-lg shadow-lg"
                style={{}}
              >
                <h1
                  className="text-6xl font-bold mb-4 "
                  style={{
                    color: "white",
                    lineHeight: "1.5em",
                    wordSpacing: "16px",
                  }}
                >
                  {randomQuote}
                </h1>

                {/*   <h1 className="text-4xl font-bold mb-4">Welcome to Leaderboard</h1>
                <p className="text-xl mb-8">Start tracking your progress now!</p>
                <div className="border-l-4 border-purple-400 pl-4">
                </div>  */}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CelebrationPage;
