import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import announcePic from '../assets/images/announcement.png';
import axios from "axios";
import { Link } from "react-router-dom";


import "./announce.css";
const Announcements = () => {
  const navigate = useNavigate();
  const [announcements, setAnnouncements] = useState([]);

  const handleSignOut = () => {
   
    localStorage.removeItem("access_token");

    // Redirect to the home page
    navigate('/');
  };


  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        // const response = await axios.get('https://scoreboard.codingindia.co.in/ShowAnnouncements/'); // Replace with your API endpoint
        const response = await axios.get('https://scoreboard.codingindia.co.in/ShowAnnouncements/', {
          headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`, // Replace with your token retrieval logic
          },
      });
        setAnnouncements(response.data);
        console.log(typeof response);
      } catch (error) {
        console.error('Error fetching announcements:', error);
      }
    };

    fetchAnnouncements();
  }, []);


  const formatDateTime = (datetimeString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(datetimeString).toLocaleString(undefined, options);
  };


  return (
    <>
      <div className="announceContainer">
        <div className="boxAnnounce">
          <h1>Recent Announcement</h1>
        </div>
        {announcements.map((announcement) => (
          <>
            <div className="row p-2 mt-2">
              <div className="col-2">
                <h3>{formatDateTime(announcement.activity_date)}</h3>
              </div>
              <div className="col-10">
                <p>{announcement.name}</p>
              </div>
            </div>
          </>
        ))}
        <Link
          className="signOut"
          to={{ pathname: '/' }}
          onClick={handleSignOut}
        >
          <i class="fa-solid fa-right-from-bracket"></i>
        </Link>
      </div>

      <img src={announcePic} className="announcePic" alt="" />

    </>
  );
};

export default Announcements;