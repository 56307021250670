import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";

const Announcementslider = () => {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(
          "https://scoreboard.codingindia.co.in/ShowAnnouncements/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        setAnnouncements(response.data);
      } catch (error) {
        // console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, []);

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 12000,
    prevArrow: <></>, // Hide the previous arrow
    nextArrow: <></>, // Hide the next arrow
  };
  return (
    <div>
      <div className="recentContainer">
        <div className="row recentNews">
          <div className="col-3">
            <h4>Recent Event</h4>
          </div>
          <div className="col-9">
            <Slider {...settings}>
              {announcements.map((announcement) => (
                <div key={announcement.id}>
                  <span style={{ fontSize: "1.2rem" }}>
                    {announcement.name}
                  </span>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Announcementslider;
