import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import "./CurrentMonthTaskForm.css";
import Navbar from "../../NavBar";
function CurrentMonthTaskForm() {
  const { leaderId } = useParams();

  const [formData, setFormData] = useState({
    name: '',
    leader: leaderId,
    target: '',
    points: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://scoreboard.codingindia.co.in/LeadersTable/create_task/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        throw new Error('Failed to add task');
      }
      alert('Task added successfully');
      setFormData({
        name: '',
        leader: leaderId,
        target: '',
        points: ''
      });
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to add task');
    }
  };

  return (
    <>
<Navbar/>
    <div className='container-fluid  w-90' >

      <h2 class="text-center mt-4">Add New Task</h2>


      <div className='currMonthTask'>
        <form onSubmit={handleSubmit}>
          <div className='form-control mb-2'>
            <label class="form-label w-100">
              Task Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              class="w-100"
            />

          </div>
          <div className='form-control mb-2'>
            <label class="form-label w-100">
              Leader:</label>
            <input
              type="text"
              name="leader"
              value={leaderId}
              disabled
              required
              class="w-100"
            />

          </div>
          <div className='form-control mb-2'>
            <label class="form-label w-100">
              Target:</label>
            <input
              type="text"
              name="target"
              value={formData.target}
              onChange={handleChange}
              required
              class="w-100"
            />

          </div>
          <div className='form-control mb-2'>
            <label class="form-label w-100">
              Points: </label>
            <input
              type="number"
              name="points"
              class="w-100"
              value={formData.points}
              onChange={handleChange}
              required
            />

          </div>
          <button type="submit" class="addTasBtn"><i class="fa-solid fa-plus"></i> Add Task</button>
        </form>
      </div>
    </div >
    </>
  );
}

export default CurrentMonthTaskForm;
