import React from 'react';
import DOMPurify from 'dompurify';

const SummaryCell = ({ summary }) => {
    function stripHtml(html) {
        const cleanHtml = DOMPurify.sanitize(html);
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = cleanHtml;
        return tempDiv.textContent || tempDiv.innerText || '';
    }

    const plainTextSummary = stripHtml(summary);

    return (
        <td className="py-2 px-4 border-b">
            {plainTextSummary.length > 100 ? plainTextSummary.substring(0, 100) + "..." : plainTextSummary}
        </td>
    );
};

export default SummaryCell;
