import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import img1 from '../assets/images/lb2.webp'

// Optimized image URL with smaller resolution for faster load

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(""); // Error state
  const navigate = useNavigate();

  // Handle form submission
  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const user = {
      username: username.trim(),
      password: password,
    };

    try {
      // Perform the POST request for login
      const { data } = await axios.post("https://scoreboard.codingindia.co.in/token/", user, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      // Set tokens in localStorage
      localStorage.setItem("access_token", data.access);
      localStorage.setItem("refresh_token", data.refresh);
      axios.defaults.headers.common["Authorization"] = `Bearer ${data["access"]}`;

      // Redirect to the records page
      navigate("/records");
    } catch (err) {
      // Set error if login fails
      setError("Invalid username or password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <main className="main-content mt-0">
        <div
          className="page-header align-items-start min-vh-100"
          style={{ backgroundImage: `url(${img1})` }}
        >
          <span className="mask bg-gradient-dark opacity-6"></span>
          <div className="container my-auto">
            <div className="row">
              <div className="col-lg-4 col-md-8 col-12 mx-auto">
                <div className="card z-index-0 fadeIn3 fadeInBottom">
                  <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div className="border-radius-lg py-3 pe-1" style={{ background: "var(--bg-blue)" }}>
                      <h4 className="text-white text-center mb-0">Dashboard Login</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <form role="form" className="text-start" onSubmit={submit}>
                      <div className="input-group input-group-outline my-3">
                        <label htmlFor="username" className="form-labels w-100">
                          Username
                        </label>
                        <input
                          type="text"
                          value={username}
                          id="username"
                          className="form-control"
                          placeholder="Enter your username"
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div className="input-group input-group-outline mb-3">
                        <label htmlFor="password" className="form-labels w-100">
                          Password
                        </label>
                        <input
                          type="password"
                          value={password}
                          id="password"
                          className="form-control"
                          placeholder="Enter your password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>

                      {/* Display error message if login fails */}
                      {error && <div className="alert alert-danger">{error}</div>}

                      {/* Disable button and show loading while login request is in progress */}
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn w-100 my-4 mb-2"
                          style={{ background: "var(--bg-blue)", color:"white" }}
                          disabled={loading}
                        >
                          {loading ? "Signing in..." : "Sign in"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;