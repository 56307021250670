import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./../NavBar";
import axios from "axios";
// import Announcementslider from './Announcementslider.jsx';

function Records() {
  const [data, setData] = useState(null);
  const [dailyEntryData, setDailyEntryData] = useState([]);
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [pipelineandturnedup, setPipelineandturnedup] = useState([]);
  const navigate = useNavigate();

  const handlePermissionNavigation = (userPermissions) => {
    if (userPermissions.includes("website.update_target")) {
      navigate("/sub_admin_view", {
        replace: true,
        state: { userPermissions: "update_target" },
      });
    } else if (userPermissions.includes("website.custom_viewer_perm")) {
      navigate("/viewers", {
        replace: true,
        state: { userPermissions: "custom_viewer_perm" },
      });
    } else if (
      userPermissions.includes("website.custom_user_view") &&
      userPermissions.includes("auth.center_manager_view")
    ) {
      navigate("/UserViews", {
        replace: true,
        state: { userPermissions: "custom_user_view and center_manager_view" },
      });
    } else if (
      userPermissions.includes("auth.Custom_team_leader_perm") &&
      userPermissions.includes("auth.leaderanddocladderteam")
    ) {
      navigate("/teamleaderview", {
        replace: true,
        state: { userPermissions: "teamleader and docladdermember" },
      });
    } else if (userPermissions.includes("website.custom_user_view")) {
      navigate("/UserViews", {
        replace: true,
        state: { userPermissions: "custom_user_view" },
      });
    } else if (userPermissions.includes("auth.custom_operations")) {
      navigate("/momlist", {
        replace: true,
        state: { userPermissions: "custom_operation" },
      });
    } else if (userPermissions.includes("auth.hr_permissions")) {
      navigate("/hrview", {
        replace: true,
        state: { userPermissions: "hr_permission" },
      });
    } else if (userPermissions.includes("auth.operations")) {
      navigate("/operations", {
        replace: true,
        state: { userPermissions: "operations" },
      });
    } else if (userPermissions.includes("auth.custom_marketting_view")) {
      navigate("/markettingview", {
        replace: true,
        state: { userPermissions: "custom_marketting" },
      });
    } else if (userPermissions.includes("auth.Custom_team_docladder_perm")) {
      navigate("/teamdocladderview", {
        replace: true,
        state: { userPermissions: "Custom_team_docladder_perm" },
      });
    } else if (userPermissions.includes("auth.Custom_team_leader_perm")) {
      navigate("/teamleaderview", {
        replace: true,
        state: { userPermissions: "Custom_team_leader_perm" },
      });
    } else {
      navigate("/pagenotfound", {
        replace: true,
        state: { userPermissions: "notassigned" },
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken) {
          const response = await axios.get(
            "https://scoreboard.codingindia.co.in/Docladder/daily-user-records/",
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          const {
            records,
            monthly_records,
            is_superuser,
            user_permissions,
            pipelineandturnedup,
          } = response.data;

          setData(records || []);
          setDailyEntryData(monthly_records || []);
          setIsSuperuser(is_superuser);
          setPipelineandturnedup(pipelineandturnedup || []);

          if (is_superuser) {
            // Superuser logic
          } else if (user_permissions) {
            handlePermissionNavigation(user_permissions);
          }
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchData();
  }, [navigate]);

  const sortedData = useMemo(() => {
    if (!dailyEntryData || !pipelineandturnedup) return [];

    return dailyEntryData.sort((a, b) => {
      // Sorting logic here
    });
  }, [dailyEntryData, pipelineandturnedup]);

  // Calculate the totals for each column
  const totalForwarded = sortedData.reduce(
    (acc, curr) => acc + curr.resume_forwarded,
    0
  );
  const totalPipeline = sortedData.reduce((acc, curr) => {
    const pipeline =
      pipelineandturnedup.find((entry) => entry.user === curr.user)?.pipline ||
      0;
    return acc + pipeline;
  }, 0);
  const totalTurnedUp = sortedData.reduce(
    (acc, curr) => acc + curr.turned_up,
    0
  );
  const totalSelected = sortedData.reduce(
    (acc, curr) => acc + curr.selected,
    0
  );
  const totalRevenue = sortedData.reduce(
    (acc, curr) => acc + (isNaN(curr.revenue) ? 0 : parseFloat(curr.revenue)),
    0
  );

  const totalRevenueTarget = sortedData.reduce((acc, curr) => {
    const correspondingRecord = data.find((record) => record.id === curr.user);
    const targetRev = correspondingRecord
      ? parseFloat(correspondingRecord.revenue) || 0
      : 0;
    return acc + targetRev;
  }, 0);

  return (
    <>
      <Navbar />
      <section>
        <div>
          {isSuperuser && (
            <table
              className="min-w-full bg-white border border-gray-300"
              style={{ width: "95%", margin: "auto" }}
            >
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">HR</th>
                  <th className="py-2 px-4 border-b">Name</th>
                  <th className="py-2 px-4 border-b">Forwarded</th>
                  <th className="py-2 px-4 border-b">Pipeline</th>
                  <th className="py-2 px-4 border-b">Turned Up</th>
                  <th className="py-2 px-4 border-b">Selected Candidate</th>
                  <th className="py-2 px-4 border-b">Revenue</th>
                  <th className="py-2 px-4 border-b">Turned Up Ratio</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300">
                {sortedData.map((dailyEntry) => {
                  const correspondingRecord = data.find(
                    (record) => record.id === dailyEntry.user
                  );
                  const imageSrc = correspondingRecord
                    ? `https://scoreboard.codingindia.co.in${correspondingRecord.image}`
                    : "";
                  const userName = correspondingRecord
                    ? correspondingRecord.name
                    : "";
                  const targetRev = correspondingRecord
                    ? correspondingRecord.revenue
                    : "";

                  const pipeline =
                    pipelineandturnedup.find(
                      (entry) => entry.user === dailyEntry.user
                    )?.pipline || "";

                  return (
                    <tr key={dailyEntry.user}>
                      <td className="py-2 px-4 border-b text-center">
                        {imageSrc && (
                          <img
                            src={imageSrc}
                            className="w-auto"
                            style={{ height: "100px" }}
                            alt=""
                          />
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {userName}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {dailyEntry.resume_forwarded}
                      </td>
                      {/* <td className="py-2 px-4 border-b text-center">{pipeline}</td> */}
                      <td className="py-2 px-4 border-b text-center">
                        {pipeline === ""
                          ? "0"
                          : pipeline !== undefined
                          ? pipeline
                          : 0}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {dailyEntry.turned_up}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {dailyEntry.selected}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {isNaN(dailyEntry.revenue)
                          ? 0
                          : parseFloat(dailyEntry.revenue)}{" "}
                        / {targetRev} lac
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {dailyEntry.resume_forwarded !== 0
                          ? (
                              ((dailyEntry.turned_up + dailyEntry.selected) /
                                dailyEntry.resume_forwarded) *
                              100
                            ).toFixed(2)
                          : 0}
                        %
                      </td>
                    </tr>
                  );
                })}

                {/* Totals Row */}
                <tr>
                  <td className="py-2 px-4 border-b text-center font-bold">
                    Total
                  </td>
                  <td className="py-2 px-4 border-b"></td>
                  <td className="py-2 px-4 border-b text-center font-bold">
                    {totalForwarded}
                  </td>
                  <td className="py-2 px-4 border-b text-center font-bold">
                    {totalPipeline}
                  </td>
                  <td className="py-2 px-4 border-b text-center font-bold">
                    {totalTurnedUp}
                  </td>
                  <td className="py-2 px-4 border-b text-center font-bold">
                    {totalSelected}
                  </td>
                  {/* <td className="py-2 px-4 border-b text-center font-bold">{totalRevenue.toFixed(2)} lac</td> */}
                  <td className="py-2 px-4 border-b text-center font-bold">
                    {totalRevenue.toFixed(2)} / {totalRevenueTarget.toFixed(2)}{" "}
                    lac
                  </td>
                  <td className="py-2 px-4 border-b"></td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </section>
      {/* <Announcementslider /> */}
    </>
  );
}

export default Records;
